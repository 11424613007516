import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  CONTRACTROLES_REQUEST,
  CONTRACTROLES_SUCCESS,
  CONTRACTROLES_FAILURE,
} from './constants';

export const getContactRoles = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/ContactsMaster`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      CONTRACTROLES_REQUEST,
      CONTRACTROLES_SUCCESS,
      CONTRACTROLES_FAILURE,
    ],
  });
};
