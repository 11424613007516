import {
  FETCH_DASHBOARD_REQUEST,
  DOWNLOAD_REQUEST,
  DOWNLOAD_FAILURE,
  DOWNLOAD_SUCCESS,
  DASHBOARDFILTER_FAILURE,
  DASHBOARDFILTER_REQUEST,
  DASHBOARDFILTER_SUCCESS,
  PRODUCTCATEGORY_FAILURE,
  PRODUCTCATEGORY_REQUEST,
  PRODUCTCATEGORY_SUCCESS,
  PRODUCTSUBCATEGORY_FAILURE,
  PRODUCTSUBCATEGORY_REQUEST,
  PRODUCTSUBCATEGORY_SUCCESS,
  DEALERSSALESREPRESENTATIVE_FAILURE,
  DEALERSSALESREPRESENTATIVE_REQUEST,
  DEALERSSALESREPRESENTATIVE_SUCCESS,
  MODELVARIANT_FAILURE,
  MODELVARIANT_REQUEST,
  MODELVARIANT_SUCCESS,
  TABLEDATA_REQUEST,
  TABLEDATA_SUCCESS,
  TABLEDATA_FAILURE,
} from './constants';

import {API_URL} from '../../constants/envVariables';
import {createAction} from 'redux-api-middleware';

export const initiateFetchDashboard = (payload) => ({
  type: FETCH_DASHBOARD_REQUEST,
  payload,
});

export const getDownloadData = (accessToken, url) => {
  return createAction({
    endpoint: `${url}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [DOWNLOAD_REQUEST, DOWNLOAD_SUCCESS, DOWNLOAD_FAILURE],
  });
};

// export const getTableData = (accessToken, url) => {
//   return createAction({
//     endpoint: `${url}`,
//     method: 'GET',
//     headers: {
//       'Content-Type': 'application/json',
//       Accept: 'text/csv',
//       Authorization: `Bearer ${accessToken}`,
//     },
//     types: [TABLEDATA_REQUEST, TABLEDATA_SUCCESS, DOWNLOAD_FAILURE],
//   });
// };

export const getDashboardFilterData = () => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster/GetOpportunitiesFilterPortal`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
    types: [
      DASHBOARDFILTER_REQUEST,
      DASHBOARDFILTER_SUCCESS,
      DASHBOARDFILTER_FAILURE,
    ],
  });
};
export const getProductCategoryFilterData = () => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster/ProductCategory`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
    types: [
      PRODUCTCATEGORY_REQUEST,
      PRODUCTCATEGORY_SUCCESS,
      PRODUCTCATEGORY_FAILURE,
    ],
  });
};

export const getProductSubCategoryFilterData = (productCode) => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster/ProductSubCategory?productCode=${productCode}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
    types: [
      PRODUCTSUBCATEGORY_REQUEST,
      PRODUCTSUBCATEGORY_SUCCESS,
      PRODUCTSUBCATEGORY_FAILURE,
    ],
  });
};
export const getModelFilterData = (productSubCode, productCode) => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster/ModelVariant?productSubCode=${productSubCode}&productCode=${productCode}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
    types: [MODELVARIANT_REQUEST, MODELVARIANT_SUCCESS, MODELVARIANT_FAILURE],
  });
};
export const getDealerSalesRepresentativeFilterData = (dealerCode, type) => {
  var url = '';
  if (type === 'zone') {
    url = `${API_URL}/OpportunitiesMaster/ZonesSalesRepresentatives?zoneCode=${dealerCode}`;
  } else if (type === 'branch') {
    url = `${API_URL}/OpportunitiesMaster/BranchSalesRepresentatives?branchCode=${dealerCode}`;
  } else {
    url = `${API_URL}/OpportunitiesMaster/DealersSalesRepresentatives?dealerCode=${dealerCode}`;
  }
  console.log('dealerCode === ', dealerCode, type, url);
  return createAction({
    endpoint: url,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
    },
    types: [
      DEALERSSALESREPRESENTATIVE_REQUEST,
      DEALERSSALESREPRESENTATIVE_SUCCESS,
      DEALERSSALESREPRESENTATIVE_FAILURE,
    ],
  });
};
export const resetFilterData = (type) => {
  return createAction({
    types: [type],
  });
};

export const getTableData = (accessToken, url) => {
  console.log('Table Data');
  return createAction({
    endpoint: `${url}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'text/csv',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [TABLEDATA_REQUEST, TABLEDATA_SUCCESS, TABLEDATA_FAILURE],
  });
};
