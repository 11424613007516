export const Opportunities_REQUEST = 'Opportunities_REQUEST';
export const Opportunities_SUCCESS = 'Opportunities_SUCCESS';
export const DownloadOpportunities_FAILURE = 'DownloadOpportunities_FAILURE';
export const DownloadOpportunities_REQUEST = 'DownloadOpportunities_REQUEST';
export const DownloadOpportunities_SUCCESS = 'DownloadOpportunities_SUCCESS';
export const Opportunities_FAILURE = 'Opportunities_FAILURE';

export const OpportunitiesCount_REQUEST = 'OpportunitiesCount_REQUEST';
export const OpportunitiesCount_SUCCESS = 'OpportunitiesCount_SUCCESS';
export const OpportunitiesCount_FAILURE = 'OpportunitiesCount_FAILURE';

export const OpportunitiesUpdate_REQUEST = 'OpportunitiesUpdate_REQUEST';
export const OpportunitiesUpdate_SUCCESS = 'OpportunitiesUpdate_SUCCESS';
export const OpportunitiesUpdate_FAILURE = 'OpportunitiesUpdate_FAILURE';

export const OpportunityMCode_REQUEST = 'OpportunityMCode_REQUEST';
export const OpportunityMCode_SUCCESS = 'OpportunityMCode_SUCCESS';
export const OpportunityMCode_FAILURE = 'OpportunityMCode_FAILURE';

export const CALCULATEOPPORTUNITYSTAGE_REQUEST =
  'CALCULATEOPPORTUNITYSTAGE_REQUEST';
export const CALCULATEOPPORTUNITYSTAGE_SUCCESS =
  'CALCULATEOPPORTUNITYSTAGE_SUCCESS';
export const CALCULATEOPPORTUNITYSTAGE_FAILURE =
  'CALCULATEOPPORTUNITYSTAGE_FAILURE';
