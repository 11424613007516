export const contactsColumns = [
  {
    title: 'Contact Id',
    dataIndex: 'id',
    key: 'id',
    sorter: {
      compare: (a, b) => a.id / b.id,
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'name',
    key: 'name',
    sorter: {
      compare: (a, b) => a.name.localeCompare(b.name),
    },
  },

  {
    title: 'Phone No.',
    dataIndex: 'contactNumber',
    key: 'contactNumber',
    sorter: {
      compare: (a, b) => a.contactNumber / b.contactNumber,
    },
  },
  {
    title: 'MCode',
    dataIndex: 'mCode',
    key: 'mCode',
    sorter: {
      compare: (a, b) => a.mCode / b.mCode,
    },
  },
  {
    title: 'Account Name',
    dataIndex: ['account', 'name'],
    key: 'account.name',
    sorter: {
      compare: (a, b) => a.account.name.localeCompare(b.account.name),
    },
  },
  {
    title: 'District',
    dataIndex: ['address', 'district'],
    key: ['address', 'district'],
    // sorter: {
    //   compare: (a, b) =>
    //     a.address.districtCode.localeCompare(b.address.districtCode),
    // },
  },
  {
    title: 'State',
    dataIndex: ['address', 'state'],
    key: 'address.state',
    // sorter: {
    //   compare: (a, b) => a.address.stateCode.localeCompare(b.address.stateCode),
    // },
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'action',
  // },
];
