import RouteGuard from './RouteGuard';
import LoginContainer from '../../containers/LoginContainer';
import HomeContainer from '../../containers/HomeContainer';
import AccountContainer from '../../containers/AccountContainer';
import NotFound from '../common/NotFound';
import ContactsContainer from '../../containers/ContactsContainer';
import ForceChangePasswordContainer from '../../containers/ForceChangePasswordContainer';
import {ForgotPassword} from '../forgotPassword/ForgotPassword';
import {ForgotPasswordVerificationCode} from '../forgotPassword/ForgotPasswordVerificationCode';
import {Opportunities} from '../opportunity/Opportunities';
import Activity from '../activity/Activity';
import {Notification} from '../notification/Notification';
import {BulkUpload} from '../bulkUpload/BulkUpload';
import {SalesTools} from '../salesTools/SalesTools';
import {Report} from '../report/Report';
import {Settings} from '../settings/Settings';
import UserManagement from '../userManagement/UserManagement';
export {RouteGuard};

// Must be authenticated to access the route
export const ROUTE_GUARD_TYPE_AUTH = 'AUTH';
// Must be unauthenticated to access the route(/login, /register)
export const ROUTE_GUARD_TYPE_UNAUTH = 'UNAUTH';
export const ROUTE_GUARD_TYPE_GENERAL = 'GENERAL';

export const LOGIN = {
  id: 'Login',
  path: '/login',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: LoginContainer,
};
export const FORCECHANGEPASSWORD = {
  id: 'ForceChangePassword',
  path: '/forceChangePassword',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForceChangePasswordContainer,
};
export const FORGOTPASSWORD = {
  id: 'ForgotPassword',
  path: '/forgotPassword',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForgotPassword,
};
export const FORGOTPASSWORDVERIFICATIONCODE = {
  id: 'ForgotPasswordVerificationCode',
  path: '/forgotPasswordVerificationCode',
  guard: ROUTE_GUARD_TYPE_UNAUTH,
  component: ForgotPasswordVerificationCode,
};

export const HOME = {
  id: 'Home',
  path: '/',
  exact: true,
  guard: ROUTE_GUARD_TYPE_AUTH,
  component: HomeContainer,
};

export const USERMANAGEMENT = {
  id: 'UserManagement',
  path: '/userManagement',
  exact: true,
  guard: ROUTE_GUARD_TYPE_AUTH,
  component: UserManagement,
};

export const ROUTES = [
  {...USERMANAGEMENT},
  {...LOGIN},
  {...FORCECHANGEPASSWORD},
  {...FORGOTPASSWORD},
  {...FORGOTPASSWORDVERIFICATIONCODE},
  {
    id: 'Accounts',
    path: '/accounts',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: AccountContainer,
  },
  {
    id: 'Contacts',
    path: '/contacts',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: ContactsContainer,
  },
  {
    id: 'Opportunities',
    path: '/opportunities',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Opportunities,
  },
  {
    id: 'Activity',
    path: '/activities',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Activity,
  },
  {
    id: 'Notifications',
    path: '/notifications',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Notification,
  },
  {
    id: 'BulkUpload',
    path: '/bulkUpload',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: BulkUpload,
  },
  {
    id: 'SalesTools',
    path: '/salesTools',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: SalesTools,
  },
  {
    id: 'Reports',
    path: '/reports',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Report,
  },
  {
    id: 'Settings',
    path: '/settings',
    guard: ROUTE_GUARD_TYPE_AUTH,
    component: Settings,
  },
  {...HOME},
  {
    id: 'NotFound',
    path: '*',
    guard: ROUTE_GUARD_TYPE_GENERAL,
    component: NotFound,
  },
];
