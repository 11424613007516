import {API_URL} from '../../constants/envVariables';

export const DASHBOARD_ENDPOINT = `${API_URL}/Opportunities/DashboardForOrg`;
export const DOWNLOAD_REQUEST = 'DOWNLOAD_REQUEST';
export const DOWNLOAD_SUCCESS = 'DOWNLOAD_SUCCESS';
export const DOWNLOAD_FAILURE = 'DOWNLOAD_FAILURE';

export const FETCH_DASHBOARD_REQUEST = 'FETCH_DASHBOARD_REQUEST';
export const FETCH_DASHBOARD_SUCCESS = 'FETCH_DASHBOARD_SUCCESS';
export const FETCH_DASHBOARD_FAILURE = 'FETCH_DASHBOARD_FAILURE';
export const DASHBOARDFILTER_REQUEST = 'DASHBOARDFILTER_REQUEST';
export const DASHBOARDFILTER_SUCCESS = 'DASHBOARDFILTER_SUCCESS';
export const DASHBOARDFILTER_FAILURE = 'DASHBOARDFILTER_FAILURE';

export const PRODUCTCATEGORY_REQUEST = 'PRODUCTCATEGORY_REQUEST';
export const PRODUCTCATEGORY_SUCCESS = 'PRODUCTCATEGORY_SUCCESS';
export const PRODUCTCATEGORY_FAILURE = 'PRODUCTCATEGORY_FAILURE';
export const PRODUCTSUBCATEGORY_REQUEST = 'PRODUCTSUBCATEGORY_REQUEST';
export const PRODUCTSUBCATEGORY_SUCCESS = 'PRODUCTSUBCATEGORY_SUCCESS';
export const PRODUCTSUBCATEGORY_FAILURE = 'PRODUCTSUBCATEGORY_FAILURE';
export const RESET_PRODUCTSUBCATEGORY = 'RESET_PRODUCTSUBCATEGORY';
export const MODELVARIANT_REQUEST = 'MODELVARIANT_REQUEST';
export const MODELVARIANT_SUCCESS = 'MODELVARIANT_SUCCESS';
export const MODELVARIANT_FAILURE = 'MODELVARIANT_FAILURE';
export const RESET_MODEL = 'RESET_MODEL';

export const DEALERSSALESREPRESENTATIVE_REQUEST =
  'DEALERSSALESREPRESENTATIVE_REQUEST';
export const DEALERSSALESREPRESENTATIVE_SUCCESS =
  'DEALERSSALESREPRESENTATIVE_SUCCESS';
export const DEALERSSALESREPRESENTATIVE_FAILURE =
  'DEALERSSALESREPRESENTATIVE_FAILURE';
export const RESET_SAlESREPRESENTATIVE = 'RESET_SAlESREPRESENTATIVE';
export const TABLEDATA_REQUEST = 'TABLEDATA_REQUEST';
export const TABLEDATA_SUCCESS = 'TABLEDATA_SUCCESS';
export const TABLEDATA_FAILURE = 'TABLEDATA_FAILURE';
