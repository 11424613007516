export const ADDRESS_REQUEST = 'ADDRESS_REQUEST';
export const ADDRESS_SUCCESS = 'ADDRESS_SUCCESS';
export const ADDRESS_FAILURE = 'ADDRESS_FAILURE';

export const DiSTRICT_REQUEST = 'DiSTRICT_REQUEST';
export const DiSTRICT_SUCCESS = 'DiSTRICT_SUCCESS';
export const DiSTRICT_FAILURE = 'DiSTRICT_FAILURE';

export const USERADDRESS_REQUEST = 'USERADDRESS_REQUEST';
export const USERADDRESS_SUCCESS = 'USERADDRESS_SUCCESS';
export const USERADDRESS_FAILURE = 'USERADDRESS_FAILURE';
