import {
  Opportunities_FAILURE,
  Opportunities_SUCCESS,
  Opportunities_REQUEST,
  DownloadOpportunities_FAILURE,
  DownloadOpportunities_SUCCESS,
  DownloadOpportunities_REQUEST,
  OpportunitiesCount_FAILURE,
  OpportunitiesCount_SUCCESS,
  OpportunitiesCount_REQUEST,
  OpportunitiesUpdate_REQUEST,
  OpportunitiesUpdate_FAILURE,
  OpportunitiesUpdate_SUCCESS,
  CALCULATEOPPORTUNITYSTAGE_REQUEST,
  CALCULATEOPPORTUNITYSTAGE_SUCCESS,
  CALCULATEOPPORTUNITYSTAGE_FAILURE,
  OpportunityMCode_REQUEST,
  OpportunityMCode_SUCCESS,
  OpportunityMCode_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const isFetchingOpportunities = (state = false, action) => {
  switch (action.type) {
    case Opportunities_REQUEST:
      return true;
    case Opportunities_SUCCESS:
    case Opportunities_FAILURE:
      return false;
    default:
      return state;
  }
};

const opportunitiesData = (state = null, action) => {
  switch (action.type) {
    case Opportunities_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureOpportunities = (state = null, action) => {
  switch (action.type) {
    case Opportunities_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasOpportunitiesFetched = (state = false, action) => {
  switch (action.type) {
    case Opportunities_REQUEST:
      return false;
    case Opportunities_SUCCESS:
    case Opportunities_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingDownloadOpportunities = (state = false, action) => {
  switch (action.type) {
    case DownloadOpportunities_REQUEST:
      return true;
    case DownloadOpportunities_SUCCESS:
    case DownloadOpportunities_FAILURE:
      return false;
    default:
      return state;
  }
};

const opportunitiesDownloadData = (state = null, action) => {
  switch (action.type) {
    case DownloadOpportunities_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDownloadOpportunities = (state = null, action) => {
  switch (action.type) {
    case DownloadOpportunities_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDownloadOpportunitiesFetched = (state = false, action) => {
  switch (action.type) {
    case DownloadOpportunities_REQUEST:
      return false;
    case DownloadOpportunities_SUCCESS:
    case DownloadOpportunities_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingOpportunitiesCount = (state = false, action) => {
  switch (action.type) {
    case OpportunitiesCount_REQUEST:
      return true;
    case OpportunitiesCount_SUCCESS:
    case OpportunitiesCount_FAILURE:
      return false;
    default:
      return state;
  }
};

const opportunitiesDataCount = (state = null, action) => {
  switch (action.type) {
    case OpportunitiesCount_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureOpportunitiesCount = (state = null, action) => {
  switch (action.type) {
    case OpportunitiesCount_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasOpportunitiesCountFetched = (state = false, action) => {
  switch (action.type) {
    case OpportunitiesCount_REQUEST:
      return false;
    case OpportunitiesCount_SUCCESS:
    case OpportunitiesCount_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingOpportunityByMCode = (state = false, action) => {
  switch (action.type) {
    case OpportunityMCode_REQUEST:
      return true;
    case OpportunityMCode_SUCCESS:
    case OpportunityMCode_FAILURE:
      return false;
    default:
      return state;
  }
};

const opportunityByMCodeData = (state = null, action) => {
  switch (action.type) {
    case OpportunityMCode_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureOpportunityByMCode = (state = null, action) => {
  switch (action.type) {
    case OpportunityMCode_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasOpportunityByMCodeFetched = (state = false, action) => {
  switch (action.type) {
    case OpportunityMCode_REQUEST:
      return false;
    case OpportunityMCode_SUCCESS:
    case OpportunityMCode_FAILURE:
      return true;
    default:
      return state;
  }
};

const updateOpportunityData = (state = null, action) => {
  switch (action.type) {
    case OpportunitiesUpdate_REQUEST:
      return state;
    case OpportunitiesUpdate_SUCCESS:
      return action;
    case OpportunitiesUpdate_FAILURE:
      return state;
    default:
      return state;
  }
};

const calculateOpportunityStage = (state = null, action) => {
  switch (action.type) {
    case CALCULATEOPPORTUNITYSTAGE_REQUEST:
      return state;
    case CALCULATEOPPORTUNITYSTAGE_SUCCESS:
      return action;
    case CALCULATEOPPORTUNITYSTAGE_FAILURE:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingDownloadOpportunities,
  hasDownloadOpportunitiesFetched,
  opportunitiesDownloadData,
  failureDownloadOpportunities,
  isFetchingOpportunitiesCount,
  hasOpportunitiesCountFetched,
  failureOpportunitiesCount,
  opportunitiesDataCount,
  opportunitiesData,
  hasOpportunitiesFetched,
  failureOpportunities,
  isFetchingOpportunities,
  updateOpportunityData,
  calculateOpportunityStage,
  isFetchingOpportunityByMCode,
  failureOpportunityByMCode,
  opportunityByMCodeData,
  hasOpportunityByMCodeFetched,
});
