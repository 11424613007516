import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Account from '../components/accounts/Account';
import {getAccounts} from '../modules/accounts/action';
import {useSelector} from 'react-redux';
import {getAuthUserData} from '../modules/auth/user/selector';
import moment from 'moment';

const AccountContainer = ({getAccounts, accounts, hasFetched}) => {
  const authUser = useSelector(getAuthUserData);
  useEffect(() => {
    if (!hasFetched)
      getAccounts(
        authUser.accessToken,
        1,
        10,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        '',
        '',
        '',
      );
  }, [getAccounts, hasFetched, authUser.accessToken]);
  return <Account accounts={accounts} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({
  accounts: state.accounts,
  hasFetched: state.hasFetched,
});

//#endregion

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({getAccounts}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(AccountContainer);
