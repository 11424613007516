export default {
  openStatus: {
    title: 'Open',
    value: 0,
    background: '#F59E0B',
  },
  winStatus: {
    title: 'Won',
    value: 0,
    background: '#34D399',
  },
  lostStatus: {
    title: 'Lost',
    value: 0,
    background: '#8B575C',
  },
  droppedStatus: {
    title: 'Dropped',
    value: 0,
    background: '#374151',
  },
};
