import React, {useState, useEffect} from 'react';
import {
  Modal,
  Form,
  Collapse,
  Input,
  Select,
  DatePicker,
  Space,
  Button,
  AutoComplete,
  message,
} from 'antd';
import moment from 'moment';
import {PINCODE_REGEX, MOBILE_NUMBER_REGEX} from '../../constants/default';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getAccountAddress} from '../../modules/address/action';
import {
  getUserAddressCountriesData,
  getUserAddressStatesData,
} from '../../modules/address/selectors';
import {getContactRoles} from '../../modules/contactroles/action';
import {getContactRolesData} from '../../modules/contactroles/selectors';
import {getPreferedLanguages} from '../../modules/preferedlanguage/action';
import {getPreferedLanguagesData} from '../../modules/preferedlanguage/selectors';
import {getMyAccounts} from '../../modules/accounts/action';
import {getMyAccountsData} from '../../modules/accounts/selectors';
import {updateContact} from '../../modules/contacts/action';
import {updateContactDataSelector} from '../../modules/contacts/selectors';

const {Panel} = Collapse;
const {Option} = Select;
const ContactModalForm = ({visible, onCancel, onCreate, onSubmit, data}) => {
  const dispatch = useDispatch();

  const [accountNameValue, setAccountNameValue] = useState('');
  const [accountNameOptions, setAccountNameOptions] = useState([]);
  const [accountMCodeValue, setAccountMCodeValue] = useState('');
  const [firstNameValue, setFirstNameValue] = useState();
  const [lastNameValue, setLastNameValue] = useState();
  const [emailIdValue, setEmailIdValue] = useState();
  const [contactNumberValue, setContactNumberValue] = useState();
  const [designationValue, setDesignationValue] = useState();
  const [districtValue, setDistrictValue] = useState();
  const [cityValue, setCityValue] = useState();
  const [pincodeValue, setPincodeValue] = useState();
  const [addressLine1Value, setAddressLine1Value] = useState();
  const [addressLine2Value, setAddressLine2Value] = useState();
  const [addressLine3Value, setAddressLine3Value] = useState();
  const [preferredLanguageValue, setPreferredLanguageValue] = useState(
    () => '',
  );
  const [stateValue, setStateValue] = useState(() => 'All');
  const [contactRoleValue, setContactRoleValue] = useState(() => 'All');

  const [birthDate, setBirthDate] = useState(
    !data.personalDetails?.dateOfBirth
      ? moment(new Date())._d
      : moment(data.personalDetails?.dateOfBirth)._d,
  );
  const [marriageDate, setMarriageDate] = useState(
    !data.personalDetails?.dateOfMarriage
      ? moment(new Date())._d
      : moment(data.personalDetails?.dateOfMarriage)._d,
  );
  const [requiredFieldsValue, setRequiredFieldsValue] = useState({
    account: true,
    firstName: true,
    lastName: true,
    contactNumber: true,
    district: true,
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    pincode: '',
    contactNumber: '',
  });

  const authUser = useSelector(getAuthUserData);

  useEffect(() => {
    dispatch(getContactRoles(authUser.accessToken));
    dispatch(getAccountAddress(authUser.accessToken, data.account?.userId));
    dispatch(getPreferedLanguages(authUser.accessToken));
  }, [dispatch, authUser.accessToken, data]);

  useEffect(() => {
    dispatch(getMyAccounts(authUser.accessToken, 1, 3, accountNameValue));
  }, [accountNameValue, dispatch, authUser.accessToken]);

  const statesData = useSelector(getUserAddressStatesData);
  const contactRolesData = useSelector(getContactRolesData);
  const preferedLanguagesData = useSelector(getPreferedLanguagesData);
  const myAccountsData = useSelector(getMyAccountsData);
  const updateStatus = useSelector(updateContactDataSelector);
  useEffect(() => {
    if (visible) {
      setAccountNameValue(data && data?.account ? data.account.name : '');
      setAccountMCodeValue(data ? data.accountId : '');
      setFirstNameValue(data ? data?.firstName : '');
      setLastNameValue(data ? data?.lastName : '');
      setEmailIdValue(data ? data?.email : '');
      setContactNumberValue(data ? data?.contactNumber : '');
      setDesignationValue(data ? data?.designation : '');
      setDistrictValue(data && data.address ? data.address?.district : '');
      setCityValue(data && data.address ? data.address?.city : '');
      setPincodeValue(data && data.address ? data.address?.pincode : '');
      setAddressLine1Value(
        data && data.address ? data.address?.addressLine1 : '',
      );
      setAddressLine2Value(
        data && data.address ? data.address?.addressLine2 : '',
      );
      setAddressLine3Value(
        data && data.address ? data.address?.addressLine3 : '',
      );
      setStateValue(data && data.address ? data.address?.stateCode : 'All');
      setContactRoleValue((data && data?.roleCode: 'All'));
      setPreferredLanguageValue(
        data && data.personalDetails
          ? data.personalDetails?.preferedLanguageId
          : '',
      );
    }
  }, [visible, data]);

  useEffect(() => {
    requriedValidation();
  }, [
    accountNameValue,
    firstNameValue,
    lastNameValue,
    contactNumberValue,
    districtValue,
  ]);

  useEffect(() => {
    validate();
  }, [emailIdValue, pincodeValue, contactNumberValue]);

  const validate = () => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setFormErrors({
      email:
        emailIdValue !== '' && reg.test(emailIdValue) === false
          ? 'Email Field is Invalid'
          : '',
      pincode:
        pincodeValue !== '' && PINCODE_REGEX.test(pincodeValue) === false
          ? 'PINCode is Invalid'
          : '',
      contactNumber:
        contactNumberValue !== '' &&
        contactNumberValue !== undefined &&
        contactNumberValue !== null &&
        MOBILE_NUMBER_REGEX.test(contactNumberValue) === false
          ? 'Contact Number is Invalid'
          : '',
    });
    if (
      formErrors.email === '' &&
      formErrors.contactNumber === '' &&
      formErrors.pincode === ''
    ) {
      return true;
    }
    return false;
  };

  const requriedValidation = () => {
    if (
      accountNameValue &&
      accountNameValue !== '' &&
      accountNameValue !== undefined
    ) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, account: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, account: true};
      });
    }

    if (
      firstNameValue &&
      firstNameValue !== '' &&
      firstNameValue !== undefined
    ) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, firstName: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, firstName: true};
      });
    }

    if (lastNameValue && lastNameValue !== '' && lastNameValue !== undefined) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, lastName: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, lastName: true};
      });
    }

    if (
      contactNumberValue &&
      contactNumberValue !== '' &&
      contactNumberValue !== undefined
    ) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, contactNumber: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, contactNumber: true};
      });
    }

    if (districtValue && districtValue !== '' && districtValue !== undefined) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, district: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, district: true};
      });
    }
  };

  useEffect(() => {
    if (data.personalDetails?.dateOfBirth) {
      setBirthDate(moment(data.personalDetails?.dateOfBirth)._d);
    }
    if (data.personalDetails?.dateOfMarriage) {
      setMarriageDate(moment(data.personalDetails?.dateOfMarriage)._d);
    }
  }, [data.personalDetails?.dateOfBirth, data.personalDetails?.dateOfMarriage]);

  useEffect(() => {
    if (myAccountsData) {
      let options = myAccountsData.map((item, index) => {
        return {value: item.name + '-' + item.mCode, label: item.name};
      });
      setAccountNameOptions(options);
    }
  }, [myAccountsData]);

  // useEffect(() => {
  //   if (updateStatus) {
  //     message.success({
  //       content: 'Contact Updated Sucessfully',
  //       className: 'custom-class',
  //       style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
  //     });
  //     setTimeout(() => {
  //       onCancel();
  //     }, 10);
  //   } else if (updateStatus) {
  //     message.error({
  //       content: 'Contact Updation Not Done',
  //       className: 'custom-class',
  //       style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
  //     });
  //   }
  // }, [updateStatus]);

  useEffect(() => {
    if (visible) {
      if (updateStatus?.type === 'CONTACTUPDATE_SUCCESS') {
        message.success(
          {
            content: 'Contact Updated Sucessfully !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
        onSubmit();
        onCancel();
      } else if (updateStatus?.type === 'CONTACTUPDATE_FAILURE') {
        message.error(
          {
            content: 'Contact Update Failed !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    }
  }, [updateStatus]);

  const onFirstNameChange = (events) => {
    setFirstNameValue(events.target.value);
  };
  const onLastNameChange = (events) => {
    setLastNameValue(events.target.value);
  };

  const onEmailIdChange = (events) => {
    setEmailIdValue(events.target.value);
  };
  const onContactNumberChange = (events) => {
    setContactNumberValue(events.target.value);
  };
  const onDesignationChange = (events) => {
    setDesignationValue(events.target.value);
  };

  const onDistrictChange = (events) => {
    setDistrictValue(events.target.value);
  };

  const onCityChange = (events) => {
    setCityValue(events.target.value);
  };
  const onPincodeChange = (events) => {
    setPincodeValue(events.target.value);
  };
  const onAddressLine1Change = (events) => {
    setAddressLine1Value(events.target.value);
  };
  const onAddressLine2Change = (events) => {
    setAddressLine2Value(events.target.value);
  };
  const onAddressLine3Change = (events) => {
    setAddressLine3Value(events.target.value);
  };
  const onPreferredLanguageChanged = (value) => {
    setPreferredLanguageValue(value);
  };

  const onStateChanged = (value) => {
    setStateValue(value);
  };

  const onContactRoleChanged = (value) => {
    setContactRoleValue(value);
  };

  const onSearchSelect = (value) => {
    //console.log('search:', value);
  };

  const OnBirthDateChanged = (value) => {
    if (value) {
      setBirthDate(moment(value)._d);
    }
  };
  const OnMarriageDateChanged = (value) => {
    if (value) {
      setMarriageDate(moment(value)._d);
    }
  };

  const onSelect = (data) => {
    setAccountNameValue(data.split('-')[0]);
    setAccountMCodeValue(data.split('-')[1]);
  };

  const onChange = (data) => {
    setAccountNameValue(data.split('-')[0]);
    setAccountMCodeValue(data.split('-')[1]);
  };

  const OnSaveClick = (events) => {
    events.preventDefault();

    if (
      !requiredFieldsValue.account &&
      !requiredFieldsValue.firstName &&
      !requiredFieldsValue.lastName &&
      !requiredFieldsValue.contactNumber &&
      !requiredFieldsValue.district
    ) {
      if (validate) {
        let selectedRoles = contactRolesData.filter(
          (role) => role.code === contactRoleValue,
        );
        let selectedAccount = myAccountsData.filter(
          (account) => account.mCode === accountMCodeValue,
        );

        let selectedState = statesData.filter(
          (state) => state.code === stateValue,
        );

        let contactDoc = null;
        let contactRole = null;
        let associatedAccount = null;
        let accountDoc = null;
        let stateName = null;

        if (data.contactAttachments) {
          contactDoc = [
            {
              fileId: data.contactAttachments.fileId,
              fileName: data.contactAttachments.fileName,
              keyName: data.contactAttachments.keyName,
            },
          ];
        }

        if (selectedRoles && selectedRoles.length > 0) {
          contactRole = {
            id: selectedRoles[0].id,
            name: selectedRoles[0].name,
            code: selectedRoles[0].code,
          };
        }

        if (selectedAccount && selectedAccount.length > 0) {
          if (selectedAccount[0].accountAttachments) {
            accountDoc = [
              {
                fileId: selectedAccount[0].accountAttachments.fileId,
                fileName: selectedAccount[0].accountAttachments.fileName,
                keyName: selectedAccount[0].accountAttachments.keyName,
              },
            ];
          }
          associatedAccount = {
            requiresSync: selectedAccount[0].requiresSync,
            id: selectedAccount[0].id,
            mCode: selectedAccount[0].mCode,
            name: selectedAccount[0].name,
            email: selectedAccount[0].email,
            mobileNumber: selectedAccount[0].mobileNumber,
            pan: selectedAccount[0].pan,
            gstin: selectedAccount[0].gstin,
            source: selectedAccount[0].source,
            isKeyAccount: selectedAccount[0].isKeyAccount,
            salesManager: selectedAccount[0].salesManager,
            createdOn: selectedAccount[0].createdOn,
            createdBy: selectedAccount[0].createdBy,
            userId: selectedAccount[0].userId,
            userEmailAddress: selectedAccount[0].userEmailAddress,
            employeeId: selectedAccount[0].employeeId,
            accountTypeCode: selectedAccount[0].accountTypeCode,
            customerCategoryCode: selectedAccount[0].customerCategoryCode,
            numberOfContacts: selectedAccount[0].numberOfContacts,
            externalCustomerId: selectedAccount[0].externalCustomerId,
            address: {
              id: selectedAccount[0].address.id,
              addressLine1: selectedAccount[0].address.addressLine1,
              addressLine2: selectedAccount[0].address.addressLine2,
              addressLine3: selectedAccount[0].address.addressLine3,
              pincode: selectedAccount[0].address.pincode,
              stateCode: selectedAccount[0].address.stateCode,
              countryCode: selectedAccount[0].address.countryCode,
              state: selectedAccount[0].address.state,
              country: selectedAccount[0].address.country,
              district: selectedAccount[0].address.district,
              districtCode: selectedAccount[0].address.districtCode,
              city: selectedAccount[0].address.city,
              cityCode: selectedAccount[0].address.cityCode,
            },
            application: selectedAccount[0].application,
            accountAttachments: accountDoc,
          };
        } else {
          if (data.account.accountAttachments) {
            accountDoc = [
              {
                fileId: data.account.accountAttachments.fileId,
                fileName: data.account.accountAttachments.fileName,
                keyName: data.account.accountAttachments.keyName,
              },
            ];
          }
          associatedAccount = {
            requiresSync: data.account.requiresSync,
            id: data.account.id,
            mCode: data.account.mCode,
            name: data.account.name,
            email: data.account.email,
            mobileNumber: data.account.mobileNumber,
            pan: data.account.pan,
            gstin: data.account.gstin,
            source: data.account.source,
            isKeyAccount: data.account.isKeyAccount,
            salesManager: data.account.salesManager,
            createdOn: data.account.createdOn,
            createdBy: data.account.createdBy,
            userId: data.account.userId,
            userEmailAddress: data.account.userEmailAddress,
            employeeId: data.account.employeeId,
            accountTypeCode: data.account.accountTypeCode,
            customerCategoryCode: data.account.customerCategoryCode,
            numberOfContacts: data.account.numberOfContacts,
            externalCustomerId: data.account.externalCustomerId,
            address: {
              id: data.account.address.id,
              addressLine1: data.account.address.addressLine1,
              addressLine2: data.account.address.addressLine2,
              addressLine3: data.account.address.addressLine3,
              pincode: data.account.address.pincode,
              stateCode: data.account.address.stateCode,
              countryCode: data.account.address.countryCode,
              state: data.account.address.state,
              country: data.account.address.country,
              district: data.account.address.district,
              districtCode: data.account.address.districtCode,
              city: data.account.address.city,
              cityCode: data.account.address.cityCode,
            },
            application: data.account.application,
            accountAttachments: accountDoc,
          };
        }

        if (selectedState.length > 0) {
          stateName = selectedState[0].name;
        }

        let birthday = birthDate;
        if (
          moment(new Date()).format('DD/MM/YYYY') ===
          moment(birthDate).format('DD/MM/YYYY')
        ) {
          birthday = null;
        }
        let aniversary = marriageDate;
        if (
          moment(new Date()).format('DD/MM/YYYY') ===
          moment(marriageDate).format('DD/MM/YYYY')
        ) {
          aniversary = null;
        }

        const updatedData = {
          RequiresSync: true,
          id: data.id,
          mCode: data.mCode,
          firstName: firstNameValue,
          lastName: lastNameValue,
          email: emailIdValue,
          source: data.source,
          contactNumber: contactNumberValue,
          designation: designationValue,
          accountId: associatedAccount.id,
          roleCode: contactRoleValue,
          account: associatedAccount,
          role: contactRole,
          address: {
            id: data.address.id,
            addressLine1: addressLine1Value,
            addressLine2: addressLine2Value,
            addressLine3: addressLine3Value,
            pincode: pincodeValue,
            stateCode: stateValue,
            countryCode: data.address.countryCode,
            state: stateName,
            country: data.address.country,
            district: districtValue,
            districtCode: data.address.districtCode,
            city: cityValue,
            cityCode: data.address.cityCode,
          },
          personalDetails: {
            dateOfBirth: birthday,
            dateOfMarriage: aniversary,
            preferedLanguageId: preferredLanguageValue,
          },
          contactAttachments: contactDoc,
        };

        dispatch(updateContact(authUser.accessToken, updatedData));
      } else {
        console.log('Something goes wrong');
      }
    } else {
      message.warning('Please enter all required values.');
    }
  };

  return (
    <Modal
      cancelText="Close"
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      title="Contact Details"
      visible={visible}
      width={1000}>
      <Form>
        <table className="tableServiceDetails" width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td style={{float: 'right'}}>
                <Button
                  htmlType="submit"
                  onClick={OnSaveClick}
                  style={{
                    background: '#fd560eb5',
                    border: '#fd560eb5',
                    fontWeight: '550',
                  }}
                  type="primary">
                  Save
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Basic Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> M Contact Code</td>
                  <td className="tdHeader">
                    {' '}
                    Account Name <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      style={{width: '100%', marginRight: '10px'}}
                      value={data ? data.mCode : ''}
                    />
                  </td>
                  <td>
                    <AutoComplete
                      onChange={onChange}
                      onSelect={onSelect}
                      options={accountNameOptions}
                      style={{width: '100%', marginRight: '10px'}}
                      value={accountNameValue}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {requiredFieldsValue.account === true ? (
                      <span style={{color: 'red'}}>
                        Please select an account
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    First Name <span style={{color: 'red'}}>*</span>
                  </td>
                  <td className="tdHeader">
                    {' '}
                    Last Name <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={onFirstNameChange}
                      style={{width: '100%', marginRight: '10px'}}
                      type="text"
                      value={firstNameValue}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={onLastNameChange}
                      style={{width: '100%', marginRight: '10px'}}
                      type="text"
                      value={lastNameValue}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    {requiredFieldsValue.firstName === true ? (
                      <span style={{color: 'red'}}>
                        Please enter first name
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td>
                    {requiredFieldsValue.lastName === true ? (
                      <span style={{color: 'red'}}>Please enter last name</span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Email Id</td>
                  <td className="tdHeader">
                    {' '}
                    Contact Number <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={onEmailIdChange}
                      style={{width: '100%', marginRight: '10px'}}
                      type="text"
                      value={emailIdValue}
                    />
                  </td>

                  <td>
                    <Input
                      maxLength={10}
                      onChange={onContactNumberChange}
                      style={{width: '100%', marginRight: '10px'}}
                      type="text"
                      value={contactNumberValue}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.email}</span>
                  </td>
                  {/* <td>
                    {requiredFieldsValue.contactNumber === true ? (
                      <span style={{color: 'red'}}>
                        Please enter contact number.
                      </span>
                    ) : (
                      ''
                    )}
                  </td> */}
                  <td>
                    {requiredFieldsValue.contactNumber === true ? (
                      <span style={{color: 'red'}}>
                        Please enter contact number.
                      </span>
                    ) : formErrors.contactNumber !== '' ? (
                      <span style={{color: 'red'}}>
                        {formErrors.contactNumber}
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Designation</td>
                  <td className="tdHeader"> Role</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={onDesignationChange}
                      style={{width: '100%', marginRight: '10px'}}
                      type="text"
                      value={designationValue}
                    />
                  </td>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onContactRoleChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Role"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={contactRoleValue}>
                      {contactRolesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Address" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> State</td>
                  <td className="tdHeader">
                    {' '}
                    District <span style={{color: 'red'}}>*</span>
                  </td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onStateChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a State"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={stateValue}>
                      {statesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Input
                      onChange={onDistrictChange}
                      type="text"
                      value={districtValue}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    {requiredFieldsValue.district === true ? (
                      <span style={{color: 'red'}}>Please enter district</span>
                    ) : (
                      ''
                    )}
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> City</td>
                  <td className="tdHeader">Pincode</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={onCityChange}
                      type="text"
                      value={cityValue}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={onPincodeChange}
                      type="text"
                      value={pincodeValue}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.pincode}</span>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Address Line 1</td>
                  <td className="tdHeader">Address Line 2</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      maxLength={40}
                      onChange={onAddressLine1Change}
                      type="text"
                      value={addressLine1Value}
                    />
                  </td>
                  <td>
                    <Input
                      maxLength={40}
                      onChange={onAddressLine2Change}
                      type="text"
                      value={addressLine2Value}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Address Line 3</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      maxLength={40}
                      onChange={onAddressLine3Change}
                      type="text"
                      value={addressLine3Value}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Personal Details" key="3">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Date of Birth</td>
                  <td className="tdHeader"> Date of Marriage</td>
                </tr>
                <tr>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(birthDate, 'DD/MM/YYYY')}
                        format={'DD/MM/YYYY'}
                        onChange={OnBirthDateChanged}
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(birthDate, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(marriageDate, 'DD/MM/YYYY')}
                        format={'DD/MM/YYYY'}
                        onChange={OnMarriageDateChanged}
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(marriageDate, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Preferred Language</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onPreferredLanguageChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Language"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={preferredLanguageValue}>
                      {preferedLanguagesData?.map((p) => (
                        <Option value={p.id}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
export default ContactModalForm;
