import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Home from '../components/home/Home';

const HomeContainer = (props) => {
  return <Home {...props} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({});

//#endregion

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(HomeContainer);
