export const Account_REQUEST = 'Account_REQUEST';
export const Account_SUCCESS = 'Account_SUCCESS';
export const Account_FAILURE = 'Account_FAILURE';
export const AccountCount_REQUEST = 'AccountCount_REQUEST';
export const AccountCount_SUCCESS = 'AccountCount_SUCCESS';
export const AccountCount_FAILURE = 'AccountCount_FAILURE';
export const MYACCOUNT_REQUEST = 'MYACCOUNT_REQUEST';
export const MYACCOUNT_SUCCESS = 'MYACCOUNT_SUCCESS';
export const MYACCOUNT_FAILURE = 'MYACCOUNT_FAILURE';
export const ACCOUNTUPDATE_REQUEST = 'ACCOUNTUPDATE_REQUEST';
export const ACCOUNTUPDATE_SUCCESS = 'ACCOUNTUPDATE_SUCCESS';
export const ACCOUNTUPDATE_FAILURE = 'ACCOUNTUPDATE_FAILURE';
export const Account_PAN_REQUEST = 'Account_PAN_REQUEST';
export const Account_PAN_SUCCESS = 'Account_PAN_SUCCESS';
export const Account_PAN_FAILURE = 'Account_PAN_FAILURE';
export const Account_GST_REQUEST = 'Account_GST_REQUEST';
export const Account_GST_SUCCESS = 'Account_GST_SUCCESS';
export const Account_GST_FAILURE = 'Account_GST_FAILURE';
export const Account_MOBILE_REQUEST = 'Account_MOBILE_REQUEST';
export const Account_MOBILE_SUCCESS = 'Account_MOBILE_SUCCESS';
export const Account_MOBILE_FAILURE = 'Account_MOBILE_FAILURE';
export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
