import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Pagination, Table, Button} from 'antd';
import {accountColumns} from '../../data/accountsColumns';
import states from '../../data/states';
import districtsData from '../../data/districts';
import DataFilterBox from '../common/DataFilterBox';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getAccounts, getAccountsCount} from '../../modules/accounts/action';
import {getAccountsDataCount} from '../../modules/accounts/selectors';
import AccountModalForm from '../common/AccountModal';

import {API_URL} from '../../constants/envVariables';

import {downloadCsv} from '../../utils/downloadCsv';
import {request} from '../../utils/HttpClient';
import getQueryString from '../../utils/getQueryString';

const Account = ({accounts}) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [stateFilter, setStateFilter] = useState(() => 'All');
  const [districtFilter, setDistrictFilter] = useState(() => 'All');
  const [fromDate, setFromDateFilter] = useState(
    moment(new Date()).add(-7, 'day').toDate(),
  );
  const dataCount = useSelector(getAccountsDataCount);
  const [toDate, setToDateFilter] = useState(moment(new Date()).toDate());
  const [filterDistricts, setDistrict] = useState(() => []);
  const [filteredAccountData, setAccountData] = useState();
  const authUser = useSelector(getAuthUserData);
  const [pageNumber, setPageNumber] = useState(1);

  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [pageChangeClicked, setPageChangeClicked] = useState(false);

  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const [filteredName, setFilteredName] = useState('');
  const [filteredMCode, setFilteredMCode] = useState('');

  useEffect(() => {
    dispatch(
      getAccountsCount(
        authUser.accessToken,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD'),
        '',
        '',
        '',
      ),
    );
  }, [dispatch, authUser.accessToken]);

  const pageChanged = (page, pageSize) => {
    setConfig((prevState) => ({
      pagination: {
        ...prevState.pagination,
        defaultPageSize: pageSize,
        pageSize: pageSize,
      },
    }));
    setPageSizeNew(pageSize);
    setPageNumber(page);
    setPageChangeClicked(!pageChangeClicked);
  };

  useEffect(() => {
    if (pageChangeClicked) {
      dispatch(
        getAccounts(
          authUser.accessToken,
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          moment(toDate).format('YYYY-MM-DD'),
          moment(fromDate).format('YYYY-MM-DD'),
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
          filteredName,
        ),
      );
      setPageChangeClicked(!pageChangeClicked);
    }
  }, [pageNumber, pageSizeNew]);

  const onStateChanged = (value) => {
    setStateFilter(value);
    setDistrict(
      districtsData.filter((item) => item.state.includes(value)).length > 0
        ? districtsData.filter((item) => item.state.includes(value))[0]
            .districts
        : districtFilter,
    );
  };
  const onDistrictChanged = (value) => {
    setDistrictFilter(value);
  };

  const fromDateChanged = (value) => {
    setFromDateFilter(value._d);
  };
  const toDateChanged = (value) => {
    setToDateFilter(value._d);
  };
  const onResetDataClick = () => {
    setStateFilter(() => 'All');
    setDistrictFilter(() => 'All');
    setFromDateFilter(moment(new Date()).add(-7, 'day').toDate());
    setToDateFilter(moment(new Date()));
    // console.log(toDate);
    // console.log(fromDate);
    setFilteredName('');
    setFilteredMCode('');
    dispatch(
      getAccounts(
        authUser.accessToken,
        1,
        pageSizeNew,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        '',
        '',
        '',
      ),
    );
    dispatch(
      getAccountsCount(
        authUser.accessToken,
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        '',
        '',
        '',
      ),
    );
  };
  const onFilterDataClick = () => {
    getFilteredData();
  };

  const getFilteredData = () => {
    if (filteredMCode) {
      dispatch(
        getAccountsCount(
          authUser.accessToken,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          filteredName,
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
        ),
      );
      dispatch(
        getAccounts(
          authUser.accessToken,
          pageNumber,
          pageSizeNew,
          '',
          '',
          '',
          '',
          '',
          filteredMCode,
        ),
      );
    } else {
      dispatch(
        getAccountsCount(
          authUser.accessToken,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          filteredName,
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
        ),
      );
      dispatch(
        getAccounts(
          authUser.accessToken,
          pageNumber,
          pageSizeNew,
          moment(toDate).format('YYYY-MM-DD'),
          moment(fromDate).format('YYYY-MM-DD'),
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
          filteredName,
        ),
      );
    }
  };

  const getDetails = (e, record) => {
    setVisible(!visible);
    setModalData(record);
  };

  const handleCreate = () => {};

  const handleModalSubmit = () => {
    dispatch(
      getAccounts(
        authUser.accessToken,
        pageNumber === 0 ? 1 : pageNumber,
        pageSizeNew,
        moment(toDate).format('YYYY-MM-DD'),
        moment(fromDate).format('YYYY-MM-DD'),
        stateFilter === 'All' ? '' : stateFilter,
        districtFilter === 'All' ? '' : districtFilter,
        filteredName,
      ),
    );
  };

  const onDownloadDataClick = () => {
    callApiAndDownloadCsv({
      Name: filteredName,
      FromDate: moment(fromDate).format('YYYY-MM-DD'),
      ToDate: moment(toDate).format('YYYY-MM-DD'),
      State: stateFilter === 'All' ? '' : stateFilter,
      District: districtFilter === 'All' ? '' : districtFilter,
    });
  };

  useEffect(() => {
    setAccountData(accounts.data);
  }, [accounts.data]);

  const onUserNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredName(target.value.trim());
  };

  const onMCodeChange = ({target}) => {
    setPageNumber(1);
    setFilteredMCode(target.value.trim());
  };

  const updatedAccount = [
    ...accountColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];

  const items = [
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: 'State',
      fieldType: 'Select',
      options: states,
      onChange: onStateChanged,
      value: stateFilter,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: 'District',
      fieldType: 'Select',
      options: filterDistricts,
      onChange: onDistrictChanged,
      value: districtFilter,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromDateChanged,
      value: fromDate,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toDateChanged,
      value: toDate,
    },
    {
      fieldId: 5,
      fieldName: 'Filter data',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterDataClick,
    },
    {
      fieldId: 6,
      fieldName: 'Reset',
      fieldTitle: '',
      fieldType: 'Button',
      customStyle: {marginLeft: 10, marginRight: 0},
      onClick: onResetDataClick,
    },
    {
      fieldId: 7,
      fieldName: 'Download Data Dump',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onDownloadDataClick,
    },
    {
      fieldId: 9,
      fieldName: 'Filter By Account Name',
      fieldTitle: '',
      fieldMargin: '17px',
      fieldType: 'Label',
    },
    {
      fieldId: 8,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Account Name',
      onChange: onUserNameChange,
      value: filteredName,
    },
    {
      fieldId: 13,
      fieldType: 'Col',
    },
    {
      fieldId: 14,
      fieldName: 'Filter By Account MCode',
      fieldTitle: '',
      fieldMargin: '17px',
      fieldType: 'Label',
    },
    {
      fieldId: 15,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Code',
      onChange: onMCodeChange,
      value: filteredMCode,
    },
  ];

  const callApiAndDownloadCsv = async (filters) => {
    const filterQueryString = getQueryString(filters);
    const url = `${API_URL}/Account/GetAccountsPortalDownload?${filterQueryString}`;
    const data = await request(url, authUser.accessToken);
    if (data) {
      downloadCsv(data.payload, 'Accounts_Details');
    }
  };

  return (
    <MainLayout>
      <DataFilterBox items={items} title="Accounts Filter"></DataFilterBox>
      <div className="contentLayout">
        <span className="contentHeader">Accounts</span>
        <Pagination
          defaultCurrent={1}
          onChange={pageChanged}
          style={{float: 'right'}}
          total={dataCount === 0 ? 1 : dataCount}
        />
        <AccountModalForm
          data={modalData}
          onCancel={() => {
            setVisible(false);
            setStateFilter(() => 'All');
            setDistrictFilter(() => 'All');
            setFromDateFilter(moment(new Date()).add(-7, 'day').toDate());
            setToDateFilter(moment(new Date()));
            // console.log(toDate);
            // console.log(fromDate);
            setFilteredName('');
          }}
          onCreate={() => handleCreate()}
          onSubmit={() => handleModalSubmit()}
          visible={visible}
        />
        <Table
          columns={updatedAccount}
          dataSource={filteredAccountData}
          loading={accounts.isFetching}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};

export default Account;
