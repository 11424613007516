import {createSelector} from 'reselect';

export const getBulkUpload = ({bulkUpload}) => bulkUpload;

export const getStatusList = createSelector(
  getBulkUpload,
  (bulkUpload) => bulkUpload?.statusList,
);

export const getBulkUploadResponses = createSelector(
  getBulkUpload,
  (bulkUpload) => bulkUpload.bulkUploadResponse,
);

export const getBulkUploadFilterFileResponses = createSelector(
  getBulkUpload,
  (bulkUpload) => bulkUpload.bulkUploadFilterFileResponse,
);

export const BulkUploadRequestSelector = createSelector(
  getBulkUpload,
  (bulkUpload) => bulkUpload.bulkUploadRequest,
);
