import {Parser} from 'json2csv';

export const downloadCsv = (data, fileName) => {
  if (data && data.length > 0) {
    const headers = Object.keys(data[0]);

    const parser = new Parser(headers);
    const csv = parser.parse(data);
    var blob = new Blob([csv], {type: 'text/csv'});
    var d = new Date();
    var month = d.getMonth() + 1;
    d = d.getDate() + '_' + month + '_' + d.getFullYear();
    if (window.navigator.msSaveOrOpenBlob)
      window.navigator.msSaveBlob(blob, `${fileName}_${d}.csv`);
    else {
      var a = window.document.createElement('a');
      a.href = window.URL.createObjectURL(blob, {type: 'text/plain'});
      a.download = `${fileName}_${d}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }
};
