export default {
  overdueStage: {
    title: 'Overdue',
    value: 0,
    background: '#FF0000',
  },
  futuredueStage: {
    title: 'Future Due',
    value: 0,
    background: '#9CA3AF',
  },
  coldStage: {
    title: 'Cold',
    value: 0,
    background: '#08BDBD',
  },
  warmStage: {
    title: 'Warm',
    value: 0,
    background: '#6366F1',
  },
  hotStage: {
    title: 'Hot',
    value: 0,
    background: '#F24C00',
  },
  sureShotStage: {
    title: 'Sure shot',
    value: 0,
    background: '#059669',
  },
};
