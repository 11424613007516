import produce from 'immer';
import {combineReducers} from 'redux';
import {
  FETCH_DASHBOARD_REQUEST,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
  DOWNLOAD_REQUEST,
  DOWNLOAD_FAILURE,
  DOWNLOAD_SUCCESS,
  DASHBOARDFILTER_FAILURE,
  DASHBOARDFILTER_REQUEST,
  DASHBOARDFILTER_SUCCESS,
  PRODUCTCATEGORY_FAILURE,
  PRODUCTCATEGORY_REQUEST,
  PRODUCTCATEGORY_SUCCESS,
  PRODUCTSUBCATEGORY_FAILURE,
  PRODUCTSUBCATEGORY_REQUEST,
  PRODUCTSUBCATEGORY_SUCCESS,
  DEALERSSALESREPRESENTATIVE_FAILURE,
  DEALERSSALESREPRESENTATIVE_REQUEST,
  DEALERSSALESREPRESENTATIVE_SUCCESS,
  MODELVARIANT_FAILURE,
  MODELVARIANT_REQUEST,
  MODELVARIANT_SUCCESS,
  RESET_SAlESREPRESENTATIVE,
  RESET_MODEL,
  RESET_PRODUCTSUBCATEGORY,
  TABLEDATA_REQUEST,
  TABLEDATA_SUCCESS,
  TABLEDATA_FAILURE,
} from './constants';

const error = (state = null, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_FAILURE:
      return action.payload;
    case FETCH_DASHBOARD_REQUEST:
    case FETCH_DASHBOARD_SUCCESS:
      return null;
    default:
      return state;
  }
};

const hasFetched = (state = false, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_FAILURE:
    case FETCH_DASHBOARD_SUCCESS:
      return true;
    default:
      return state;
  }
};

const isFetching = (state = false, action) => {
  switch (action.type) {
    case FETCH_DASHBOARD_REQUEST:
      return true;
    case FETCH_DASHBOARD_FAILURE:
    case FETCH_DASHBOARD_SUCCESS:
      return false;
    default:
      return state;
  }
};

const payload = produce((draft, action) => {
  // eslint-disable-next-line default-case
  switch (action.type) {
    case FETCH_DASHBOARD_SUCCESS:
      return action.payload;
    case FETCH_DASHBOARD_REQUEST:
    case FETCH_DASHBOARD_FAILURE:
      return null;
  }
}, null);

const isFetchingDownloadData = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUEST:
      return true;
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_FAILURE:
      return false;
    default:
      return state;
  }
};

const downloadedData = (state = null, action) => {
  switch (action.type) {
    case DOWNLOAD_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDownload = (state = null, action) => {
  switch (action.type) {
    case DOWNLOAD_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDownloadFetched = (state = false, action) => {
  switch (action.type) {
    case DOWNLOAD_REQUEST:
      return false;
    case DOWNLOAD_SUCCESS:
    case DOWNLOAD_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingTableData = (state = false, action) => {
  switch (action.type) {
    case TABLEDATA_REQUEST:
      return true;
    case TABLEDATA_SUCCESS:
    case TABLEDATA_FAILURE:
      return false;
    default:
      return state;
  }
};

const tableData = (state = null, action) => {
  switch (action.type) {
    case TABLEDATA_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureTableData = (state = null, action) => {
  switch (action.type) {
    case TABLEDATA_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasTableDataFetched = (state = false, action) => {
  switch (action.type) {
    case TABLEDATA_REQUEST:
      return false;
    case TABLEDATA_SUCCESS:
    case TABLEDATA_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingDashboardFilterData = (state = false, action) => {
  switch (action.type) {
    case DASHBOARDFILTER_REQUEST:
      return true;
    case DASHBOARDFILTER_SUCCESS:
    case DASHBOARDFILTER_FAILURE:
      return false;
    default:
      return state;
  }
};

const DashboardFilterData = (state = null, action) => {
  switch (action.type) {
    case DASHBOARDFILTER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDashboardFilter = (state = null, action) => {
  switch (action.type) {
    case DASHBOARDFILTER_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDashboardFilterFetched = (state = false, action) => {
  switch (action.type) {
    case DASHBOARDFILTER_REQUEST:
      return false;
    case DASHBOARDFILTER_SUCCESS:
    case DASHBOARDFILTER_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingProductCategoryData = (state = false, action) => {
  switch (action.type) {
    case PRODUCTCATEGORY_REQUEST:
      return true;
    case PRODUCTCATEGORY_SUCCESS:
    case PRODUCTCATEGORY_FAILURE:
      return false;
    default:
      return state;
  }
};

const ProductCategoryFilterData = (state = null, action) => {
  switch (action.type) {
    case PRODUCTCATEGORY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureProductCategoryFilter = (state = null, action) => {
  switch (action.type) {
    case PRODUCTCATEGORY_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasProductCategoryFilterFetched = (state = false, action) => {
  switch (action.type) {
    case PRODUCTCATEGORY_REQUEST:
      return false;
    case PRODUCTCATEGORY_SUCCESS:
    case PRODUCTCATEGORY_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingProductSubCategoryData = (state = false, action) => {
  switch (action.type) {
    case PRODUCTSUBCATEGORY_REQUEST:
      return true;
    case PRODUCTSUBCATEGORY_SUCCESS:
    case PRODUCTSUBCATEGORY_FAILURE:
      return false;
    default:
      return state;
  }
};

const ProductSubCategoryFilterData = (state = null, action) => {
  switch (action.type) {
    case PRODUCTSUBCATEGORY_SUCCESS:
      return action.payload;
    case RESET_PRODUCTSUBCATEGORY:
      return [];
    default:
      return state;
  }
};

const failureProductSubCategoryFilter = (state = null, action) => {
  switch (action.type) {
    case PRODUCTSUBCATEGORY_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasProductSubCategoryFilterFetched = (state = false, action) => {
  switch (action.type) {
    case PRODUCTSUBCATEGORY_REQUEST:
      return false;
    case PRODUCTSUBCATEGORY_SUCCESS:
    case PRODUCTSUBCATEGORY_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingModelVariantData = (state = false, action) => {
  switch (action.type) {
    case MODELVARIANT_REQUEST:
      return true;
    case MODELVARIANT_SUCCESS:
    case MODELVARIANT_FAILURE:
      return false;
    default:
      return state;
  }
};

const ModelVariantFilterData = (state = null, action) => {
  switch (action.type) {
    case MODELVARIANT_SUCCESS:
      return action.payload;
    case RESET_MODEL:
      return [];
    default:
      return state;
  }
};

const failureModelVariantFilter = (state = null, action) => {
  switch (action.type) {
    case MODELVARIANT_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasModelVariantFilterFetched = (state = false, action) => {
  switch (action.type) {
    case MODELVARIANT_REQUEST:
      return false;
    case MODELVARIANT_SUCCESS:
    case MODELVARIANT_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingDealersSalesRepresentative = (state = false, action) => {
  switch (action.type) {
    case DEALERSSALESREPRESENTATIVE_REQUEST:
      return true;
    case DEALERSSALESREPRESENTATIVE_SUCCESS:
    case DEALERSSALESREPRESENTATIVE_FAILURE:
      return false;
    default:
      return state;
  }
};

const dealersSalesRepresentativeData = (state = null, action) => {
  switch (action.type) {
    case DEALERSSALESREPRESENTATIVE_SUCCESS:
      return action.payload;
    case RESET_SAlESREPRESENTATIVE:
      return [];
    default:
      return state;
  }
};

const failureDealerSaleRepresentativeFilter = (state = null, action) => {
  switch (action.type) {
    case DEALERSSALESREPRESENTATIVE_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasDealerSalesRepresentativeFetched = (state = false, action) => {
  switch (action.type) {
    case DEALERSSALESREPRESENTATIVE_REQUEST:
      return false;
    case DEALERSSALESREPRESENTATIVE_SUCCESS:
    case DEALERSSALESREPRESENTATIVE_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  error,
  hasFetched,
  isFetching,
  payload,
  hasDownloadFetched,
  isFetchingDownloadData,
  downloadedData,
  failureDownload,
  isFetchingDashboardFilterData,
  DashboardFilterData,
  hasDashboardFilterFetched,
  failureDashboardFilter,
  ProductCategoryFilterData,
  isFetchingProductCategoryData,
  failureProductCategoryFilter,
  hasProductCategoryFilterFetched,
  isFetchingProductSubCategoryData,
  ProductSubCategoryFilterData,
  failureProductSubCategoryFilter,
  hasProductSubCategoryFilterFetched,
  ModelVariantFilterData,
  isFetchingModelVariantData,
  failureModelVariantFilter,
  hasModelVariantFilterFetched,
  dealersSalesRepresentativeData,
  isFetchingDealersSalesRepresentative,
  hasDealerSalesRepresentativeFetched,
  failureDealerSaleRepresentativeFilter,
  isFetchingTableData,
  hasTableDataFetched,
  tableData,
  failureTableData,
});
