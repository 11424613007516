import {
  ADDRESS_FAILURE,
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  DiSTRICT_REQUEST,
  DiSTRICT_FAILURE,
  DiSTRICT_SUCCESS,
  USERADDRESS_REQUEST,
  USERADDRESS_SUCCESS,
  USERADDRESS_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const addressesData = (state = null, action) => {
  switch (action.type) {
    case ADDRESS_REQUEST:
      return state;
    case ADDRESS_SUCCESS:
      return action.payload;
    case ADDRESS_FAILURE:
      return state;
    default:
      return state;
  }
};

const addressesUserData = (state = null, action) => {
  switch (action.type) {
    case USERADDRESS_REQUEST:
      return state;
    case USERADDRESS_SUCCESS:
      return action.payload;
    case USERADDRESS_FAILURE:
      return state;
    default:
      return state;
  }
};

const districtsData = (state = null, action) => {
  switch (action.type) {
    case DiSTRICT_REQUEST:
      return state;
    case DiSTRICT_SUCCESS:
      return action.payload;
    case DiSTRICT_FAILURE:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  addressesData,
  districtsData,
  addressesUserData,
});
