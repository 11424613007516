import {combineReducers} from 'redux';
import app from './app/reducer';
import auth from './auth/reducer';
import accounts from './accounts/reducer';
import dashboard from './dashboard/reducer';
import contacts from './contacts/reducer';
import userInfo from './userDetails/reducer';
import forceChangePassword from './auth/forceChangePassword/reducer';
import forgotPasswordVerificationCode from './auth/forgotPassword/forgotPasswordVerificationCode/reducer';
import forgotPasswordChanged from './auth/forgotPassword/reducer';
import opportunities from './opportunities/reducer';
import activities from './activity/reducer';
import territoryFilter from './territoryFilter/reducer';
import address from './address/reducer';
import contactroles from './contactroles/reducer';
import preferedlanguage from './preferedlanguage/reducer';
import accountmaster from './accountmaster/reducer';
import dropdowns from './dropdowns/reducer';
import bulkUpload from './bulkUpload/reducer';
import salesMaster from './salesTools/reducer'

const appReducer = combineReducers({
  app,
  auth,
  accounts,
  contacts,
  dashboard,
  dropdowns,
  userInfo,
  forceChangePassword,
  forgotPasswordVerificationCode,
  forgotPasswordChanged,
  opportunities,
  activities,
  territoryFilter,
  address,
  contactroles,
  preferedlanguage,
  accountmaster,
  bulkUpload,
  salesMaster,
});

export const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STORE') return appReducer(undefined, action);
  return appReducer(state, action);
};

// export const rootReducer = combineReducers({
//   app,
//   auth,
//   accounts,
//   contacts,
//   userInfo,
//   forceChangePassword,
//   forgotPasswordVerificationCode,
//   forgotPasswordChanged,
//   opportunities,
// });
