import React from 'react';
import moment from 'moment';

export const bulkUploadColumns = [
  {
    title: 'Transaction Type',
    dataIndex: 'uploadtype',
    key: 'id',
    defaultSortOrder: ['ascend', 'descend'],
    sorter: (a, b) => {
      return a.uploadtype.localeCompare(b.uploadtype);
    },
  },
  {
    title: 'Uploaded File Name',
    dataIndex: 'fileName',
    key: 'id',
    defaultSortOrder: ['ascend', 'descend'],
    sorter: (a, b) => {
      a = a.fileName || '';
      b = b.fileName || '';
    },
  },
  {
    title: 'JobId',
    dataIndex: 'jobId',
    key: 'id',
    defaultSortOrder: ['ascend', 'descend'],
    sorter: (a, b) => {
      return a.jobId.localeCompare(b.jobId);
    },
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'id',
    defaultSortOrder: ['ascend', 'descend'],
    sorter: (a, b) => {
      return a.status.localeCompare(b.status);
    },
  },
  {
    title: 'Upload Date',
    dataIndex: 'uploadDate',
    key: 'id',
    defaultSortOrder: ['ascend', 'descend'],
    sorter: (a, b) => {
      return a.uploadDate.localeCompare(b.uploadDate);
    },
  },
];
