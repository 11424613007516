import React, {useEffect} from 'react';
import {bindActionCreators} from 'redux';
import {connect, useSelector} from 'react-redux';
import Contacts from '../components/contacts/Contacts';
import {getContacts} from '../modules/contacts/action';
import {getAuthUserData} from '../modules/auth/user/selector';
import moment from 'moment';

const ContactsContainer = ({getContacts, contacts, hasContactsFetched}) => {
  const authUser = useSelector(getAuthUserData);
  useEffect(() => {
    if (!hasContactsFetched) {
      getContacts(
        1,
        10,
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        '',
        '',
        authUser.accessToken,
        '',
        '',
      );
    }
  }, [getContacts, hasContactsFetched, authUser.accessToken]);
  return <Contacts contacts={contacts} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({
  contacts: state.contacts,
  hasContactsFetched: state.hasContactsFetched,
});

//#endregion

const mapDispatchToProps = (dispatch: any) =>
  bindActionCreators({getContacts}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(ContactsContainer);
