import React, {useEffect, useState} from 'react';
import {Card, Col, Row} from 'antd';
import MainLayout from '../../layouts/MainLayout';
import {Spin} from 'antd';
import moment from 'moment';
import {API_URL} from '../../constants/envVariables';
import {useSelector, useDispatch} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {request} from '../../utils/HttpClient';
import {getSalesToolsMaster} from '../../modules/salesTools/action';
import {data} from '../../data/accountsColumns';
import axios from 'axios';

export const SalesTools = () => {
  const authUser = useSelector(getAuthUserData);
  const [salesToolsMaster, setsalesToolsMaster] = useState([]);
  const [salesToolsChildren, setsalesToolsChildren] = useState([]);
  const [salesFiles, setsalesFiles] = useState([]);
  const [isSalesMasterClicked, setSalesMasterClicked] = useState(false);
  const [hasChildren, setHasChildren] = useState(false);
  const dispatch = useDispatch();
  const [isLoading, setisLoading] = useState(true);

  useEffect(() => {
    setSalesMasterClicked(false);
    getAccountDetails(authUser.accessToken);
  }, [dispatch, authUser.accessToken]);

  const getAccountDetails = async (accessToken) => {
    return await axios
      .get(`${API_URL}/Saleskit/Categories`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((obj) => {
        setsalesToolsMaster(obj.data);
        setisLoading(false);
      })
      .catch((err) => {});
  };

  const downloadFile = async (accessToken, keyName) => {
    return await axios
      .get(`${API_URL}/Saleskit/DownloadUrl?itemKey=${keyName}`, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${accessToken}`,
        },
      })
      .then((obj) => {
        window.open(obj.data.url, '_blank', 'noreferrer');
      })
      .catch((err) => {});
  };

  const onSalesOptionClick = (value, obj) => {
    setSalesMasterClicked(true);
    if (value == null || value == '') {
      setHasChildren(false);
      setsalesFiles(obj.files);
    } else {
      setHasChildren(true);
      setsalesFiles(value.files);
      setsalesToolsChildren(value);
    }
  };

  const onClickDownlodFile = (value) => {
    downloadFile(authUser.accessToken, value);
  };
  return (
    <MainLayout>
      <div className="salesTools">
        <span className="contentHeader">Sales Tools</span>
      </div>
      {isLoading === false ? (
        <>
          <div className="contentLayout">
            {salesToolsMaster.map((obj) => (
              <div
                className="salesToolChildDiv"
                key={obj.key}
                onClick={() => onSalesOptionClick(obj.children, obj)}>
                {obj.label}
              </div>
            ))}
          </div>
          {isSalesMasterClicked & hasChildren ? (
            <div className="contentLayout row">
              {salesToolsChildren.map((obj) => (
                <div
                  className="salesToolChildDiv"
                  key={obj.key}
                  onClick={() => onSalesOptionClick(obj.children, obj)}>
                  {obj.label}
                </div>
              ))}
            </div>
          ) : isSalesMasterClicked & !hasChildren ? (
            <div className="contentLayout">
              {salesFiles.map((obj) => (
                <div
                  className="salesToolChildDiv"
                  key={obj.keyName}
                  onClick={() => onClickDownlodFile(obj.keyName)}>
                  {obj.fileName}
                </div>
              ))}
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <>
          <div style={{marginLeft: 500}}>
            <Spin />
          </div>
        </>
      )}
    </MainLayout>
  );
};
