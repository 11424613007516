import React from 'react';
import {Modal, Form, Collapse, Input, Checkbox, Table, Radio} from 'antd';
import moment from 'moment';

const {Panel} = Collapse;

const OpportunityModalForm = ({visible, onCancel, onCreate, data}) => {
  const {TextArea} = Input;
  return (
    <Modal
      cancelText="Close"
      footer={null}
      onCancel={onCancel}
      title="Opportunity Details"
      visible={visible}
      width={1000}>
      <Form>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Basic Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> M-Opty Code</td>
                  <td className="tdHeader"> Account Id</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      style={{marginRight: '10px'}}
                      value={data ? data.mCode : ''}
                    />
                  </td>
                  <td>
                    <Input disabled value={data ? data.accountMCode : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Account Name</td>

                  <td className="tdHeader"> Contact</td>
                  <td className="tdHeader"> District</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.accountName : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.contactName : ''} />
                  </td>
                  <td>
                    <Input
                      disabled
                      value={data ? data.contactDistrictName : ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Variant-Code</td>
                  <td className="tdHeader"> BP-Code</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.variant : ''} />
                  </td>

                  <td>
                    <Input disabled value={''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Opportunity Created By</td>
                  <td className="tdHeader"> Opportunity Type</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.createdByName : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.opportunityType : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Model</td>
                  <td className="tdHeader"> Quantity</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.modelName : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.quantity : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"></td>

                  <td className="tdHeader">
                    {'  '}Bulk Deal{'  '}
                    <Checkbox
                      checked={data ? data.isBulkDeal : false}
                      defaultChecked={false}
                      disabled
                    />
                    {'  '}Tender{'  '}
                    <Checkbox
                      checked={data ? data.isTender : false}
                      defaultChecked={false}
                      disabled
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Estimated Closing Date</td>
                  <td className="tdHeader"> Start Date</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.estimatedClosingDate
                            ? moment(data.estimatedClosingDate).format(
                                'DD/MM/YYYY hh:mm A',
                              )
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.startDate
                            ? moment(data.startDate).format(
                                'DD/MM/YYYY hh:mm A',
                              )
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Created Date</td>
                  <td className="tdHeader"> Remarks</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.createDate
                            ? moment(data.createDate).format(
                                'DD/MM/YYYY hh:mm A',
                              )
                            : ''
                          : ''
                      }
                    />
                  </td>
                  <td>
                    <TextArea rows={4} value={data ? data.remarks : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Key Account</td>
                  <td className="tdHeader"> Opty Status</td>
                </tr>
                <tr>
                  <td>
                    <Checkbox
                      checked={data ? data.keyAccount : false}
                      defaultChecked={false}
                      disabled
                    />
                  </td>
                  <td className="tdHeader">
                    <Radio.Group value={data ? data.opportunityStatus : ''}>
                      <Radio value={'OPEN'}>Open</Radio>
                      <Radio value={'WON'}>Won</Radio>
                      <Radio value={'LOST'}>Lost</Radio>
                      <Radio value={'DROPPED'}>Dropped</Radio>
                    </Radio.Group>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Additional Details" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Financier</td>
                  <td className="tdHeader"> Contact</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.financierName : ''} />
                  </td>
                  <td>
                    <Input
                      disabled
                      value={data ? data.financierContactName : ''}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Customer Type</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.customerTypeName : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Usage</td>
                  <td className="tdHeader">Origin</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.usageName : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.originName : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Origin Source</td>
                  <td className="tdHeader">Variant</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.originSource : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.variant : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Project</td>
                  <td className="tdHeader"> Site</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.project : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.site : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    With Attachment{' '}
                    <Checkbox
                      checked={
                        data
                          ? data.isAttachmentAvailable === 'Yes'
                            ? true
                            : false
                          : false
                      }
                      defaultChecked={false}
                      disabled
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Competitor Details" key="3">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                {data
                  ? data.competitors
                    ? data.competitors.map((item) => {
                        return (
                          <>
                            <tr>
                              <td className="tdHeader">Competitor Name</td>
                              <td className="tdHeader"> Model</td>
                            </tr>
                            <tr>
                              <td>
                                <Input disabled value={item.competitorName} />
                              </td>
                              <td>
                                <Input disabled value={item.modelCode} />
                              </td>
                            </tr>
                            <tr>
                              <td className="tdHeader">Value</td>
                              <td className="tdHeader">Lost to Competitor</td>
                            </tr>
                            <tr>
                              <td>
                                <Input disabled value={item.value} />
                              </td>
                              <td>
                                <Input
                                  disabled
                                  value={item.isLostToCompetitor}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="tdHeader">Remarks</td>
                            </tr>
                            <tr>
                              <td>
                                <TextArea rows={4} value={item.remarks} />
                              </td>
                            </tr>
                          </>
                        );
                      })
                    : ''
                  : ''}
              </tbody>
            </table>
          </Panel>
          <Panel header="Opportunity Stage" key="4">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">Opty Stages</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={
                        data
                          ? data.opportunityStages
                            ? data.opportunityStages.opportunityStage
                            : ''
                          : ''
                      }
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Opportunity Reasons" key="5">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">Reasons 1</td>
                  <td className="tdHeader">Reasons 2</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.reason1Desc : ''} />
                  </td>
                  <td>
                    <Input disabled value={data ? data.reason2Desc : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Reasons 3</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data ? data.reason3Desc : ''} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
export default OpportunityModalForm;
