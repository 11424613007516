import {createSelector} from 'reselect';

export const getActivitiesDetails = ({activities}) => activities;

export const getActivitiesData = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.activitiesData,
);
export const getActivitiesCountData = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.activitiesDataCount,
);
export const fetchingActivities = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.isFetchingActivities,
);
export const fetchingActivitiesCount = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.isFetchingActivitiesCount,
);
export const fetchingActivitiesType = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.isFetchingActivitiesType,
);
export const getActivitiesTypeData = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.activitiesTypeData,
);

export const updateActivityData = createSelector(
  getActivitiesDetails,
  (getActivitiesDetails) => getActivitiesDetails.updateActivityData,
);
