import {createSelector} from 'reselect';

export const getContacts = ({contacts}) => contacts;

export const getContactsData = createSelector(
  getContacts,
  (contacts) => contacts.dataContactsCount,
);
export const isFetchedContacts = createSelector(
  getContacts,
  (contacts) => contacts.hasContactsCountFetched,
);

export const getContactsDataCount = createSelector(
  getContacts,
  (contacts) => contacts.dataContactsCount,
);

export const updateContactDataSelector = createSelector(
  getContacts,
  (contacts) => contacts.updateContactData,
);

export const getContactsDataByMCode = createSelector(
  getContacts,
  (contacts) => contacts.dataContactsByMCode,
);

export const contactsData = createSelector(
  getContacts,
  (contacts) => contacts.dataContacts,
);

export const financierContactsData = createSelector(
  getContacts,
  (contacts) => contacts.dataFinancierContactsByMCode,
);
