import React from 'react';
import moment from 'moment';

export const accountColumns = [
  {
    title: 'MCode',
    dataIndex: 'mCode',
    key: 'id',
    sorter: {
      compare: (a, b) => a.mCode / b.mCode,
    },
  },
  {
    title: 'BP Code',
    dataIndex: 'externalCustomerId',
    key: 'id',
  },
  {
    title: 'Account Name',
    dataIndex: 'name',
    key: 'id',
    sorter: (a, b) => {
      a = a.name === null ? '' : a.name;
      b = b.name === null ? '' : b.name;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Phone Number',
    dataIndex: 'mobileNumber',
    key: 'id',
    // sorter: {
    //   compare: (a, b) => a.mobileNumber / b.mobileNumber,
    // },
  },
  {
    title: 'Number Of Contacts',
    dataIndex: 'numberOfContacts',
    key: 'id',
    sorter: {
      compare: (a, b) => a.numberOfContacts / b.numberOfContacts,
    },
  },
  {
    title: 'District',
    dataIndex: ['address', 'district'],
    key: 'id',
    // sorter: {
    //   compare: (a, b) =>
    //     a.address.districtCode.localeCompare(b.address.districtCode),
    // },
  },
  {
    title: 'State',
    dataIndex: ['address', 'state'],
    key: 'id',
    // sorter: {
    //   compare: (a, b) => a.address.stateCode.localeCompare(b.address.stateCode),
    // },
  },
  {
    title: 'Created Name',
    dataIndex: 'createdBy',
    key: 'id',
    sorter: {
      compare: (a, b) => a.createdBy.localeCompare(b.createdBy),
    },
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdOn',
    key: 'id',
    render: (createdOn) => {
      return createdOn ? (
        <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.createdOn) / moment(b.createdOn),
    },
  },
  // {
  //   title: 'Action',
  //   dataIndex: 'action',
  //   key: 'id',
  // },
];
export const data = [
  {
    code: null,
    bpCode: '/',
    name: 'Default',
    type: null,
    email: 'DefaulAccount@test.com',
    mobileNumber: '1089209011',
    pan: 'DFEJHJAHK',
    gstin: null,
    numberOfContacts: 2,
    isKeyAccount: false,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Customer',
    customerCategoryCode: 'Organization',
    address: {
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      addressLine3: 'Address Line 3',
      pincode: null,
      stateCode: 'Bihar',
      districtCode: 'Patna',
      cityCode: '',
    },
    creationOn: '12/09/2020',
    createdBy: 'Alok Kumar',
    application: [],
  },
  {
    mCode: 'ABCL',
    bpCode: '/',
    name: 'Ab corportate',
    type: 'corp',
    email: 'char@abclewasa.ltd',
    mobileNumber: '9998887651',
    pan: 'KIRLAOP1',
    gstin: '12321312312',
    numberOfContacts: 1,
    isKeyAccount: true,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '21/09/2020',
    createdBy: 'Sanjay Kumar Saha',
    application: [],
  },
  {
    mCode: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 4,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/09/2020',
    createdBy: 'Bindu Kumar Saha',
    application: [],
  },
  {
    mCode: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 8,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Broker',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    application: [],
  },
  {
    code: 'NajaYo',
    bpCode: '/',
    name: 'NOP',
    type: 'string',
    email: 'NYi@haj.op',
    mobileNumber: '1245121',
    pan: 'ASDFSD',
    gstin: '12411',
    numberOfContacts: 22,
    isKeyAccount: true,
    userId: 3,
    userEmailAddress: 'tuser1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/10/2020',
    createdBy: 'Amit Pal',
    application: [],
  },
  {
    code: 'TESTC',
    bpCode: '/',
    name: 'TestCC User',
    type: 'string',
    email: 'testAccount3@test.com',
    mobileNumber: '6284098765',
    pan: 'BMW6789',
    gstin: 'string',
    numberOfContacts: 2,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'DSC',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line 45',
      addressLine3: 'Address line 67',
      pincode: '127856',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '10/09/2020',
    createdBy: 'Bindu Chandru Saha',
    application: [],
  },
  {
    code: null,
    bpCode: '/',
    name: 'Default',
    type: null,
    email: 'DefaulAccount@test.com',
    mobileNumber: '1089209011',
    pan: 'DFEJHJAHK',
    gstin: null,
    numberOfContacts: 2,
    isKeyAccount: false,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Customer',
    customerCategoryCode: 'Organization',
    address: {
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      addressLine3: 'Address Line 3',
      pincode: null,
      stateCode: 'Bihar',
      districtCode: 'Patna',
      cityCode: '',
    },
    creationOn: '20/09/2020',
    createdBy: 'Alok Kumar',
    application: [],
  },
  {
    code: 'ABCL',
    bpCode: '/',
    name: 'Ab corportate',
    type: 'corp',
    email: 'char@abclewasa.ltd',
    mobileNumber: '9998887651',
    pan: 'KIRLAOP1',
    gstin: '12321312312',
    numberOfContacts: 1,
    isKeyAccount: true,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '21/09/2020',
    createdBy: 'Sanjay Kumar Saha',
    application: [],
  },
  {
    code: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 4,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/09/2020',
    createdBy: 'Bindu Kumar Saha',
    application: [],
  },
  {
    code: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 8,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Broker',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    application: [],
  },
  {
    code: 'NajaYo',
    bpCode: '/',
    name: 'NOP',
    type: 'string',
    email: 'NYi@haj.op',
    mobileNumber: '1245121',
    pan: 'ASDFSD',
    gstin: '12411',
    numberOfContacts: 22,
    isKeyAccount: true,
    userId: 3,
    userEmailAddress: 'tuser1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/10/2020',
    createdBy: 'Amit Pal',
    application: [],
  },
  {
    code: 'TESTC',
    bpCode: '/',
    name: 'TestCC User',
    type: 'string',
    email: 'testAccount3@test.com',
    mobileNumber: '6284098765',
    pan: 'BMW6789',
    gstin: 'string',
    numberOfContacts: 2,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'DSC',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line 45',
      addressLine3: 'Address line 67',
      pincode: '127856',
      stateCode: 'Bihar',
      districtCode: 'Buxar',
      cityCode: 'Guwahati',
    },
    creationOn: '10/09/2020',
    createdBy: 'Bindu Chandru Saha',
    application: [],
  },
  {
    code: null,
    bpCode: '/',
    name: 'Default',
    type: null,
    email: 'DefaulAccount@test.com',
    mobileNumber: '1089209011',
    pan: 'DFEJHJAHK',
    gstin: null,
    numberOfContacts: 2,
    isKeyAccount: false,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Customer',
    customerCategoryCode: 'Organization',
    address: {
      addressLine1: 'Address Line 1',
      addressLine2: 'Address Line 2',
      addressLine3: 'Address Line 3',
      pincode: null,
      stateCode: 'Bihar',
      districtCode: 'Patna',
      cityCode: '',
    },
    creationOn: '12/09/2020',
    createdBy: 'Alok Kumar',
    application: [],
  },
  {
    code: 'ABCL',
    bpCode: '/',
    name: 'Ab corportate',
    type: 'corp',
    email: 'char@abclewasa.ltd',
    mobileNumber: '9998887651',
    pan: 'KIRLAOP1',
    gstin: '12321312312',
    numberOfContacts: 1,
    isKeyAccount: true,
    userId: 1,
    userEmailAddress: 'john.doe@example.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '21/09/2020',
    createdBy: 'Sanjay Kumar Saha',
    application: [],
  },
  {
    code: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 4,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/09/2020',
    createdBy: 'Bindu Kumar Saha',
    application: [],
  },
  {
    code: 'TEST',
    bpCode: '/',
    name: 'Test User',
    type: 'string',
    email: 'testAccount1@test.com',
    mobileNumber: '6294098765',
    pan: 'BMPQWER6789',
    gstin: 'string',
    numberOfContacts: 8,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'Broker',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line45',
      addressLine3: 'Address line67',
      pincode: '123456',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    application: [],
  },
  {
    code: 'NajaYo',
    bpCode: '/',
    name: 'NOP',
    type: 'string',
    email: 'NYi@haj.op',
    mobileNumber: '1245121',
    pan: 'ASDFSD',
    gstin: '12411',
    numberOfContacts: 22,
    isKeyAccount: true,
    userId: 3,
    userEmailAddress: 'tuser1@test.com',
    accountTypeCode: 'Transporter',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'A 8d9s9',
      addressLine2: 'A s2',
      addressLine3: 'A ss3',
      pincode: '780930',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '23/10/2020',
    createdBy: 'Amit Pal',
    application: [],
  },
  {
    code: 'TESTC',
    bpCode: '/',
    name: 'TestCC User',
    type: 'string',
    email: 'testAccount3@test.com',
    mobileNumber: '6284098765',
    pan: 'BMW6789',
    gstin: 'string',
    numberOfContacts: 2,
    isKeyAccount: true,
    userId: 2,
    userEmailAddress: 'newtest1@test.com',
    accountTypeCode: 'DSC',
    customerCategoryCode: 'Retail',
    address: {
      addressLine1: 'Address line 89',
      addressLine2: 'Address line 45',
      addressLine3: 'Address line 67',
      pincode: '127856',
      stateCode: 'Assam',
      districtCode: 'Guwahati',
      cityCode: 'Guwahati',
    },
    creationOn: '10/09/2020',
    createdBy: 'Bindu Chandru Saha',
    application: [],
  },
];
