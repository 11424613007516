export const BULKUPLOADSTATUSLIST_REQUEST = 'BULKUPLOADSTATUSLIST_REQUEST';
export const BULKUPLOADSTATUSLIST_SUCCESS = 'BULKUPLOADSTATUSLIST_SUCCESS';
export const BULKUPLOADSTATUSLIST_FAILURE = 'BULKUPLOADSTATUSLIST_FAILURE';

export const BULKUPLOADRESPONSE_REQUEST = 'BULKUPLOADRESPONSE_REQUEST';
export const BULKUPLOADRESPONSE_SUCCESS = 'BULKUPLOADRESPONSE_SUCCESS';
export const BULKUPLOADRESPONSE_FAILURE = 'BULKUPLOADRESPONSE_FAILURE';

export const BULKUPLOADREQUEST_REQUEST = 'BULKUPLOADREQUEST_REQUEST';
export const BULKUPLOADREQUEST_SUCCESS = 'BULKUPLOADREQUEST_SUCCESS';
export const BULKUPLOADREQUEST_FAILURE = 'BULKUPLOADREQUEST_FAILURE';

export const BULKUPLOADFILEFILTER_REQUEST = 'BULKUPLOADFILEFILTER_REQUEST';
export const BULKUPLOADFILEFILTER_SUCCESS = 'BULKUPLOADFILEFILTER_SUCCESS';
export const BULKUPLOADFILEFILTER_FAILURE = 'BULKUPLOADFILEFILTER_FAILURE';
