import {
  SALESTOOLSMASTER_REQUEST,
  SALESTOOLSMASTER_SUCCESS,
  SALESTOOLSMASTER_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const salesMaster = (state = null, action) => {
  switch (action.type) {
    case SALESTOOLSMASTER_REQUEST:
      return state;
    case SALESTOOLSMASTER_SUCCESS:
      return action.payload;
    case SALESTOOLSMASTER_FAILURE:
      return state;
    default:
      return state;
  }
};
export default combineReducers({
  salesMaster,
});
