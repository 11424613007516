import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  Users_FAILURE,
  Users_REQUEST,
  Users_SUCCESS,
  UsersDefined_REQUEST,
  UsersDefined_SUCCESS,
  UsersDefined_FAILURE,
  EnableUser_FAILURE,
  DisableUser_REQUEST,
  DisableUser_FAILURE,
  DisableUser_SUCCESS,
  EnableUser_REQUEST,
  EnableUser_SUCCESS,
  ORGCODE_REQUEST,
  ORGCODE_SUCCESS,
  ORGCODE_FAILURE,
  RESETPASSWORD_FAILURE,
  RESETPASSWORD_REQUEST,
  RESETPASSWORD_SUCCESS,
  RESETDEVICE_FAILURE,
  RESETDEVICE_SUCCESS,
  RESETDEVICE_REQUEST,
  CHECKPORTALACCESS_REQUEST,
  CHECKPORTALACCESS_SUCCESS,
  CHECKPORTALACCESS_FAILURE,
  UPDATEACCESS_REQUEST,
  UPDATEACCESS_SUCCESS,
  UPDATEACCESS_FAILURE,
  ADDMANAGER_REQUEST,
  ADDMANAGER_SUCCESS,
  ADDMANAGER_FAILURE,
  USERSEARCH_REQUEST,
  USERSEARCH_SUCCESS,
  USERSEARCH_FAILURE,
  UsersDefinedCount_SUCCESS,
  UsersDefinedCount_REQUEST,
  UsersDefinedCount_FAILURE,
} from './constants';

export const getUserDetails = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/User/Self`,
    method: 'GET',
    types: [Users_REQUEST, Users_SUCCESS, Users_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const resetData = (type) => {
  return createAction({
    types: [type],
  });
};
export const getUsersDefined = (
  accessToken,
  pageNumber,
  pageSize,
  firstName,
  lastName,
  EmployeeCode,
  Role,
  OrganizationCode,
) => {
  return createAction({
    endpoint: `${API_URL}/User/GetUsers?FirstName=${firstName ?? ''}&LastName=${
      lastName ?? ''
    }&EmployeeCode=${EmployeeCode ?? ''}&Role=${Role ?? ''}&OrganizationCode=${
      OrganizationCode ?? ''
    }&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    types: [UsersDefined_REQUEST, UsersDefined_SUCCESS, UsersDefined_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getUsersCount = (
  accessToken,
  pageNumber,
  pageSize,
  firstName,
  lastName,
  EmployeeCode,
  Role,
  OrganizationCode,
) => {
  return createAction({
    endpoint: `${API_URL}/User/GetUsersCount/GetUsersCount?FirstName=${
      firstName ?? ''
    }&LastName=${lastName ?? ''}&EmployeeCode=${EmployeeCode ?? ''}&Role=${
      Role ?? ''
    }&OrganizationCode=${
      OrganizationCode ?? ''
    }&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    types: [
      UsersDefinedCount_REQUEST,
      UsersDefinedCount_SUCCESS,
      UsersDefinedCount_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const enableUser = (id, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/User/EnableUser`,
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      EnableUser_REQUEST,
      {
        type: EnableUser_SUCCESS,
        payload: {id},
      },
      EnableUser_FAILURE,
    ],
  });
};
export const disableUser = (id, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/User/DisableUser`,
    method: 'POST',
    body: JSON.stringify({id}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      DisableUser_REQUEST,
      {
        type: DisableUser_SUCCESS,
        payload: {id},
      },
      DisableUser_FAILURE,
    ],
  });
};
export const getOrgCode = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/User/GetOrgCode`,
    method: 'GET',
    types: [ORGCODE_REQUEST, ORGCODE_SUCCESS, ORGCODE_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const userSearch = (accessToken, search, pageNumber, pageSize) => {
  return createAction({
    endpoint: `${API_URL}/User/UserSearch?Search=${search}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    types: [USERSEARCH_REQUEST, USERSEARCH_SUCCESS, USERSEARCH_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const addZonalManager = (accessToken, obj, rowName) => {
  if (rowName === 'zoneList') {
    return createAction({
      endpoint: `${API_URL}/Hierarchy/AddZonalManager`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else if (rowName === 'branchList') {
    return createAction({
      endpoint: `${API_URL}/Hierarchy/AddBranchManager`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};

export const deleteZonalManager = (accessToken, obj, rowName) => {
  if (rowName === 'zoneList') {
    return createAction({
      endpoint: `${API_URL}/Hierarchy/DeleteZonalManagerMapping`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  } else if (rowName === 'branchList') {
    return createAction({
      endpoint: `${API_URL}/Hierarchy/DeleteBranchManagerMapping`,
      method: 'POST',
      body: JSON.stringify(obj),
      types: [ADDMANAGER_REQUEST, ADDMANAGER_SUCCESS, ADDMANAGER_FAILURE],
      headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
        Authorization: `Bearer ${accessToken}`,
      },
    });
  }
};

export const resetUserPassword = (accessToken, userId) => {
  return createAction({
    endpoint: `${API_URL}/User/AdminChangeUserPassword?userId=${userId}`,
    method: 'GET',
    types: [
      RESETPASSWORD_REQUEST,
      RESETPASSWORD_SUCCESS,
      RESETPASSWORD_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const resetDevice = (userId, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Device/ResetDevices`,
    method: 'POST',
    body: JSON.stringify({userId}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      RESETDEVICE_REQUEST,
      {
        type: RESETDEVICE_SUCCESS,
        payload: {userId},
      },
      RESETDEVICE_FAILURE,
    ],
  });
};

export const updateAccess = (
  userId,
  accessToken,
  canAccessApp,
  canAccessPortal,
) => {
  return createAction({
    endpoint: `${API_URL}/User/UpdatePortalOrAppAccess`,
    method: 'POST',
    body: JSON.stringify({userId, canAccessApp, canAccessPortal}),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      UPDATEACCESS_REQUEST,
      {
        type: UPDATEACCESS_SUCCESS,
        payload: {userId, canAccessApp, canAccessPortal},
      },
      UPDATEACCESS_FAILURE,
    ],
  });
};

export const checkPortalAccess = (username) => {
  console.log(`${API_URL}`);
  return createAction({
    endpoint: `${API_URL}/User/CheckPortalAccess?Username=${username}`,
    method: 'GET',
    types: [
      CHECKPORTALACCESS_REQUEST,
      CHECKPORTALACCESS_SUCCESS,
      CHECKPORTALACCESS_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};
