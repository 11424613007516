import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  ACCOUNTMASTER_REQUEST,
  ACCOUNTMASTER_SUCCESS,
  ACCOUNTMASTER_FAILURE,
} from './constants';

export const getAccountMaster = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/AccountsMaster`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      ACCOUNTMASTER_REQUEST,
      ACCOUNTMASTER_SUCCESS,
      ACCOUNTMASTER_FAILURE,
    ],
  });
};
