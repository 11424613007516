import {createSelector} from 'reselect';

export const getAccountDetails = ({accounts}) => accounts;

export const getAccountsData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.data,
);

export const getAccountsDataCount = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.accountsDataCount,
);

export const getMyAccountsData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.myAccountData,
);

export const updateAccountDataSelector = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.updateAccountData,
);

export const getPanData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.panData,
);

export const getGstData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.gstData,
);

export const getMobileData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.mobileData,
);

export const getUserData = createSelector(
  getAccountDetails,
  (getAccountDetails) => getAccountDetails.userData,
);
