import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  ADDRESS_FAILURE,
  ADDRESS_REQUEST,
  ADDRESS_SUCCESS,
  DiSTRICT_FAILURE,
  DiSTRICT_REQUEST,
  DiSTRICT_SUCCESS,
  USERADDRESS_FAILURE,
  USERADDRESS_REQUEST,
  USERADDRESS_SUCCESS,
} from './constants';

export const getAddress = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/AddressMaster`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [ADDRESS_REQUEST, ADDRESS_SUCCESS, ADDRESS_FAILURE],
  });
};

export const getAccountAddress = (accessToken, userId) => {
  return createAction({
    endpoint: `${API_URL}/AddressMaster/AddressForPortal?userId=${userId}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [USERADDRESS_REQUEST, USERADDRESS_SUCCESS, USERADDRESS_FAILURE],
  });
};

export const getDistricts = (accessToken, district, pageNumber, pageSize) => {
  return createAction({
    endpoint: `${API_URL}/AddressMaster/Districts?District=${district}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [DiSTRICT_REQUEST, DiSTRICT_SUCCESS, DiSTRICT_FAILURE],
  });
};
