import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {BrowserRouter as Router, Switch} from 'react-router-dom';
import {ROUTES, RouteGuard} from '../routes';
import {useDispatch, useSelector} from 'react-redux';
import {Auth} from 'aws-amplify';
import {loginSuccess} from '../../modules/auth/action';
import {useIdleTimer} from 'react-idle-timer';
import {getAuthIsAuthenticated} from '../../modules/auth/selectors';
import {Modal, Spin} from 'antd';
import 'antd/dist/antd.css';
import {signOut} from '../../modules/auth/action';
import {getOrgCode, getUserDetails} from '../../modules/userDetails/action';
import {getAuthUserAccessToken} from '../../modules/auth/user/selector';
import {getSelfDetails} from '../../modules/userDetails/selector';

import {clearStore} from '../../modules/auth/forgotPassword/action';

const App = (props) => {
  const dispatch = useDispatch();
  const [shouldTryAutoLogin, setShouldTryAutoLogin] = useState(true);
  const [shouldShowSplashScreen, setShouldShowSplashScreen] = useState(true);

  const isUserAuthenticated = useSelector(getAuthIsAuthenticated);
  const accessToken = useSelector(getAuthUserAccessToken);
  const selfUserinfo = useSelector(getSelfDetails);

  function countDown() {
    let secondsToGo = 5;
    const modal = Modal.success({
      title: 'Information',
      content: `You have stayed idle for more than 20 minutes and plese login again in ${secondsToGo} second.`,
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
      modal.update({
        content: `You have stayed idle for more than 20 minutes and plese login again in ${secondsToGo} second.`,
      });
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  const handleOnIdle = () => {
    if (isUserAuthenticated) {
      countDown();
      dispatch(signOut());
      dispatch(clearStore());
    }
  };

  useEffect(() => {
    if (selfUserinfo) {
      setShouldShowSplashScreen(false);
    }
  }, [selfUserinfo]);

  useEffect(() => {
    if (accessToken) {
      dispatch(getUserDetails(accessToken));
      dispatch(getOrgCode(accessToken));
    }
  }, [accessToken, dispatch]);

  useIdleTimer({
    timeout: 1000 * 60 * 20,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  useEffect(() => {
    const tryAutoLogin = async () => {
      setShouldTryAutoLogin(false);
      try {
        const user = await Auth.currentAuthenticatedUser();
        if (user && !user.challengeName) {
          dispatch(loginSuccess(user));
          // setShouldShowSplashScreen(false);
        } else {
          const err = {type: 'AutoLoginFailed', message: 'Login Failed.'};
          throw err;
        }
      } catch (error) {
        setShouldShowSplashScreen(false);
      }
    };

    if (shouldTryAutoLogin) {
      tryAutoLogin();
    }
  }, [dispatch, shouldTryAutoLogin]);

  return (
    <>
      {shouldShowSplashScreen ? (
        <SpinnerOuter>
          <Spin />
        </SpinnerOuter>
      ) : (
        <Router>
          <Switch>
            {ROUTES.map((route) => (
              <RouteGuard
                exact={route.exact}
                guard={route.guard}
                isAuthenticated={props.isAuthenticated}
                key={route.id}
                path={route.path}>
                <route.component />
              </RouteGuard>
            ))}
          </Switch>
        </Router>
      )}
    </>
  );
};

const SpinnerOuter = styled.div`
  height: 100vh;
  display: flex;
  padding-top: 10em;
  justify-content: center;
`;

export default App;
