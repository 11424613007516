import {NO_INTERNET_ERROR, UNKNOWN_ERROR} from '../constants/errors';
import {ApiError} from './errors';

const NETWORK_ERROR_MESSAGES = ['Network request failed', 'Failed to fetch'];

export default function getErrorForReducer(error) {
  const DEFAULT_ERRORS = [
    {message: 'Sorry something went wrong. Please try again.'},
  ];

  if (error.payload instanceof ApiError) {
    const errors = error.payload.response
      ? error.payload.response
      : DEFAULT_ERRORS;
    return {
      name: error.name ? error.name : UNKNOWN_ERROR,
      errors,
      status: error.payload.status,
      statusText: error.payload.statusText,
    };
  } else if (error.message) {
    let message = error.message;
    let errorName = error.name ? error.name : UNKNOWN_ERROR;
    if (NETWORK_ERROR_MESSAGES.indexOf(error.message) !== -1) {
      message = 'Please check if you have a working Internet connection.';
      errorName = NO_INTERNET_ERROR;
    }
    return {
      name: errorName,
      errors: [{message}],
    };
  }

  return {
    name: error.name ? error.name : UNKNOWN_ERROR,
    errors: DEFAULT_ERRORS,
  };
}
