export class ApiError extends Error {
  constructor(status, statusText = '', response = null) {
    super();
    this.name = 'ApiError';
    this.status = status;
    this.statusText = statusText;
    this.response = response;
  }
}

export class DebugError extends Error {
  constructor(message = '', info = null) {
    super();
    this.name = 'DebugError';
    this.message = message;
    this.info = info;
  }
}
