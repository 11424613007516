import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {useSelector, useDispatch} from 'react-redux';
import UploadDownloadbox from '../common/UploadDownloadBox';
import DataFilterBox from '../common/DataFilterBox';
import {Button, Table, message} from 'antd';
import {bulkUploadColumns} from '../../data/bulkUploadColumns';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {
  getBulkUploadStatusList,
  getBulkUploadResponse,
  getBulkUploadFilterFileResponse,
  postBulkUploadRequest,
} from '../../modules/bulkUpload/action';
import {
  getStatusList,
  getBulkUploadResponses,
  getBulkUploadFilterFileResponses,
  BulkUploadRequestSelector,
} from '../../modules/bulkUpload/selector';
import {downloadCsv} from '../../utils/downloadCsv';
import Papa from 'papaparse';
import {getDropdownValues} from '../../modules/dropdowns/action';
import {getDropdownsData} from '../../modules/dropdowns/selector';

export const BulkUpload = () => {
  const [isDownloadEnabled, setIsDownloadEnable] = useState(false);
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const statusList = useSelector(getStatusList);
  const [data, setData] = useState();
  const [file, setFile] = useState(null);
  const [value, setValue] = useState(null);

  const accountsHeader = [
    'AccountName',
    'Email',
    'MobileNumber',
    'PinCode',
    'AccountType',
    'Source',
    'EmployeeResponsible',
  ];
  const contactsHeader = [
    'FirstName',
    'LastName',
    'Email',
    'ContactNumber',
    'PinCode',
    'AccountMCode',
    'Source',
    'District',
  ];
  const opportunityHeader = [
    'OpportunityTypeName',
    'AccountMCode',
    'ContactMCode',
    'OpportunityModelName',
    'VariantName',
    'OriginSource',
  ];
  const uploadStatus = useSelector(BulkUploadRequestSelector);
  const fileFilter = useSelector(getBulkUploadFilterFileResponses);

  const [filteredFileName, setFilteredFileName] = useState('');

  const onUserNameChange = ({target}) => {
    setFilteredFileName(target.value.trim());
  };

  const onFilterDataClick = async () => {
    getFilteredData();
  };

  const onResetClick = async () => {
    setFilteredFileName('');
    setData(statusList);
  };

  const getFilteredData = () => {
    if (filteredFileName !== '') {
      dispatch(
        getBulkUploadFilterFileResponse(authUser.accessToken, filteredFileName),
      );
    } else {
      setData(statusList);
    }
  };

  useEffect(() => {
    dispatch(getBulkUploadStatusList(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    setData(statusList);
  }, [statusList]);

  useEffect(() => {
    setData(fileFilter);
  }, [fileFilter]);

  const handleFileUpload = (event) => {
    if (event.target.files !== undefined) {
      setFile(event.target.files[0]);
      setValue(event.target.value);
    }
  };

  const handleOnClickUpload = async (event) => {
    let uploadRequest = {};
    let uploadRequestUrl = null;
    if (file) {
      if (file.type === 'text/csv') {
        Papa.parse(file, {
          delimiter: '',
          chunkSize: 3,
          header: true,
          complete: function (responses) {
            console.log(responses);
            var conType = headerValidation(responses.meta.fields);
            if (responses.data.length > 1) {
              if (conType === 'A') {
                uploadRequest = {
                  jobId: generateJobId(conType),
                  contentType: 'Account',
                  status: 'Pending',
                  fileName: file.name,
                  accountBulkUpload: responses.data.slice(
                    0,
                    responses.data.length - 1,
                  ),
                };
                uploadRequestUrl = 'BulkUpload/UploadAndValidateAccount';
                createUploadRequest(uploadRequestUrl, uploadRequest);
                setValue(null);
              } else if (conType === 'C') {
                uploadRequest = {
                  jobId: generateJobId(conType),
                  contentType: 'Contact',
                  status: 'Pending',
                  fileName: file.name,
                  contactBulkUpload: responses.data.slice(
                    0,
                    responses.data.length - 1,
                  ),
                };
                uploadRequestUrl = 'BulkUpload/UploadAndValidateContact';
                createUploadRequest(uploadRequestUrl, uploadRequest);
                setValue(null);
              } else if (conType === 'O') {
                uploadRequest = {
                  jobId: generateJobId(conType),
                  contentType: 'Opportunity',
                  status: 'Pending',
                  fileName: file.name,
                  opportunityBulkUpload: responses.data.slice(
                    0,
                    responses.data.length - 1,
                  ),
                };
                uploadRequestUrl = 'BulkUpload/UploadAndValidateOpportunity';
                createUploadRequest(uploadRequestUrl, uploadRequest);
                setValue(null);
              } else {
                message.error(
                  {
                    content:
                      'Attached file sheet header mismatching or wrong header sequence.',
                    className: 'custom-class',
                    style: {
                      fontFamily: 'arial',
                      fontWeight: 400,
                      color: '#797878',
                    },
                  },
                  5,
                );
              }
            } else {
              message.error(
                {
                  content: 'No Data found in the attached file',
                  className: 'custom-class',
                  style: {
                    fontFamily: 'arial',
                    fontWeight: 400,
                    color: '#797878',
                  },
                },
                5,
              );
            }
          },
        });
      } else {
        message.error(
          {
            content: 'Attached file is not a csv file. Only csv file allowed.',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    } else {
      message.error(
        {
          content: 'no file selected',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
    }
  };

  const getDetails = async (e, record) => {
    let url;
    if (record.uploadtype === 'Account') {
      url = 'BulkUpload/DownloadAccountResponseFile';
    } else if (record.uploadtype === 'Contact') {
      url = 'BulkUpload/DownloadContactResponseFile';
    } else if (record.uploadtype === 'Opportunity') {
      url = 'BulkUpload/DownloadOppResponseFile';
    }
    const rdata = await dispatch(
      getBulkUploadResponse(authUser.accessToken, url, record.jobId),
    );
    if (rdata) {
      downloadCsv(rdata.payload, `${record.uploadtype}?_Response`);
    }
  };

  const updatedbulkUpload = [
    ...bulkUploadColumns,
    {
      title: 'Download',
      dataIndex: 'action',
      key: 'id',
      width: '12%',
      render: (text, record) => (
        <Button
          disabled={record.status !== 'Completed' ? true : false}
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Download
        </Button>
      ),
    },
  ];

  const headerValidation = (headerData) => {
    var isAccountHeader =
      accountsHeader.length === headerData.length &&
      accountsHeader.every(
        (o, i) =>
          Object.keys(o).length === Object.keys(headerData[i]).length &&
          Object.keys(o).every((k) => o[k] === headerData[i][k]),
      );

    if (!isAccountHeader) {
      var isConatctHeader =
        contactsHeader.length === headerData.length &&
        contactsHeader.every(
          (o, i) =>
            Object.keys(o).length === Object.keys(headerData[i]).length &&
            Object.keys(o).every((k) => o[k] === headerData[i][k]),
        );
    }

    if (!isAccountHeader && !isConatctHeader) {
      var isOpportunityHeader =
        opportunityHeader.length === headerData.length &&
        opportunityHeader.every(
          (o, i) =>
            Object.keys(o).length === Object.keys(headerData[i]).length &&
            Object.keys(o).every((k) => o[k] === headerData[i][k]),
        );
    }
    return isAccountHeader === true
      ? 'A'
      : isConatctHeader === true
      ? 'C'
      : isOpportunityHeader === true
      ? 'O'
      : 'E';
  };

  const createUploadRequest = (url, payload) => {
    handleFileUpload({target: {undefined}});
    if (url != null) {
      dispatch(postBulkUploadRequest(authUser.accessToken, url, payload));
    }
  };

  const generateJobId = (conType) => {
    var JobId = conType + '_' + Math.random().toString(36).substr(2, 9);
    return JobId.toUpperCase();
  };

  const onClickAccount = () => {
    let data = [
      {
        AccountName: '',
        Email: '',
        MobileNumber: '',
        PinCode: '',
        AccountType: '',
        Source: '',
        EmployeeResponsible: '',
      },
    ];

    downloadCsv(data, 'AccountTemplete');
  };
  const onClickContact = () => {
    let data = [
      {
        FirstName: '',
        LastName: '',
        Email: '',
        ContactNumber: '',
        PinCode: '',
        AccountMCode: '',
        Source: '',
        District: '',
      },
    ];

    downloadCsv(data, 'ContactTemplete');
  };
  const onClickOpportunity = () => {
    let data = [
      {
        OpportunityTypeName: '',
        AccountMCode: '',
        ContactMCode: '',
        OpportunityModelName: '',
        VariantName: '',
        OriginSource: '',
      },
    ];

    downloadCsv(data, 'OpportunityTemplete');
  };

  const onDownloadVariantClick = () => {
    setIsDownloadEnable(true);
    dispatch(getDropdownValues());
  };

  const dropdownData = useSelector(getDropdownsData);

  useEffect(() => {
    if (isDownloadEnabled) {
      // console.log('dropdownData ==== ', dropdownData, isDownloadEnabled);
      var variantsData = dropdownData?.variants;
      var newVariants = [];
      variantsData.forEach((element) => {
        if (element?.code)
          newVariants.push({
            'Opportunity Model': element?.model,
            'Variant Name': element?.name,
            'Variant Code': element?.code,
          });
      });
      if (newVariants.length > 0) {
        // console.log('newVariants ==== ', newVariants);
        downloadCsv(newVariants, 'variants');
      }
      setIsDownloadEnable(false);
    }
  }, [dispatch, dropdownData]);

  useEffect(() => {
    if (uploadStatus?.type === 'BULKUPLOADREQUEST_SUCCESS') {
      message.success(
        {
          content:
            uploadStatus.payload !== undefined
              ? uploadStatus.payload
              : 'Request submitted successfully!',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
      // serviceReportItems[0].value == null;
      dispatch(getBulkUploadStatusList(authUser.accessToken));
    } else if (uploadStatus?.type === 'BULKUPLOADREQUEST_FAILURE') {
      message.error(
        {
          content:
            uploadStatus.payload.response !== undefined
              ? uploadStatus.payload.response
              : 'Request submittion failed !',
          className: 'custom-class',
          style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
        },
        5,
      );
    }
  }, [uploadStatus]);

  const serviceReportItems = [
    {
      fieldId: 1,
      fieldName: 'Choose File',
      fieldType: 'Input',
      type: 'file',
      value: value,
      onChange: handleFileUpload,
    },
    {
      fieldId: 2,
      fieldName: 'Upload file (*.csv)',
      fieldTitle: '',
      fieldType: 'Button',
      fieldMargin: 3.5,
      onClick: handleOnClickUpload,
    },
    {
      fieldId: 3,
      fieldName: 'Account Template',
      fieldType: 'Button',
      fieldMargin: 3.5,
      onClick: onClickAccount,
    },
    {
      fieldId: 4,
      fieldName: 'Contact Template',
      fieldType: 'Button',
      fieldMargin: 3.5,
      onClick: onClickContact,
    },
    {
      fieldId: 5,
      fieldName: 'Opportunity Template',
      fieldType: 'Button',
      fieldMargin: 3.5,
      onClick: onClickOpportunity,
    },

    {
      fieldId: 9,
      fieldName: 'Filter By File Name',
      fieldTitle: '',
      fieldMargin: '20px',
      fieldType: 'Label',
    },
    {
      fieldId: 8,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter File Name',
      type: 'text',
      onChange: onUserNameChange,
      value: filteredFileName,
    },
    {
      fieldId: 5,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Col',
    },
    {
      fieldId: 19,
      fieldName: 'Fiter Data',
      fieldTitle: '',
      fieldType: 'ButtonFilter',
      onClick: onFilterDataClick,
    },

    {
      fieldId: 19,
      fieldName: 'Reset',
      fieldTitle: '',
      fieldType: 'ButtonFilter',
      onClick: onResetClick,
    },
    {
      fieldId: 19,
      fieldName: 'Download Variants',
      fieldTitle: '',
      fieldType: 'ButtonFilter',
      onClick: onDownloadVariantClick,
    },
  ];

  return (
    <MainLayout>
      <UploadDownloadbox
        items={serviceReportItems}
        title="Bulk Upload"></UploadDownloadbox>

      <div className="contentLayout" style={{paddingTop: 15}}>
        <span className="contentHeader">Download Results</span>
        <Table
          columns={updatedbulkUpload}
          dataSource={data}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
