import React, {useEffect, useState} from 'react';
import {Layout, Menu} from 'antd';
import {
  BarChartOutlined,
  CloudOutlined,
  SettingOutlined,
  TeamOutlined,
  ContactsOutlined,
  NotificationOutlined,
  HomeOutlined,
  UserSwitchOutlined,
  UploadOutlined,
  ToolOutlined,
  VerticalAlignBottomOutlined,
} from '@ant-design/icons';
import {Link, useLocation} from 'react-router-dom';
import Header from './Header';
import {useSelector} from 'react-redux';
import {getSelfDetails} from '../modules/userDetails/selector';

const {Content, Footer, Sider} = Layout;

const menuItems = [
  {
    name: 'Home',
    exact: true,
    key: 1,
    icon: <HomeOutlined style={{fontWeight: '550'}} />,
    path: '/',
  },
  {
    name: 'User Management',
    exact: true,
    key: 2,
    icon: <UserSwitchOutlined style={{fontWeight: '550'}} />,
    path: '/userManagement',
  },
  {
    name: 'Accounts',
    key: 3,
    icon: <TeamOutlined style={{fontWeight: '550'}} />,
    path: '/accounts',
  },
  {
    name: 'Contacts',
    key: 4,
    icon: <ContactsOutlined style={{fontWeight: '550'}} />,
    path: '/contacts',
  },
  {
    name: 'Opportunity',
    key: 5,
    icon: <BarChartOutlined style={{fontWeight: '550'}} />,
    path: '/opportunities',
  },
  {
    name: 'Activity',
    key: 6,
    icon: <CloudOutlined style={{fontWeight: '550'}} />,
    path: '/activities',
  },
  // {
  //   name: 'Bulk Upload',
  //   key: 7,
  //   icon: <UploadOutlined style={{fontWeight: '550'}} />,
  //   path: '/notifications',
  // },
  {
    name: 'Bulk Upload',
    key: 7,
    icon: <UploadOutlined style={{fontWeight: '550'}} />,
    path: '/bulkUpload',
  },
  {
    name: 'Sales Tools',
    key: 8,
    icon: <ToolOutlined style={{fontWeight: '550'}} />,
    path: '/salesTools',
  },
  {
    name: 'Reports',
    key: 9,
    icon: <VerticalAlignBottomOutlined style={{fontWeight: '550'}} />,
    path: '/reports',
  },
  // {
  //   name: 'Settings',
  //   key: 8,
  //   icon: <SettingOutlined style={{fontWeight: '550'}} />,
  //   path: '/settings',
  // },
];

const MainLayout = (props) => {
  const location = useLocation();
  const selfUserinfo = useSelector(getSelfDetails);
  const [isUserAdmin, setIsUserAdmin] = useState(false);
  const [collapsed, setCollapsed] = useState(false);
  useEffect(() => {
    setIsUserAdmin(
      selfUserinfo
        ? selfUserinfo.roles.length > 0
          ? selfUserinfo.roles.includes('Admin')
          : false
        : false,
    );
  }, [selfUserinfo]);
  return (
    <div>
      <Layout className="site-layout" style={{minHeight: '100vh'}}>
        <Header />
        <Layout
          className="site-layout"
          style={{marginLeft: collapsed ? 70 : 200, marginTop: '60px'}}>
          <Sider
            collapsible
            onCollapse={() => {
              setCollapsed(!collapsed);
            }}
            style={{
              height: '100vh',
              position: 'fixed',
              left: 0,
              backgroundColor: '#5d5d5d',
            }}>
            <Menu
              mode="inline"
              selectedKeys={[location.pathname]}
              style={{backgroundColor: '#5d5d5d', fontWeight: '550'}}
              theme="dark">
              {isUserAdmin
                ? menuItems.map((menu) => (
                    <Menu.Item icon={menu.icon} key={menu.path}>
                      <Link to={menu.path}>{menu.name}</Link>
                    </Menu.Item>
                  ))
                : menuItems
                    .filter((item) => item.key !== 2)
                    .map((menu) => (
                      <Menu.Item icon={menu.icon} key={menu.path}>
                        <Link to={menu.path}>{menu.name}</Link>
                      </Menu.Item>
                    ))}
            </Menu>
          </Sider>
          <Content>{props.children}</Content>
        </Layout>
        <Footer style={{textAlign: 'right'}}>
          <div>Tata Hitachi Construction Machinery Company Private Limited</div>
        </Footer>
      </Layout>
    </div>
  );
};

export default MainLayout;
