export default [
  {
    state: 'All',
    districts: [
      {
        key: 'All',
        value: 'All',
      },
    ],
  },
  {
    state: 'Andhra Pradesh',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Anantapur', value: 'Anantapur'},
      {key: 'Chittoor', value: 'Chittoor'},
      {key: 'East Godavari', value: 'East Godavari'},
      {key: 'Guntur', value: 'Guntur'},
      {key: 'Krishna', value: 'Krishna'},
      {key: 'Kurnool', value: 'Kurnool'},
      {key: 'Nellore', value: 'Nellore'},
      {key: 'Prakasam', value: 'Prakasam'},
      {key: 'Srikakulam', value: 'Srikakulam'},
      {key: 'Visakhapatnam', value: 'Visakhapatnam'},
      {key: 'Vizianagaram', value: 'Vizianagaram'},
      {key: 'West Godavari', value: 'West Godavari'},
      {key: 'YSR Kadapa', value: 'YSR Kadapa'},
    ],
  },
  {
    state: 'Arunachal Pradesh',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Tawang', value: 'Tawang'},
      {key: 'West Kameng', value: 'West Kameng'},
      {key: 'East Kameng', value: 'East Kameng'},
      {key: 'Papum Pare', value: 'Papum Pare'},
      {key: 'Kurung Kumey', value: 'Kurung Kumey'},
      {key: 'Kra Daadi', value: 'Kra Daadi'},
      {key: 'Lower Subansiri', value: 'Lower Subansiri'},
      {key: 'Upper Subansiri', value: 'Upper Subansiri'},
      {key: 'West Siang', value: 'West Siang'},
      {key: 'East Siang', value: 'East Siang'},
      {key: 'Siang', value: 'Siang'},
      {key: 'Upper Siang', value: 'Upper Siang'},
      {key: 'Lower Siang', value: 'Lower Siang'},
      {key: 'Lower Dibang Valley', value: 'Lower Dibang Valley'},
      {key: 'Dibang Valley', value: 'Dibang Valley'},
      {key: 'Anjaw', value: 'Anjaw'},
      {key: 'Lohit', value: 'Lohit'},
      {key: 'Namsai', value: 'Namsai'},
      {key: 'Changlang', value: 'Changlang'},
      {key: 'Tirap', value: 'Tirap'},
      {key: 'Longding', value: 'Longding'},
    ],
  },
  {
    state: 'Assam',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Baksa', value: 'Baksa'},
      {key: 'Barpeta', value: 'Barpeta'},
      {key: 'Biswanath', value: 'Biswanath'},
      {key: 'Bongaigaon', value: 'Bongaigaon'},
      {key: 'Cachar', value: 'Cachar'},
      {key: 'Charaideo', value: 'Charaideo'},
      {key: 'Chirang', value: 'Chirang'},
      {key: 'Darrang', value: 'Darrang'},
      {key: 'Dhemaji', value: 'Dhemaji'},
      {key: 'Dhubri', value: 'Dhubri'},
      {key: 'Dibrugarh', value: 'Dibrugarh'},
      {key: 'Goalpara', value: 'Goalpara'},
      {key: 'Golaghat', value: 'Golaghat'},
      {key: 'Hailakandi', value: 'Hailakandi'},
      {key: 'Hojai', value: 'Hojai'},
      {key: 'Jorhat', value: 'Jorhat'},
      {key: 'Kamrup Metropolitan', value: 'Kamrup Metropolitan'},
      {key: 'Kamrup', value: 'Kamrup'},
      {key: 'Karbi Anglong', value: 'Karbi Anglong'},
      {key: 'Karimganj', value: 'Karimganj'},
      {key: 'Kokrajhar', value: 'Kokrajhar'},
      {key: 'Lakhimpur', value: 'Lakhimpur'},
      {key: 'Majuli', value: 'Majuli'},
      {key: 'Morigaon', value: 'Morigaon'},
      {key: 'Nagaon', value: 'Nagaon'},
      {key: 'Nalbari', value: 'Nalbari'},
      {key: 'Dima Hasao', value: 'Dima Hasao'},
      {key: 'Sivasagar', value: 'Sivasagar'},
      {key: 'Sonitpur', value: 'Sonitpur'},
      {key: 'South Salmara-Mankachar', value: 'South Salmara-Mankachar'},
      {key: 'Tinsukia', value: 'Tinsukia'},
      {key: 'Udalguri', value: 'Udalguri'},
      {key: 'West Karbi Anglong', value: 'West Karbi Anglong'},
    ],
  },
  {
    state: 'Bihar',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Araria', value: 'Araria'},
      {key: 'Arwal', value: 'Arwal'},
      {key: 'Aurangabad', value: 'Aurangabad'},
      {key: 'Banka', value: 'Banka'},
      {key: 'Begusarai', value: 'Begusarai'},
      {key: 'Bhagalpur', value: 'Bhagalpur'},
      {key: 'Bhojpur', value: 'Bhojpur'},
      {key: 'Buxar', value: 'Buxar'},
      {key: 'Darbhanga', value: 'Darbhanga'},
      {key: 'East Champaran (Motihari)', value: 'East Champaran (Motihari)'},
      {key: 'Gaya', value: 'Gaya'},
      {key: 'Gopalganj', value: 'Gopalganj'},
      {key: 'Jamui', value: 'Jamui'},
      {key: 'Jehanabad', value: 'Jehanabad'},
      {key: 'Kaimur (Bhabua)', value: 'Kaimur (Bhabua)'},
      {key: 'Katihar', value: 'Katihar'},
      {key: 'Khagaria', value: 'Khagaria'},
      {key: 'Kishanganj', value: 'Kishanganj'},
      {key: 'Lakhisarai', value: 'Lakhisarai'},
      {key: 'Madhepura', value: 'Madhepura'},
      {key: 'Madhubani', value: 'Madhubani'},
      {key: 'Munger (Monghyr)', value: 'Munger (Monghyr)'},
      {key: 'Muzaffarpur', value: 'Muzaffarpur'},
      {key: 'Nalanda', value: 'Nalanda'},
      {key: 'Nawada', value: 'Nawada'},
      {key: 'Patna', value: 'Patna'},
      {key: 'Purnia (Purnea)', value: 'Purnia (Purnea)'},
      {key: 'Rohtas', value: 'Rohtas'},
      {key: 'Saharsa', value: 'Saharsa'},
      {key: 'Samastipur', value: 'Samastipur'},
      {key: 'Saran', value: 'Saran'},
      {key: 'Sheikhpura', value: 'Sheikhpura'},
      {key: 'Sheohar', value: 'Sheohar'},
      {key: 'Sitamarhi', value: 'Sitamarhi'},
      {key: 'Siwan', value: 'Siwan'},
      {key: 'Supaul', value: 'Supaul'},
      {key: 'Vaishali', value: 'Vaishali'},
      {key: 'West Champaran', value: 'West Champaran'},
    ],
  },
  {
    state: 'Chandigarh (UT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Chandigarh', value: 'Chandigarh'},
    ],
  },
  {
    state: 'Chhattisgarh',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Balod', value: 'Balod'},
      {key: 'Baloda Bazar', value: 'Baloda Bazar'},
      {key: 'Balrampur', value: 'Balrampur'},
      {key: 'Bastar', value: 'Bastar'},
      {key: 'Bemetara', value: 'Bemetara'},
      {key: 'Bijapur', value: 'Bijapur'},
      {key: 'Bilaspur', value: 'Bilaspur'},
      {key: 'Dantewada (South Bastar)', value: 'Dantewada (South Bastar)'},
      {key: 'Dhamtari', value: 'Dhamtari'},
      {key: 'Durg', value: 'Durg'},
      {key: 'Gariyaband', value: 'Gariyaband'},
      {key: 'Janjgir-Champa', value: 'Janjgir-Champa'},
      {key: 'Jashpur', value: 'Jashpur'},
      {key: 'Kabirdham (Kawardha)', value: 'Kabirdham (Kawardha)'},
      {key: 'Kanker (North Bastar)', value: 'Kanker (North Bastar)'},
      {key: 'Kondagaon', value: 'Kondagaon'},
      {key: 'Korba', value: 'Korba'},
      {key: 'Korea (Koriya)', value: 'Korea (Koriya)'},
      {key: 'Mahasamund', value: 'Mahasamund'},
      {key: 'Mungeli', value: 'Mungeli'},
      {key: 'Narayanpur', value: 'Narayanpur'},
      {key: 'Raigarh', value: 'Raigarh'},
      {key: 'Raipur', value: 'Raipur'},
      {key: 'Rajnandgaon', value: 'Rajnandgaon'},
      {key: 'Sukma', value: 'Sukma'},
      {key: 'Surajpur  ', value: 'Surajpur  '},
      {key: 'Surguja', value: 'Surguja'},
    ],
  },
  {
    state: 'Dadra and Nagar Haveli (UT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Dadra & Nagar Haveli', value: 'Dadra & Nagar Haveli'},
    ],
  },
  {
    state: 'Daman and Diu (UT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Daman', value: 'Daman'},
      {key: 'Diu', value: 'Diu'},
    ],
  },
  {
    state: 'Delhi (NCT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Central Delhi', value: 'Central Delhi'},
      {key: 'East Delhi', value: 'East Delhi'},
      {key: 'New Delhi', value: 'New Delhi'},
      {key: 'North Delhi', value: 'North Delhi'},
      {key: 'North East  Delhi', value: 'North East  Delhi'},
      {key: 'North West  Delhi', value: 'North West  Delhi'},
      {key: 'Shahdara', value: 'Shahdara'},
      {key: 'South Delhi', value: 'South Delhi'},
      {key: 'South East Delhi', value: 'South East Delhi'},
      {key: 'South West  Delhi', value: 'South West  Delhi'},
      {key: 'West Delhi', value: 'West Delhi'},
    ],
  },
  {
    state: 'Goa',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'North Goa', value: 'North Goa'},
      {key: 'South Goa', value: 'South Goa'},
    ],
  },
  {
    state: 'Gujarat',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Ahmedabad', value: 'Ahmedabad'},
      {key: 'Amreli', value: 'Amreli'},
      {key: 'Anand', value: 'Anand'},
      {key: 'Aravalli', value: 'Aravalli'},
      {key: 'Banaskantha (Palanpur)', value: 'Banaskantha (Palanpur)'},
      {key: 'Bharuch', value: 'Bharuch'},
      {key: 'Bhavnagar', value: 'Bhavnagar'},
      {key: 'Botad', value: 'Botad'},
      {key: 'Chhota Udepur', value: 'Chhota Udepur'},
      {key: 'Dahod', value: 'Dahod'},
      {key: 'Dangs (Ahwa)', value: 'Dangs (Ahwa)'},
      {key: 'Devbhoomi Dwarka', value: 'Devbhoomi Dwarka'},
      {key: 'Gandhinagar', value: 'Gandhinagar'},
      {key: 'Gir Somnath', value: 'Gir Somnath'},
      {key: 'Jamnagar', value: 'Jamnagar'},
      {key: 'Junagadh', value: 'Junagadh'},
      {key: 'Kachchh', value: 'Kachchh'},
      {key: 'Kheda (Nadiad)', value: 'Kheda (Nadiad)'},
      {key: 'Mahisagar', value: 'Mahisagar'},
      {key: 'Mehsana', value: 'Mehsana'},
      {key: 'Morbi', value: 'Morbi'},
      {key: 'Narmada (Rajpipla)', value: 'Narmada (Rajpipla)'},
      {key: 'Navsari', value: 'Navsari'},
      {key: 'Panchmahal (Godhra)', value: 'Panchmahal (Godhra)'},
      {key: 'Patan', value: 'Patan'},
      {key: 'Porbandar', value: 'Porbandar'},
      {key: 'Rajkot', value: 'Rajkot'},
      {key: 'Sabarkantha (Himmatnagar)', value: 'Sabarkantha (Himmatnagar)'},
      {key: 'Surat', value: 'Surat'},
      {key: 'Surendranagar', value: 'Surendranagar'},
      {key: 'Tapi (Vyara)', value: 'Tapi (Vyara)'},
      {key: 'Vadodara', value: 'Vadodara'},
      {key: 'Valsad', value: 'Valsad'},
    ],
  },
  {
    state: 'Haryana',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Ambala', value: 'Ambala'},
      {key: 'Bhiwani', value: 'Bhiwani'},
      {key: 'Charkhi Dadri', value: 'Charkhi Dadri'},
      {key: 'Faridabad', value: 'Faridabad'},
      {key: 'Fatehabad', value: 'Fatehabad'},
      {key: 'Gurgaon', value: 'Gurgaon'},
      {key: 'Hisar', value: 'Hisar'},
      {key: 'Jhajjar', value: 'Jhajjar'},
      {key: 'Jind', value: 'Jind'},
      {key: 'Kaithal', value: 'Kaithal'},
      {key: 'Karnal', value: 'Karnal'},
      {key: 'Kurukshetra', value: 'Kurukshetra'},
      {key: 'Mahendragarh', value: 'Mahendragarh'},
      {key: 'Mewat', value: 'Mewat'},
      {key: 'Palwal', value: 'Palwal'},
      {key: 'Panchkula', value: 'Panchkula'},
      {key: 'Panipat', value: 'Panipat'},
      {key: 'Rewari', value: 'Rewari'},
      {key: 'Rohtak', value: 'Rohtak'},
      {key: 'Sirsa', value: 'Sirsa'},
      {key: 'Sonipat', value: 'Sonipat'},
      {key: 'Yamunanagar', value: 'Yamunanagar'},
    ],
  },
  {
    state: 'Himachal Pradesh',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Bilaspur', value: 'Bilaspur'},
      {key: 'Chamba', value: 'Chamba'},
      {key: 'Hamirpur', value: 'Hamirpur'},
      {key: 'Kangra', value: 'Kangra'},
      {key: 'Kinnaur', value: 'Kinnaur'},
      {key: 'Kullu', value: 'Kullu'},
      {key: 'Lahaul &amp; Spiti', value: 'Lahaul &amp; Spiti'},
      {key: 'Mandi', value: 'Mandi'},
      {key: 'Shimla', value: 'Shimla'},
      {key: 'Sirmaur (Sirmour)', value: 'Sirmaur (Sirmour)'},
      {key: 'Solan', value: 'Solan'},
      {key: 'Una', value: 'Una'},
    ],
  },
  {
    state: 'Jammu and Kashmir',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Anantnag', value: 'Anantnag'},
      {key: 'Bandipore', value: 'Bandipore'},
      {key: 'Baramulla', value: 'Baramulla'},
      {key: 'Budgam', value: 'Budgam'},
      {key: 'Doda', value: 'Doda'},
      {key: 'Ganderbal', value: 'Ganderbal'},
      {key: 'Jammu', value: 'Jammu'},
      {key: 'Kargil', value: 'Kargil'},
      {key: 'Kathua', value: 'Kathua'},
      {key: 'Kishtwar', value: 'Kishtwar'},
      {key: 'Kulgam', value: 'Kulgam'},
      {key: 'Kupwara', value: 'Kupwara'},
      {key: 'Leh', value: 'Leh'},
      {key: 'Poonch', value: 'Poonch'},
      {key: 'Pulwama', value: 'Pulwama'},
      {key: 'Rajouri', value: 'Rajouri'},
      {key: 'Ramban', value: 'Ramban'},
      {key: 'Reasi', value: 'Reasi'},
      {key: 'Samba', value: 'Samba'},
      {key: 'Shopian', value: 'Shopian'},
      {key: 'Srinagar', value: 'Srinagar'},
      {key: 'Udhampur', value: 'Udhampur'},
    ],
  },
  {
    state: 'Jharkhand',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Bokaro', value: 'Bokaro'},
      {key: 'Chatra', value: 'Chatra'},
      {key: 'Deoghar', value: 'Deoghar'},
      {key: 'Dhanbad', value: 'Dhanbad'},
      {key: 'Dumka', value: 'Dumka'},
      {key: 'East Singhbhum', value: 'East Singhbhum'},
      {key: 'Garhwa', value: 'Garhwa'},
      {key: 'Giridih', value: 'Giridih'},
      {key: 'Godda', value: 'Godda'},
      {key: 'Gumla', value: 'Gumla'},
      {key: 'Hazaribag', value: 'Hazaribag'},
      {key: 'Jamtara', value: 'Jamtara'},
      {key: 'Khunti', value: 'Khunti'},
      {key: 'Koderma', value: 'Koderma'},
      {key: 'Latehar', value: 'Latehar'},
      {key: 'Lohardaga', value: 'Lohardaga'},
      {key: 'Pakur', value: 'Pakur'},
      {key: 'Palamu', value: 'Palamu'},
      {key: 'Ramgarh', value: 'Ramgarh'},
      {key: 'Ranchi', value: 'Ranchi'},
      {key: 'Sahibganj', value: 'Sahibganj'},
      {key: 'Seraikela-Kharsawan', value: 'Seraikela-Kharsawan'},
      {key: 'Simdega', value: 'Simdega'},
      {key: 'West Singhbhum', value: 'West Singhbhum'},
    ],
  },
  {
    state: 'Karnataka',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Bagalkot', value: 'Bagalkot'},
      {key: 'Ballari (Bellary)', value: 'Ballari (Bellary)'},
      {key: 'Belagavi (Belgaum)', value: 'Belagavi (Belgaum)'},
      {
        key: 'Bengaluru (Bangalore) Rural',
        value: 'Bengaluru (Bangalore) Rural',
      },
      {
        key: 'Bengaluru (Bangalore) Urban',
        value: 'Bengaluru (Bangalore) Urban',
      },
      {key: 'Bidar', value: 'Bidar'},
      {key: 'Chamarajanagar', value: 'Chamarajanagar'},
      {key: 'Chikballapur', value: 'Chikballapur'},
      {
        key: 'Chikkamagaluru (Chikmagalur)',
        value: 'Chikkamagaluru (Chikmagalur)',
      },
      {key: 'Chitradurga', value: 'Chitradurga'},
      {key: 'Dakshina Kannada', value: 'Dakshina Kannada'},
      {key: 'Davangere', value: 'Davangere'},
      {key: 'Dharwad', value: 'Dharwad'},
      {key: 'Gadag', value: 'Gadag'},
      {key: 'Hassan', value: 'Hassan'},
      {key: 'Haveri', value: 'Haveri'},
      {key: 'Kalaburagi (Gulbarga)', value: 'Kalaburagi (Gulbarga)'},
      {key: 'Kodagu', value: 'Kodagu'},
      {key: 'Kolar', value: 'Kolar'},
      {key: 'Koppal', value: 'Koppal'},
      {key: 'Mandya', value: 'Mandya'},
      {key: 'Mysuru (Mysore)', value: 'Mysuru (Mysore)'},
      {key: 'Raichur', value: 'Raichur'},
      {key: 'Ramanagara', value: 'Ramanagara'},
      {key: 'Shivamogga (Shimoga)', value: 'Shivamogga (Shimoga)'},
      {key: 'Tumakuru (Tumkur)', value: 'Tumakuru (Tumkur)'},
      {key: 'Udupi', value: 'Udupi'},
      {key: 'Uttara Kannada (Karwar)', value: 'Uttara Kannada (Karwar)'},
      {key: 'Vijayapura (Bijapur)', value: 'Vijayapura (Bijapur)'},
      {key: 'Yadgir', value: 'Yadgir'},
    ],
  },
  {
    state: 'Kerala',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Alappuzha', value: 'Alappuzha'},
      {key: 'Ernakulam', value: 'Ernakulam'},
      {key: 'Idukki', value: 'Idukki'},
      {key: 'Kannur', value: 'Kannur'},
      {key: 'Kasaragod', value: 'Kasaragod'},
      {key: 'Kollam', value: 'Kollam'},
      {key: 'Kottayam', value: 'Kottayam'},
      {key: 'Kozhikode', value: 'Kozhikode'},
      {key: 'Malappuram', value: 'Malappuram'},
      {key: 'Palakkad', value: 'Palakkad'},
      {key: 'Pathanamthitta', value: 'Pathanamthitta'},
      {key: 'Thiruvananthapuram', value: 'Thiruvananthapuram'},
      {key: 'Thrissur', value: 'Thrissur'},
      {key: 'Wayanad', value: 'Wayanad'},
    ],
  },
  {
    state: 'Lakshadweep (UT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Agatti', value: 'Agatti'},
      {key: 'Amini', value: 'Amini'},
      {key: 'Androth', value: 'Androth'},
      {key: 'Bithra', value: 'Bithra'},
      {key: 'Chethlath', value: 'Chethlath'},
      {key: 'Kavaratti', value: 'Kavaratti'},
      {key: 'Kadmath', value: 'Kadmath'},
      {key: 'Kalpeni', value: 'Kalpeni'},
      {key: 'Kilthan', value: 'Kilthan'},
      {key: 'Minicoy', value: 'Minicoy'},
    ],
  },
  {
    state: 'Madhya Pradesh',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Agar Malwa', value: 'Agar Malwa'},
      {key: 'Alirajpur', value: 'Alirajpur'},
      {key: 'Anuppur', value: 'Anuppur'},
      {key: 'Ashoknagar', value: 'Ashoknagar'},
      {key: 'Balaghat', value: 'Balaghat'},
      {key: 'Barwani', value: 'Barwani'},
      {key: 'Betul', value: 'Betul'},
      {key: 'Bhind', value: 'Bhind'},
      {key: 'Bhopal', value: 'Bhopal'},
      {key: 'Burhanpur', value: 'Burhanpur'},
      {key: 'Chhatarpur', value: 'Chhatarpur'},
      {key: 'Chhindwara', value: 'Chhindwara'},
      {key: 'Damoh', value: 'Damoh'},
      {key: 'Datia', value: 'Datia'},
      {key: 'Dewas', value: 'Dewas'},
      {key: 'Dhar', value: 'Dhar'},
      {key: 'Dindori', value: 'Dindori'},
      {key: 'Guna', value: 'Guna'},
      {key: 'Gwalior', value: 'Gwalior'},
      {key: 'Harda', value: 'Harda'},
      {key: 'Hoshangabad', value: 'Hoshangabad'},
      {key: 'Indore', value: 'Indore'},
      {key: 'Jabalpur', value: 'Jabalpur'},
      {key: 'Jhabua', value: 'Jhabua'},
      {key: 'Katni', value: 'Katni'},
      {key: 'Khandwa', value: 'Khandwa'},
      {key: 'Khargone', value: 'Khargone'},
      {key: 'Mandla', value: 'Mandla'},
      {key: 'Mandsaur', value: 'Mandsaur'},
      {key: 'Morena', value: 'Morena'},
      {key: 'Narsinghpur', value: 'Narsinghpur'},
      {key: 'Neemuch', value: 'Neemuch'},
      {key: 'Panna', value: 'Panna'},
      {key: 'Raisen', value: 'Raisen'},
      {key: 'Rajgarh', value: 'Rajgarh'},
      {key: 'Ratlam', value: 'Ratlam'},
      {key: 'Rewa', value: 'Rewa'},
      {key: 'Sagar', value: 'Sagar'},
      {key: 'Satna', value: 'Satna'},
      {key: 'Sehore', value: 'Sehore'},
      {key: 'Seoni', value: 'Seoni'},
      {key: 'Shahdol', value: 'Shahdol'},
      {key: 'Shajapur', value: 'Shajapur'},
      {key: 'Sheopur', value: 'Sheopur'},
      {key: 'Shivpuri', value: 'Shivpuri'},
      {key: 'Sidhi', value: 'Sidhi'},
      {key: 'Singrauli', value: 'Singrauli'},
      {key: 'Tikamgarh', value: 'Tikamgarh'},
      {key: 'Ujjain', value: 'Ujjain'},
      {key: 'Umaria', value: 'Umaria'},
      {key: 'Vidisha', value: 'Vidisha'},
    ],
  },
  {
    state: 'Maharashtra',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Ahmednagar', value: 'Ahmednagar'},
      {key: 'Akola', value: 'Akola'},
      {key: 'Amravati', value: 'Amravati'},
      {key: 'Aurangabad', value: 'Aurangabad'},
      {key: 'Beed', value: 'Beed'},
      {key: 'Bhandara', value: 'Bhandara'},
      {key: 'Buldhana', value: 'Buldhana'},
      {key: 'Chandrapur', value: 'Chandrapur'},
      {key: 'Dhule', value: 'Dhule'},
      {key: 'Gadchiroli', value: 'Gadchiroli'},
      {key: 'Gondia', value: 'Gondia'},
      {key: 'Hingoli', value: 'Hingoli'},
      {key: 'Jalgaon', value: 'Jalgaon'},
      {key: 'Jalna', value: 'Jalna'},
      {key: 'Kolhapur', value: 'Kolhapur'},
      {key: 'Latur', value: 'Latur'},
      {key: 'Mumbai City', value: 'Mumbai City'},
      {key: 'Mumbai Suburban', value: 'Mumbai Suburban'},
      {key: 'Nagpur', value: 'Nagpur'},
      {key: 'Nanded', value: 'Nanded'},
      {key: 'Nandurbar', value: 'Nandurbar'},
      {key: 'Nashik', value: 'Nashik'},
      {key: 'Osmanabad', value: 'Osmanabad'},
      {key: 'Palghar', value: 'Palghar'},
      {key: 'Parbhani', value: 'Parbhani'},
      {key: 'Pune', value: 'Pune'},
      {key: 'Raigad', value: 'Raigad'},
      {key: 'Ratnagiri', value: 'Ratnagiri'},
      {key: 'Sangli', value: 'Sangli'},
      {key: 'Satara', value: 'Satara'},
      {key: 'Sindhudurg', value: 'Sindhudurg'},
      {key: 'Solapur', value: 'Solapur'},
      {key: 'Thane', value: 'Thane'},
      {key: 'Wardha', value: 'Wardha'},
      {key: 'Washim', value: 'Washim'},
      {key: 'Yavatmal', value: 'Yavatmal'},
    ],
  },
  {
    state: 'Manipur',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Bishnupur', value: 'Bishnupur'},
      {key: 'Chandel', value: 'Chandel'},
      {key: 'Churachandpur', value: 'Churachandpur'},
      {key: 'Imphal East', value: 'Imphal East'},
      {key: 'Imphal West', value: 'Imphal West'},
      {key: 'Jiribam', value: 'Jiribam'},
      {key: 'Kakching', value: 'Kakching'},
      {key: 'Kamjong', value: 'Kamjong'},
      {key: 'Kangpokpi', value: 'Kangpokpi'},
      {key: 'Noney', value: 'Noney'},
      {key: 'Pherzawl', value: 'Pherzawl'},
      {key: 'Senapati', value: 'Senapati'},
      {key: 'Tamenglong', value: 'Tamenglong'},
      {key: 'Tengnoupal', value: 'Tengnoupal'},
      {key: 'Thoubal', value: 'Thoubal'},
      {key: 'Ukhrul', value: 'Ukhrul'},
    ],
  },
  {
    state: 'Meghalaya',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'East Garo Hills', value: 'East Garo Hills'},
      {key: 'East Jaintia Hills', value: 'East Jaintia Hills'},
      {key: 'East Khasi Hills', value: 'East Khasi Hills'},
      {key: 'North Garo Hills', value: 'North Garo Hills'},
      {key: 'Ri Bhoi', value: 'Ri Bhoi'},
      {key: 'South Garo Hills', value: 'South Garo Hills'},
      {key: 'South West Garo Hills ', value: 'South West Garo Hills '},
      {key: 'South West Khasi Hills', value: 'South West Khasi Hills'},
      {key: 'West Garo Hills', value: 'West Garo Hills'},
      {key: 'West Jaintia Hills', value: 'West Jaintia Hills'},
      {key: 'West Khasi Hills', value: 'West Khasi Hills'},
    ],
  },
  {
    state: 'Mizoram',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Aizawl', value: 'Aizawl'},
      {key: 'Champhai', value: 'Champhai'},
      {key: 'Kolasib', value: 'Kolasib'},
      {key: 'Lawngtlai', value: 'Lawngtlai'},
      {key: 'Lunglei', value: 'Lunglei'},
      {key: 'Mamit', value: 'Mamit'},
      {key: 'Saiha', value: 'Saiha'},
      {key: 'Serchhip', value: 'Serchhip'},
    ],
  },
  {
    state: 'Nagaland',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Dimapur', value: 'Dimapur'},
      {key: 'Kiphire', value: 'Kiphire'},
      {key: 'Kohima', value: 'Kohima'},
      {key: 'Longleng', value: 'Longleng'},
      {key: 'Mokokchung', value: 'Mokokchung'},
      {key: 'Mon', value: 'Mon'},
      {key: 'Peren', value: 'Peren'},
      {key: 'Phek', value: 'Phek'},
      {key: 'Tuensang', value: 'Tuensang'},
      {key: 'Wokha', value: 'Wokha'},
      {key: 'Zunheboto', value: 'Zunheboto'},
    ],
  },
  {
    state: 'Odisha',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Angul', value: 'Angul'},
      {key: 'Balangir', value: 'Balangir'},
      {key: 'Balasore', value: 'Balasore'},
      {key: 'Bargarh', value: 'Bargarh'},
      {key: 'Bhadrak', value: 'Bhadrak'},
      {key: 'Boudh', value: 'Boudh'},
      {key: 'Cuttack', value: 'Cuttack'},
      {key: 'Deogarh', value: 'Deogarh'},
      {key: 'Dhenkanal', value: 'Dhenkanal'},
      {key: 'Gajapati', value: 'Gajapati'},
      {key: 'Ganjam', value: 'Ganjam'},
      {key: 'Jagatsinghapur', value: 'Jagatsinghapur'},
      {key: 'Jajpur', value: 'Jajpur'},
      {key: 'Jharsuguda', value: 'Jharsuguda'},
      {key: 'Kalahandi', value: 'Kalahandi'},
      {key: 'Kandhamal', value: 'Kandhamal'},
      {key: 'Kendrapara', value: 'Kendrapara'},
      {key: 'Kendujhar (Keonjhar)', value: 'Kendujhar (Keonjhar)'},
      {key: 'Khordha', value: 'Khordha'},
      {key: 'Koraput', value: 'Koraput'},
      {key: 'Malkangiri', value: 'Malkangiri'},
      {key: 'Mayurbhanj', value: 'Mayurbhanj'},
      {key: 'Nabarangpur', value: 'Nabarangpur'},
      {key: 'Nayagarh', value: 'Nayagarh'},
      {key: 'Nuapada', value: 'Nuapada'},
      {key: 'Puri', value: 'Puri'},
      {key: 'Rayagada', value: 'Rayagada'},
      {key: 'Sambalpur', value: 'Sambalpur'},
      {key: 'Sonepur', value: 'Sonepur'},
      {key: 'Sundargarh', value: 'Sundargarh'},
    ],
  },
  {
    state: 'Puducherry (UT)',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Karaikal', value: 'Karaikal'},
      {key: 'Mahe', value: 'Mahe'},
      {key: 'Pondicherry', value: 'Pondicherry'},
      {key: 'Yanam', value: 'Yanam'},
    ],
  },
  {
    state: 'Punjab',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Amritsar', value: 'Amritsar'},
      {key: 'Barnala', value: 'Barnala'},
      {key: 'Bathinda', value: 'Bathinda'},
      {key: 'Faridkot', value: 'Faridkot'},
      {key: 'Fatehgarh Sahib', value: 'Fatehgarh Sahib'},
      {key: 'Fazilka', value: 'Fazilka'},
      {key: 'Ferozepur', value: 'Ferozepur'},
      {key: 'Gurdaspur', value: 'Gurdaspur'},
      {key: 'Hoshiarpur', value: 'Hoshiarpur'},
      {key: 'Jalandhar', value: 'Jalandhar'},
      {key: 'Kapurthala', value: 'Kapurthala'},
      {key: 'Ludhiana', value: 'Ludhiana'},
      {key: 'Mansa', value: 'Mansa'},
      {key: 'Moga', value: 'Moga'},
      {key: 'Muktsar', value: 'Muktsar'},
      {
        key: 'Nawanshahr (Shahid Bhagat Singh Nagar)',
        value: 'Nawanshahr (Shahid Bhagat Singh Nagar)',
      },
      {key: 'Pathankot', value: 'Pathankot'},
      {key: 'Patiala', value: 'Patiala'},
      {key: 'Rupnagar', value: 'Rupnagar'},
      {
        key: 'Sahibzada Ajit Singh Nagar (Mohali)',
        value: 'Sahibzada Ajit Singh Nagar (Mohali)',
      },
      {key: 'Sangrur', value: 'Sangrur'},
      {key: 'Tarn Taran', value: 'Tarn Taran'},
    ],
  },
  {
    state: 'Rajasthan',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Ajmer', value: 'Ajmer'},
      {key: 'Alwar', value: 'Alwar'},
      {key: 'Banswara', value: 'Banswara'},
      {key: 'Baran', value: 'Baran'},
      {key: 'Barmer', value: 'Barmer'},
      {key: 'Bharatpur', value: 'Bharatpur'},
      {key: 'Bhilwara', value: 'Bhilwara'},
      {key: 'Bikaner', value: 'Bikaner'},
      {key: 'Bundi', value: 'Bundi'},
      {key: 'Chittorgarh', value: 'Chittorgarh'},
      {key: 'Churu', value: 'Churu'},
      {key: 'Dausa', value: 'Dausa'},
      {key: 'Dholpur', value: 'Dholpur'},
      {key: 'Dungarpur', value: 'Dungarpur'},
      {key: 'Hanumangarh', value: 'Hanumangarh'},
      {key: 'Jaipur', value: 'Jaipur'},
      {key: 'Jaisalmer', value: 'Jaisalmer'},
      {key: 'Jalore', value: 'Jalore'},
      {key: 'Jhalawar', value: 'Jhalawar'},
      {key: 'Jhunjhunu', value: 'Jhunjhunu'},
      {key: 'Jodhpur', value: 'Jodhpur'},
      {key: 'Karauli', value: 'Karauli'},
      {key: 'Kota', value: 'Kota'},
      {key: 'Nagaur', value: 'Nagaur'},
      {key: 'Pali', value: 'Pali'},
      {key: 'Pratapgarh', value: 'Pratapgarh'},
      {key: 'Rajsamand', value: 'Rajsamand'},
      {key: 'Sawai Madhopur', value: 'Sawai Madhopur'},
      {key: 'Sikar', value: 'Sikar'},
      {key: 'Sirohi', value: 'Sirohi'},
      {key: 'Sri Ganganagar', value: 'Sri Ganganagar'},
      {key: 'Tonk', value: 'Tonk'},
      {key: 'Udaipur', value: 'Udaipur'},
    ],
  },
  {
    state: 'Sikkim',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'East Sikkim', value: 'East Sikkim'},
      {key: 'North Sikkim', value: 'North Sikkim'},
      {key: 'South Sikkim', value: 'South Sikkim'},
      {key: 'West Sikkim', value: 'West Sikkim'},
    ],
  },
  {
    state: 'Tamil Nadu',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Ariyalur', value: 'Ariyalur'},
      {key: 'Chennai', value: 'Chennai'},
      {key: 'Coimbatore', value: 'Coimbatore'},
      {key: 'Cuddalore', value: 'Cuddalore'},
      {key: 'Dharmapuri', value: 'Dharmapuri'},
      {key: 'Dindigul', value: 'Dindigul'},
      {key: 'Erode', value: 'Erode'},
      {key: 'Kanchipuram', value: 'Kanchipuram'},
      {key: 'Kanyakumari', value: 'Kanyakumari'},
      {key: 'Karur', value: 'Karur'},
      {key: 'Krishnagiri', value: 'Krishnagiri'},
      {key: 'Madurai', value: 'Madurai'},
      {key: 'Nagapattinam', value: 'Nagapattinam'},
      {key: 'Namakkal', value: 'Namakkal'},
      {key: 'Nilgiris', value: 'Nilgiris'},
      {key: 'Perambalur', value: 'Perambalur'},
      {key: 'Pudukkottai', value: 'Pudukkottai'},
      {key: 'Ramanathapuram', value: 'Ramanathapuram'},
      {key: 'Salem', value: 'Salem'},
      {key: 'Sivaganga', value: 'Sivaganga'},
      {key: 'Thanjavur', value: 'Thanjavur'},
      {key: 'Theni', value: 'Theni'},
      {key: 'Thoothukudi (Tuticorin)', value: 'Thoothukudi (Tuticorin)'},
      {key: 'Tiruchirappalli', value: 'Tiruchirappalli'},
      {key: 'Tirunelveli', value: 'Tirunelveli'},
      {key: 'Tiruppur', value: 'Tiruppur'},
      {key: 'Tiruvallur', value: 'Tiruvallur'},
      {key: 'Tiruvannamalai', value: 'Tiruvannamalai'},
      {key: 'Tiruvarur', value: 'Tiruvarur'},
      {key: 'Vellore', value: 'Vellore'},
      {key: 'Viluppuram', value: 'Viluppuram'},
      {key: 'Virudhunagar', value: 'Virudhunagar'},
    ],
  },
  {
    state: 'Telangana',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Adilabad', value: 'Adilabad'},
      {key: 'Bhadradri Kothagudem', value: 'Bhadradri Kothagudem'},
      {key: 'Hyderabad', value: 'Hyderabad'},
      {key: 'Jagtial', value: 'Jagtial'},
      {key: 'Jangaon', value: 'Jangaon'},
      {key: 'Jayashankar Bhoopalpally', value: 'Jayashankar Bhoopalpally'},
      {key: 'Jogulamba Gadwal', value: 'Jogulamba Gadwal'},
      {key: 'Kamareddy', value: 'Kamareddy'},
      {key: 'Karimnagar', value: 'Karimnagar'},
      {key: 'Khammam', value: 'Khammam'},
      {key: 'Komaram Bheem Asifabad', value: 'Komaram Bheem Asifabad'},
      {key: 'Mahabubabad', value: 'Mahabubabad'},
      {key: 'Mahabubnagar', value: 'Mahabubnagar'},
      {key: 'Mancherial', value: 'Mancherial'},
      {key: 'Medak', value: 'Medak'},
      {key: 'Medchal', value: 'Medchal'},
      {key: 'Nagarkurnool', value: 'Nagarkurnool'},
      {key: 'Nalgonda', value: 'Nalgonda'},
      {key: 'Nirmal', value: 'Nirmal'},
      {key: 'Nizamabad', value: 'Nizamabad'},
      {key: 'Peddapalli', value: 'Peddapalli'},
      {key: 'Rajanna Sircilla', value: 'Rajanna Sircilla'},
      {key: 'Rangareddy', value: 'Rangareddy'},
      {key: 'Sangareddy', value: 'Sangareddy'},
      {key: 'Siddipet', value: 'Siddipet'},
      {key: 'Suryapet', value: 'Suryapet'},
      {key: 'Vikarabad', value: 'Vikarabad'},
      {key: 'Wanaparthy', value: 'Wanaparthy'},
      {key: 'Warangal (Rural)', value: 'Warangal (Rural)'},
      {key: 'Warangal (Urban)', value: 'Warangal (Urban)'},
      {key: 'Yadadri Bhuvanagiri', value: 'Yadadri Bhuvanagiri'},
    ],
  },
  {
    state: 'Tripura',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Dhalai', value: 'Dhalai'},
      {key: 'Gomati', value: 'Gomati'},
      {key: 'Khowai', value: 'Khowai'},
      {key: 'North Tripura', value: 'North Tripura'},
      {key: 'Sepahijala', value: 'Sepahijala'},
      {key: 'South Tripura', value: 'South Tripura'},
      {key: 'Unakoti', value: 'Unakoti'},
      {key: 'West Tripura', value: 'West Tripura'},
    ],
  },
  {
    state: 'Uttarakhand',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Almora', value: 'Almora'},
      {key: 'Bageshwar', value: 'Bageshwar'},
      {key: 'Chamoli', value: 'Chamoli'},
      {key: 'Champawat', value: 'Champawat'},
      {key: 'Dehradun', value: 'Dehradun'},
      {key: 'Haridwar', value: 'Haridwar'},
      {key: 'Nainital', value: 'Nainital'},
      {key: 'Pauri Garhwal', value: 'Pauri Garhwal'},
      {key: 'Pithoragarh', value: 'Pithoragarh'},
      {key: 'Rudraprayag', value: 'Rudraprayag'},
      {key: 'Tehri Garhwal', value: 'Tehri Garhwal'},
      {key: 'Udham Singh Nagar', value: 'Udham Singh Nagar'},
      {key: 'Uttarkashi', value: 'Uttarkashi'},
    ],
  },
  {
    state: 'Uttar Pradesh',
    districts: [
      {key: 'Agra', value: 'Agra'},
      {key: 'Aligarh', value: 'Aligarh'},
      {key: 'Allahabad', value: 'Allahabad'},
      {key: 'Ambedkar Nagar', value: 'Ambedkar Nagar'},
      {
        key: 'Amethi (Chatrapati Sahuji Mahraj Nagar',
        value: 'Amethi (Chatrapati Sahuji Mahraj Nagar)',
      },
      {key: 'Amroha (J.P. Nagar)', value: 'Amroha (J.P. Nagar)'},
      {key: 'Auraiya', value: 'Auraiya'},
      {key: 'Azamgarh', value: 'Azamgarh'},
      {key: 'Baghpat', value: 'Baghpat'},
      {key: 'Bahraich', value: 'Bahraich'},
      {key: 'Ballia', value: 'Ballia'},
      {key: 'Balrampur', value: 'Balrampur'},
      {key: 'Banda', value: 'Banda'},
      {key: 'Barabanki', value: 'Barabanki'},
      {key: 'Bareilly', value: 'Bareilly'},
      {key: 'Basti', value: 'Basti'},
      {key: 'Bhadohi', value: 'Bhadohi'},
      {key: 'Bijnor', value: 'Bijnor'},
      {key: 'Budaun', value: 'Budaun'},
      {key: 'Bulandshahr', value: 'Bulandshahr'},
      {key: 'Chandauli', value: 'Chandauli'},
      {key: 'Chitrakoot', value: 'Chitrakoot'},
      {key: 'Deoria', value: 'Deoria'},
      {key: 'Etah', value: 'Etah'},
      {key: 'Etawah', value: 'Etawah'},
      {key: 'Faizabad', value: 'Faizabad'},
      {key: 'Farrukhabad', value: 'Farrukhabad'},
      {key: 'Fatehpur', value: 'Fatehpur'},
      {key: 'Firozabad', value: 'Firozabad'},
      {key: 'Gautam Buddha Nagar', value: 'Gautam Buddha Nagar'},
      {key: 'Ghaziabad', value: 'Ghaziabad'},
      {key: 'Ghazipur', value: 'Ghazipur'},
      {key: 'Gonda', value: 'Gonda'},
      {key: 'Gorakhpur', value: 'Gorakhpur'},
      {key: 'Hamirpur', value: 'Hamirpur'},
      {key: 'Hapur (Panchsheel Nagar)', value: 'Hapur (Panchsheel Nagar)'},
      {key: 'Hardoi', value: 'Hardoi'},
      {key: 'Hathras', value: 'Hathras'},
      {key: 'Jalaun', value: 'Jalaun'},
      {key: 'Jaunpur', value: 'Jaunpur'},
      {key: 'Jhansi', value: 'Jhansi'},
      {key: 'Kannauj', value: 'Kannauj'},
      {key: 'Kanpur Dehat', value: 'Kanpur Dehat'},
      {key: 'Kanpur Nagar', value: 'Kanpur Nagar'},
      {key: 'Kanshiram Nagar (Kasganj)', value: 'Kanshiram Nagar (Kasganj)'},
      {key: 'Kaushambi', value: 'Kaushambi'},
      {key: 'Kushinagar (Padrauna)', value: 'Kushinagar (Padrauna)'},
      {key: 'Lakhimpur - Kheri', value: 'Lakhimpur - Kheri'},
      {key: 'Lalitpur', value: 'Lalitpur'},
      {key: 'Lucknow', value: 'Lucknow'},
      {key: 'Maharajganj', value: 'Maharajganj'},
      {key: 'Mahoba', value: 'Mahoba'},
      {key: 'Mainpuri', value: 'Mainpuri'},
      {key: 'Mathura', value: 'Mathura'},
      {key: 'Mau', value: 'Mau'},
      {key: 'Meerut', value: 'Meerut'},
      {key: 'Mirzapur', value: 'Mirzapur'},
      {key: 'Moradabad', value: 'Moradabad'},
      {key: 'Muzaffarnagar', value: 'Muzaffarnagar'},
      {key: 'Pilibhit', value: 'Pilibhit'},
      {key: 'Pratapgarh', value: 'Pratapgarh'},
      {key: 'RaeBareli', value: 'RaeBareli'},
      {key: 'Rampur', value: 'Rampur'},
      {key: 'Saharanpur', value: 'Saharanpur'},
      {key: 'Sambhal (Bhim Nagar)', value: 'Sambhal (Bhim Nagar)'},
      {key: 'Sant Kabir Nagar', value: 'Sant Kabir Nagar'},
      {key: 'Shahjahanpur', value: 'Shahjahanpur'},
      {key: 'Shamali (Prabuddh Nagar)', value: 'Shamali (Prabuddh Nagar)'},
      {key: 'Shravasti', value: 'Shravasti'},
      {key: 'Siddharth Nagar', value: 'Siddharth Nagar'},
      {key: 'Sitapur', value: 'Sitapur'},
      {key: 'Sonbhadra', value: 'Sonbhadra'},
      {key: 'Sultanpur', value: 'Sultanpur'},
      {key: 'Unnao', value: 'Unnao'},
      {key: 'Varanasi', value: 'Varanasi'},
    ],
  },
  {
    state: 'West Bengal',
    districts: [
      {key: 'All', value: 'All'},
      {key: 'Alipurduar', value: 'Alipurduar'},
      {key: 'Bankura', value: 'Bankura'},
      {key: 'Birbhum', value: 'Birbhum'},
      {key: 'Burdwan (Bardhaman)', value: 'Burdwan (Bardhaman)'},
      {key: 'Cooch Behar', value: 'Cooch Behar'},
      {
        key: 'Dakshin Dinajpur (South Dinajpur)',
        value: 'Dakshin Dinajpur (South Dinajpur)',
      },
      {key: 'Darjeeling', value: 'Darjeeling'},
      {key: 'Hooghly', value: 'Hooghly'},
      {key: 'Howrah', value: 'Howrah'},
      {key: 'Jalpaiguri', value: 'Jalpaiguri'},
      {key: 'Kalimpong', value: 'Kalimpong'},
      {key: 'Kolkata', value: 'Kolkata'},
      {key: 'Malda', value: 'Malda'},
      {key: 'Murshidabad', value: 'Murshidabad'},
      {key: 'Nadia', value: 'Nadia'},
      {key: 'North 24 Parganas', value: 'North 24 Parganas'},
      {
        key: 'Paschim Medinipur (West Medinipur)',
        value: 'Paschim Medinipur (West Medinipur)',
      },
      {
        key: 'Purba Medinipur (East Medinipur)',
        value: 'Purba Medinipur (East Medinipur)',
      },
      {key: 'Purulia', value: 'Purulia'},
      {key: 'South 24 Parganas', value: 'South 24 Parganas'},
      {
        key: 'Uttar Dinajpur (North Dinajpur)',
        value: 'Uttar Dinajpur (North Dinajpur)',
      },
    ],
  },
];
