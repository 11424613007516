import {
  PREFEREDLANGUAGE_FAILURE,
  PREFEREDLANGUAGE_REQUEST,
  PREFEREDLANGUAGE_SUCCESS,
} from './constants';
import {combineReducers} from 'redux';

const preferedLanguageData = (state = null, action) => {
  switch (action.type) {
    case PREFEREDLANGUAGE_REQUEST:
      return state;
    case PREFEREDLANGUAGE_SUCCESS:
      return action.payload;
    case PREFEREDLANGUAGE_FAILURE:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  preferedLanguageData,
});
