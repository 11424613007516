import React from 'react';
import moment from 'moment';

export const activitiesColumns = [
  {
    title: 'Activity Type',
    dataIndex: 'activityTypeName',
    key: 'activityTypeName',
    sorter: {
      compare: (a, b) => a.activityTypeName.localeCompare(b.activityTypeName),
    },
  },
  {
    title: 'Actvity Status',
    dataIndex: 'activityStatusName',
    key: 'activityStatusName',
    sorter: {
      compare: (a, b) =>
        a.activityStatusName.localeCompare(b.activityStatusName),
    },
  },

  {
    title: 'Account Name',
    dataIndex: 'accountName',
    key: 'accountName',
    sorter: {
      compare: (a, b) => a.accountName.localeCompare(b.accountName),
    },
  },
  {
    title: 'Contact Name',
    dataIndex: 'contactName',
    key: 'contactName',
    sorter: (a, b) => {
      a = a.contactName === null ? '' : a.contactName;
      b = b.contactName === null ? '' : b.contactName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Opportunity MCode',
    dataIndex: 'opportunityMCode',
    key: 'opportunityMCode',
    sorter: {
      compare: (a, b) => a.opportunityMCode / b.opportunityMCode,
    },
  },
  {
    title: 'Opportunity Model',
    dataIndex: 'opportunityModel',
    key: 'opportunityMCode',
  },
  {
    title: 'Assigned User',
    dataIndex: 'assignedUserName',
    key: 'assignedUserName',
    sorter: (a, b) => {
      a = a.assignedUserName === null ? '' : a.assignedUserName;
      b = b.assignedUserName === null ? '' : b.assignedUserName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'BYD Code',
    dataIndex: 'byDCode',
    key: 'byDCode',
    sorter: {
      compare: (a, b) => a.byDCode / b.byDCode,
    },
  },
  {
    title: 'Closing Date',
    dataIndex: 'closingDateTime',
    key: 'closingDateTime',
    render: (closingDateTime) => {
      return closingDateTime ? (
        <p>{moment(closingDateTime).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.closingDateTime) / moment(b.closingDateTime),
    },
  },
  {
    title: 'Creation Date',
    dataIndex: 'createdOn',
    key: 'createdOn',
    render: (createdOn) => {
      return createdOn ? (
        <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.createdOn) / moment(b.createdOn),
    },
  },
];
