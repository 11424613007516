import React, {useEffect, useState} from 'react';
import {
  Modal,
  Collapse,
  Input,
  Checkbox,
  Select,
  Button,
  Layout,
  Form,
  AutoComplete,
  message,
} from 'antd';
import COLORS from '../../constants/style/colors';
import {
  PINCODE_REGEX,
  PAN_REGEX,
  MOBILE_NUMBER_REGEX,
  GSTIN_NUMBER_REGEX,
} from '../../constants/default';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getAccountAddress} from '../../modules/address/action';
import {
  getUserAddressCountriesData,
  getUserAddressStatesData,
} from '../../modules/address/selectors';
import {userSearch} from '../../modules/userDetails/action';
import {getUserSearchData} from '../../modules/userDetails/selector';
import {getAccountMaster} from '../../modules/accountmaster/action';
import {
  getAccounttTypesData,
  getCustomerCayegoriesData,
  getApplicationTypesData,
} from '../../modules/accountmaster/selector';
import {updateAccount} from '../../modules/accounts/action';
import {updateAccountDataSelector} from '../../modules/accounts/selectors';
import styled from 'styled-components';
import {
  getPanDetail,
  getGstDetail,
  getMobileDetail,
} from '../../modules/accounts/action';
import {
  getPanData,
  getGstData,
  getMobileData,
} from '../../modules/accounts/selectors';
import {data} from '../../data/accountsColumns';
import states from '../../data/states';

const {Panel} = Collapse;
const {Option} = Select;

const AccountModalForm = ({visible, onCancel, onCreate, onSubmit, data}) => {
  const dispatch = useDispatch();
  const makeFieldDisable = data?.externalCustomerId !== '' ? true : false;
  console.log(data);
  const [form, setForm] = useState({
    accountName: '',
    accountType: () => 'All',
    application: () => 'All',
    emailId: '',
    customerCategory: () => 'All',
    isKeyAccount: false,
    salesManager: '',
    pan: '',
    gstin: '',
    contactNumber: '',
    state: () => 'All',
    country: () => 'All',
    district: '',
    city: '',
    pincode: '',
    addressLine1: '',
    addressLine2: '',
    addressLine3: '',
  });

  const [salesManagerOptions, setSalesManagerOptions] = useState([]);
  const [applicationTypesOptions, setApplicationTypesOptions] = useState([]);

  const [requiredFieldsValue, setRequiredFieldsValue] = useState({
    name: true,
    pincode: true,
  });

  const [formErrors, setFormErrors] = useState({
    email: '',
    pincode: '',
    gstin: '',
    pan: '',
    contactNumber: '',
  });

  const authUser = useSelector(getAuthUserData);

  useEffect(() => {
    dispatch(getAccountMaster(authUser.accessToken));
    dispatch(getAccountAddress(authUser.accessToken, data.userId));
  }, [dispatch, authUser.accessToken, data]);

  useEffect(() => {
    dispatch(
      userSearch(
        authUser.accessToken,
        form.salesManager + '-' + data.userId,
        1,
        5,
      ),
    );
  }, [form.salesManager, dispatch, authUser.accessToken, data]);

  const statesData = useSelector(getUserAddressStatesData);
  const CountriesData = useSelector(getUserAddressCountriesData);
  //const DistrictsData = useSelector(getDistrictsData);
  const accountTypesData = useSelector(getAccounttTypesData);
  const customerCategoriesData = useSelector(getCustomerCayegoriesData);
  const applicationTypesData = useSelector(getApplicationTypesData);
  const userSearchData = useSelector(getUserSearchData);
  const updateStatus = useSelector(updateAccountDataSelector);
  useEffect(() => {
    if (visible) {
      //console.log('Districts Data: ', DistrictsData);
      setForm({
        accountName: data ? data.name : '',
        accountType: data ? data?.accountTypeCode : '',
        application: data ? data?.application : null,
        emailId: data ? data?.email : '',
        customerCategory: data ? data?.customerCategoryCode : '',
        isKeyAccount: data ? data?.isKeyAccount : false,
        salesManager: data ? data?.salesManager : '',
        pan: data ? data?.pan : '',
        gstin: data ? data?.gstin : '',
        contactNumber: data ? data?.mobileNumber : '',
        state: data && data.address ? data.address?.stateCode : 'All',
        country: data && data.address ? data.address?.countryCode : 'All',
        district: data && data.address ? data.address?.district : '',
        city: data && data.address ? data.address?.city : '',
        pincode: data && data.address ? data.address?.pincode : '',
        addressLine1: data && data.address ? data.address?.addressLine1 : '',
        addressLine2: data && data.address ? data.address?.addressLine2 : '',
        addressLine3: data && data.address ? data.address?.addressLine3 : '',
      });
    }

    //setSelectedOption(data ? data?.application : null);
  }, [visible, data]);

  useEffect(() => {
    requriedValidation();
  }, [form.accountName, form.pincode]);

  const requriedValidation = () => {
    if (form.accountName && form.accountName !== '' && form.accountName) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, name: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, name: true};
      });
    }
    if (
      form.pincode &&
      form.pincode !== '' &&
      form.pincode &&
      form.pincode !== null
    ) {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, pincode: false};
      });
    } else {
      setRequiredFieldsValue((prevState) => {
        return {...prevState, pincode: true};
      });
    }
  };

  useEffect(() => {
    validate(form);
  }, [form.emailId, form.gstin, form.pan, form.pincode, form.contactNumber]);

  const validate = (form) => {
    const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    setFormErrors({
      email:
        form.emailId !== '' &&
        form.emailId !== undefined &&
        form.emailId !== null &&
        reg.test(form.emailId) === false
          ? 'Email Field is Invalid'
          : '',
      pincode:
        form.pincode !== '' &&
        form.pincode !== undefined &&
        form.pincode !== null &&
        PINCODE_REGEX.test(form.pincode) === false
          ? 'PINCode is Invalid'
          : '',
      gstin:
        form.gstin !== '' &&
        form.gstin !== undefined &&
        form.gstin !== null &&
        GSTIN_NUMBER_REGEX.test(form.gstin) === false
          ? 'GSTIN is Invalid'
          : '',
      pan:
        form.pan !== '' &&
        form.pan !== undefined &&
        form.pan !== null &&
        PAN_REGEX.test(form.pan) === false
          ? 'PAN is Invalid'
          : '',
      contactNumber:
        form.contactNumber !== '' &&
        form.contactNumber !== undefined &&
        form.contactNumber !== null &&
        MOBILE_NUMBER_REGEX.test(form.contactNumber) === false
          ? 'Contact Number is Invalid'
          : '',
    });

    if (
      formErrors.email === '' &&
      formErrors.contactNumber === '' &&
      formErrors.gstin === '' &&
      formErrors.pan === '' &&
      formErrors.pincode === ''
    ) {
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (userSearchData) {
      let options = userSearchData.map((item, index) => {
        return {
          value: item.employeeCode,
          label: item.employeeCode,
        };
      });
      setSalesManagerOptions(options);
    }
  }, [userSearchData]);

  useEffect(() => {
    if (applicationTypesData) {
      let options = applicationTypesData.map((item, index) => {
        return <Option key={item.code}>{item.name}</Option>;
      });
      setApplicationTypesOptions(options);
    }
  }, [applicationTypesData]);

  useEffect(() => {
    if (visible) {
      if (updateStatus?.type === 'ACCOUNTUPDATE_SUCCESS') {
        message.success(
          {
            content: 'Account Updated Sucessfully !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
        onSubmit();
        onCancel();
      } else if (updateStatus?.type === 'ACCOUNTUPDATE_FAILURE') {
        message.error(
          {
            content: 'Account Update Failed !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    }
  }, [updateStatus]);

  const onIsKeyAccountChange = (events) => {
    setForm({...form, isKeyAccount: !form.isKeyAccount});
  };

  const onStateChanged = (value) => {
    setForm({...form, state: value});
  };

  const onCountryChanged = (value) => {
    setForm({...form, country: value});
  };

  const onAccountTypeChanged = (value) => {
    setForm({...form, accountType: value});
  };

  const handleChange = (selected) => {
    setForm({...form, application: selected});
  };

  const onCustomerCategoryChanged = (value) => {
    setForm({...form, customerCategory: value});
  };

  const onSelect = (value) => {
    setForm({...form, salesManager: value});
  };

  const onChange = (value) => {
    setForm({...form, salesManager: value});
  };

  const onSearchSelect = (value) => {
    //console.log('search:', value);
  };

  const [goAhead, setGoAhead] = useState(false);
  const submitForm = () => {
    if (
      form.accountName === '' ||
      form.accountName === undefined ||
      form.pincode === null ||
      form.pincode === undefined ||
      form.pincode === ''
    ) {
      message.warning('Please enter all required values.');
    } else {
      if (validate(form)) {
        // let selectedUser = userSearchData.filter(
        //   (user) => user.employeeCode === form.salesManager,
        // );

        if (
          form.pan === data?.pan &&
          form.gstin === data?.gstin &&
          form.contactNumber === data?.mobileNumber
        ) {
          Continue();
        } else {
          if (form.pan !== data?.pan) {
            if (CheckDuplicatePan()) {
              showErrorMessage(
                'PAN already exists with account ' + panData[0].mCode,
              );
            } else {
              if (form.gstin !== data?.gstin) {
                if (CheckDuplicateGst()) {
                  showErrorMessage(
                    'GST already exists with account ' + gstData[0].mCode,
                  );
                } else {
                  if (form.contactNumber !== data?.mobileNumber) {
                    if (CheckDuplicateMobile()) {
                      showErrorMessage(
                        'Mobile Number already exists with account ' +
                          mobileData[0].mCode,
                      );
                    } else {
                      Continue();
                    }
                  } else {
                    Continue();
                  }
                }
              } else {
                if (form.contactNumber !== data?.mobileNumber) {
                  if (CheckDuplicateMobile()) {
                    showErrorMessage(
                      'Mobile Number already exists with account ' +
                        mobileData[0].mCode,
                    );
                  } else {
                    Continue();
                  }
                } else {
                  Continue();
                }
              }
            }
          } else if (form.gstin !== data?.gstin) {
            if (CheckDuplicateGst()) {
              showErrorMessage(
                'GST already exists with account ' + gstData[0].mCode,
              );
            } else {
              if (form.contactNumber !== data?.mobileNumber) {
                if (CheckDuplicateMobile()) {
                  showErrorMessage(
                    'Mobile Number already exists with account ' +
                      mobileData[0].mCode,
                  );
                } else {
                  Continue();
                }
              } else {
                Continue();
              }
            }
          } else if (form.contactNumber !== data?.mobileNumber) {
            if (CheckDuplicateMobile()) {
              showErrorMessage(
                'Mobile Number already exists with account ' +
                  mobileData[0].mCode,
              );
            } else {
              Continue();
            }
          }
        }
      } else {
        console.log('Something goes wrong');
      }
    }
  };

  const Continue = () => {
    let selectedState = statesData?.filter(
      (state) => state.code === form.state,
    );

    let selectedCountry = CountriesData?.filter(
      (country) => country.code === form.country,
    );

    let selectedAccountType = accountTypesData.filter(
      (accountType) => accountType.code === form.accountType,
    );

    let selectedCustomerCategory = customerCategoriesData.filter(
      (customerCategory) => customerCategory.code === form.customerCategory,
    );

    let stateName = null;
    let countryName = null;
    let AccountType = null;
    let CustomerCategory = null;
    //let Application = null;

    if (selectedState?.length > 0) {
      stateName = selectedState[0]?.name;
    }
    if (selectedCountry?.length > 0) {
      countryName = selectedCountry[0]?.name;
    }
    if (selectedAccountType?.length > 0) {
      AccountType = selectedAccountType[0]?.name;
    }
    if (selectedCustomerCategory?.length > 0) {
      CustomerCategory = selectedCustomerCategory[0]?.name;
    }
    const updatedData = {
      id: data.id,
      mCode: data.mCode,
      name: form.accountName,
      email: form.emailId,
      mobileNumber: form.contactNumber,
      pan: form.pan,
      gstin: form.gstin,
      source: data.source,
      isKeyAccount: form.isKeyAccount,
      salesManager: form.salesManager,
      createdOn: data.createdOn,
      createdBy: data.createdBy,
      userId: data.userId,
      RequiresSync: true,
      userEmailAddress: data.userEmailAddress,
      employeeId: data.employeeId,
      accountTypeCode: AccountType,
      customerCategoryCode: CustomerCategory,
      externalCustomerId: data.externalCustomerId,
      address: {
        id: data.address.id,
        addressLine1: form.addressLine1,
        addressLine2: form.addressLine2,
        addressLine3: form.addressLine3,
        pincode: form.pincode,
        stateCode: form.state,
        countryCode: form.country,
        state: stateName,
        country: countryName,
        district: form.district,
        districtCode: form.district,
        city: form.city,
        cityCode: form.city,
      },
      application: form.application,
      accountAttachments: data.accountAttachments,
    };

    dispatch(updateAccount(authUser.accessToken, updatedData));
  };

  const OnSaveClick = (events) => {
    events.preventDefault();
    submitForm();
  };
  const panData = useSelector(getPanData);
  useEffect(() => {
    if (form.pan !== data?.pan && formErrors.pan === '') {
      dispatch(getPanDetail(authUser.accessToken, form.pan));
    }
  }, [form.pan, formErrors.pan]);
  const CheckDuplicatePan = () => {
    return panData?.length === 0 ? false : true;
  };
  const gstData = useSelector(getGstData);
  useEffect(() => {
    if (form.gstin !== data?.gstin && formErrors.gstin === '') {
      dispatch(getGstDetail(authUser.accessToken, form.gstin));
    }
  }, [form.gstin, formErrors.gstin]);
  const CheckDuplicateGst = () => {
    return gstData?.length === 0 ? false : true;
  };
  const mobileData = useSelector(getMobileData);
  useEffect(() => {
    if (
      form.contactNumber !== data?.mobileNumber &&
      formErrors.contactNumber === ''
    ) {
      dispatch(getMobileDetail(authUser.accessToken, form.contactNumber));
    }
  }, [form.contactNumber, formErrors.contactNumber]);
  const CheckDuplicateMobile = () => {
    return mobileData?.length === 0 ? false : true;
  };
  const showErrorMessage = (text) => {
    message.error(
      {
        content: text,
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      },
      5,
    );
  };

  return (
    <Modal
      cancelText="Close"
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      title="Account Details"
      visible={visible}
      width={1000}>
      <Form>
        <table className="tableServiceDetails" width="100%">
          <thead></thead>
          <tbody>
            <tr>
              <td style={{float: 'right'}}>
                <Button
                  htmlType="submit"
                  onClick={OnSaveClick}
                  style={{
                    background: '#fd560eb5',
                    border: '#fd560eb5',
                    fontWeight: '550',
                  }}
                  type="submit">
                  Save
                </Button>
              </td>
            </tr>
          </tbody>
        </table>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Basic Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> M Code</td>
                  <td className="tdHeader"> Account Id</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      style={{width: '100%'}}
                      value={data ? data.mCode : ''}
                    />
                  </td>
                  <td>
                    <Input disabled value={data ? data.id : ''} />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Account Name <span style={{color: 'red'}}>*</span>
                  </td>

                  <td className="tdHeader">Account type</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      maxLength={40}
                      onChange={(e) =>
                        setForm({...form, accountName: e.target.value})
                      }
                      value={form.accountName}
                    />
                  </td>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onAccountTypeChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a AccountType"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.accountType}>
                      {accountTypesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>
                    {requiredFieldsValue.name === true ? (
                      <span style={{color: 'red'}}>
                        Account Name is required
                      </span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tdHeader"> Email</td>
                  <td className="tdHeader"> Application Type</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(e) =>
                        setForm({...form, emailId: e.target.value})
                      }
                      value={form.emailId}
                    />
                  </td>

                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      mode="multiple"
                      onChange={handleChange}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Application Type"
                      showSearch
                      style={{width: '100%'}}
                      value={form.application}>
                      {applicationTypesOptions}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.email}</span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tdHeader"> Customer Category</td>
                  <td className="tdHeader"> PAN</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onCustomerCategoryChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Customer Category Type"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.customerCategory}>
                      {customerCategoriesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Input
                      disabled={makeFieldDisable}
                      onChange={(e) => setForm({...form, pan: e.target.value})}
                      value={form.pan}
                    />
                  </td>
                </tr>
                <tr>
                  <td></td>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.pan}</span>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> GSTIN Number</td>
                  <td className="tdHeader"> Sales Manager</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled={makeFieldDisable}
                      onChange={(e) =>
                        setForm({...form, gstin: e.target.value})
                      }
                      value={form.gstin}
                    />
                  </td>
                  <td>
                    <AutoComplete
                      onChange={onChange}
                      onSelect={onSelect}
                      options={salesManagerOptions}
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.salesManager}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.gstin}</span>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tdHeader"></td>

                  <td className="tdHeader">
                    {'  '}Is Key Account{'  '}
                    <Checkbox
                      checked={form.isKeyAccount}
                      defaultChecked={false}
                      onClick={onIsKeyAccountChange}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Address" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Country</td>
                  <td className="tdHeader"> State</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onCountryChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a Country"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.country}>
                      {CountriesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                  <td>
                    <Select
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          .toLowerCase()
                          .includes(input.toLowerCase())
                      }
                      onChange={onStateChanged}
                      onSearch={onSearchSelect}
                      optionFilterProp="children"
                      placeholder="Select a State"
                      showSearch
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.state}>
                      {statesData?.map((p) => (
                        <Option value={p.code}>{p.name}</Option>
                      ))}
                    </Select>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> District</td>
                  <td className="tdHeader"> City</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(e) =>
                        setForm({...form, district: e.target.value})
                      }
                      value={form.district}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(e) => setForm({...form, city: e.target.value})}
                      value={form.city}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Pincode <span style={{color: 'red'}}>*</span>
                  </td>
                  <td className="tdHeader">Address Line1</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      maxLength={6}
                      onChange={(e) =>
                        setForm({...form, pincode: e.target.value})
                      }
                      value={form.pincode}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(e) =>
                        setForm({...form, addressLine1: e.target.value})
                      }
                      value={form.addressLine1}
                    />
                  </td>
                </tr>
                {/* <tr>
                  <td>
                    <span style={{color: 'red'}}>{formErrors.pincode}</span>
                  </td>
                  <td></td>
                </tr> */}
                <tr>
                  <td>
                    {requiredFieldsValue.pincode === true ? (
                      <span style={{color: 'red'}}>PIN Code is required</span>
                    ) : formErrors.pincode !== '' ? (
                      <span style={{color: 'red'}}>{formErrors.pincode}</span>
                    ) : (
                      ''
                    )}
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td className="tdHeader"> Address Line2</td>
                  <td className="tdHeader"> Address Line3</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      onChange={(e) =>
                        setForm({...form, addressLine2: e.target.value})
                      }
                      value={form.addressLine2}
                    />
                  </td>
                  <td>
                    <Input
                      onChange={(e) =>
                        setForm({...form, addressLine3: e.target.value})
                      }
                      value={form.addressLine3}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Mobile Number</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      maxLength={10}
                      onChange={(e) =>
                        setForm({...form, contactNumber: e.target.value})
                      }
                      value={form.contactNumber}
                    />
                  </td>
                </tr>
                <tr>
                  <td>
                    <span style={{color: 'red'}}>
                      {formErrors.contactNumber}
                    </span>
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};
const StyledHeader = styled(Layout.Header)`
  background: ${COLORS.textColor.lightGray};
`;
export default AccountModalForm;
