import {createSelector} from 'reselect';

export const getAddresses = ({address}) => address;
//export const getDistricts = ({district}) => district;

export const getStatesData = createSelector(
  getAddresses,
  (address) => address?.addressesData?.states,
);

export const getCountriesData = createSelector(
  getAddresses,
  (address) => address?.addressesData?.countries,
);

export const getDistrictsData = createSelector(
  getAddresses,
  (address) => address?.districtsData,
);

export const getUserAddressCountriesData = createSelector(
  getAddresses,
  (address) => address?.addressesUserData?.countries,
);

export const getUserAddressStatesData = createSelector(
  getAddresses,
  (address) => address?.addressesUserData?.states,
);
