import {createSelector} from 'reselect';
import stages from './constants/stages';
import statuses from './constants/statuses';

// 1
export const getDashboard = ({dashboard}) => dashboard;

// 2
export const getDashboardError = createSelector(
  getDashboard,
  (dashboard) => dashboard.error,
);

export const getDashboardHasFetched = createSelector(
  getDashboard,
  (dashboard) => dashboard.hasFetched,
);

export const getDashboardIsFetching = createSelector(
  getDashboard,
  (dashboard) => dashboard.isFetching,
);

export const getDashboardPayload = createSelector(
  getDashboard,
  (dashboard) => dashboard.payload,
);

// 3
export const getDashboardShouldFetch = createSelector(
  getDashboardHasFetched,
  getDashboardIsFetching,
  (hasFetched, isFetching) => !hasFetched && !isFetching,
);

export const getDashboardTodaysActivities = createSelector(
  getDashboardPayload,
  (payload) => (payload ? payload.todaysActivites : []),
);

export const getDashboardStages = createSelector(
  getDashboardPayload,
  (payload) => {
    if (payload && payload.stages) {
      const final = {};
      for (let name of Object.keys(stages)) {
        final[name] = {...stages[name]};
        if (payload.stages[name]) {
          final[name].code = payload.stages[name].code;
          if (payload.stages[name].count) {
            final[name].value = payload.stages[name].count;
          }
        }
      }
      return final;
    }
    return {...stages};
  },
);

export const getDashboardStatuses = createSelector(
  getDashboardPayload,
  (payload) => {
    if (payload && payload.statuses) {
      const final = {};
      for (let name of Object.keys(statuses)) {
        final[name] = {...statuses[name]};
        if (payload.statuses[name]) {
          final[name].code = payload.statuses[name].code;
          if (payload.statuses[name].count) {
            final[name].value = payload.statuses[name].count;
          }
        }
      }
      return final;
    }
    return {...statuses};
  },
);
export const getDownloadedData = createSelector(
  getDashboard,
  (dashboard) => dashboard.downloadedData,
);
export const isFetchingDownloadData = createSelector(
  getDashboard,
  (dashboard) => dashboard.isFetchingDownloadData,
);
export const getFetchedTableData = createSelector(
  getDashboard,
  (dashboard) => dashboard.tableData,
);
export const isFetchingTableData = createSelector(
  getDashboard,
  (dashboard) => dashboard.isFetchingTableData,
);
export const DashboardFilterData = createSelector(
  getDashboard,
  (dashboard) => dashboard.DashboardFilterData,
);

export const ProductCategoryFilterData = createSelector(
  getDashboard,
  (dashboard) => dashboard.ProductCategoryFilterData,
);
export const ProductSubCategoryFilterData = createSelector(
  getDashboard,
  (dashboard) => dashboard.ProductSubCategoryFilterData,
);
export const ModelVariantFilterData = createSelector(
  getDashboard,
  (dashboard) => dashboard.ModelVariantFilterData,
);
export const DealersSaleRepresentativeData = createSelector(
  getDashboard,
  (dashboard) => dashboard.dealersSalesRepresentativeData,
);
