import {
  BULKUPLOADSTATUSLIST_REQUEST,
  BULKUPLOADSTATUSLIST_SUCCESS,
  BULKUPLOADSTATUSLIST_FAILURE,
  BULKUPLOADRESPONSE_REQUEST,
  BULKUPLOADRESPONSE_SUCCESS,
  BULKUPLOADRESPONSE_FAILURE,
  BULKUPLOADREQUEST_REQUEST,
  BULKUPLOADREQUEST_SUCCESS,
  BULKUPLOADREQUEST_FAILURE,
  BULKUPLOADFILEFILTER_REQUEST,
  BULKUPLOADFILEFILTER_SUCCESS,
  BULKUPLOADFILEFILTER_FAILURE,
} from './constants';

import {combineReducers} from 'redux';

const statusList = (state = null, action) => {
  switch (action.type) {
    case BULKUPLOADSTATUSLIST_REQUEST:
      return state;
    case BULKUPLOADSTATUSLIST_SUCCESS:
      return action.payload;
    case BULKUPLOADSTATUSLIST_FAILURE:
      return state;
    default:
      return state;
  }
};

const bulkUploadResponse = (state = null, action) => {
  switch (action.type) {
    case BULKUPLOADRESPONSE_REQUEST:
      return state;
    case BULKUPLOADRESPONSE_SUCCESS:
      return action.payload;
    case BULKUPLOADRESPONSE_FAILURE:
      return state;
    default:
      return state;
  }
};

const bulkUploadFilterFileResponse = (state = null, action) => {
  switch (action.type) {
    case BULKUPLOADFILEFILTER_REQUEST:
      return state;
    case BULKUPLOADFILEFILTER_SUCCESS:
      return action.payload;
    case BULKUPLOADFILEFILTER_FAILURE:
      return state;
    default:
      return state;
  }
};

const bulkUploadRequest = (state = null, action) => {
  switch (action.type) {
    case BULKUPLOADREQUEST_REQUEST:
      return state;
    case BULKUPLOADREQUEST_SUCCESS:
      return action;
    case BULKUPLOADREQUEST_FAILURE:
      return action;
    default:
      return state;
  }
};

export default combineReducers({
  statusList,
  bulkUploadResponse,
  bulkUploadFilterFileResponse,
  bulkUploadRequest,
});
