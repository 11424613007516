export const ACTIVITY_REQUEST = 'ACTIVITY_REQUEST';
export const ACTIVITY_SUCCESS = 'ACTIVITY_SUCCESS';
export const ACTIVITY_FAILURE = 'ACTIVITY_FAILURE';
export const ACTIVITYCOUNT_REQUEST = 'ACTIVITYCOUNT_REQUEST';
export const ACTIVITYCOUNT_SUCCESS = 'ACTIVITYCOUNT_SUCCESS';
export const ACTIVITYCOUNT_FAILURE = 'ACTIVITYCOUNT_FAILURE';
export const ACTIVITYTYPE_REQUEST = 'ACTIVITYTYPE_REQUEST';
export const ACTIVITYTYPE_SUCCESS = 'ACTIVITYTYPE_SUCCESS';
export const ACTIVITYTYPE_FAILURE = 'ACTIVITYTYPE_FAILURE';
export const ACTIVITY_UPDATE_REQUEST = 'ACTIVITY_UPDATE_REQUEST';
export const ACTIVITY_UPDATE_SUCCESS = 'ACTIVITY_UPDATE_SUCCESS';
export const ACTIVITY_UPDATE_FAILURE = 'ACTIVITY_UPDATE_FAILURE';
