import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  Account_FAILURE,
  Account_REQUEST,
  Account_SUCCESS,
  AccountCount_FAILURE,
  AccountCount_REQUEST,
  AccountCount_SUCCESS,
  MYACCOUNT_REQUEST,
  MYACCOUNT_SUCCESS,
  MYACCOUNT_FAILURE,
  ACCOUNTUPDATE_REQUEST,
  ACCOUNTUPDATE_SUCCESS,
  ACCOUNTUPDATE_FAILURE,
  Account_PAN_REQUEST,
  Account_PAN_FAILURE,
  Account_PAN_SUCCESS,
  Account_GST_FAILURE,
  Account_GST_REQUEST,
  Account_GST_SUCCESS,
  Account_MOBILE_FAILURE,
  Account_MOBILE_REQUEST,
  Account_MOBILE_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
  FETCH_USER_FAILURE,
} from './constants';

export const getAccounts = (
  accessToken,
  pageNumber,
  pageSize,
  toDate,
  fromDate,
  state,
  district,
  name,
  AccountMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Account/GetAccountsPortal?Name=${name}&FromDate=${fromDate}&ToDate=${toDate}&State=${state}&District=${district}&PageNumber=${pageNumber}&PageSize=${pageSize}&AccountMCode=${
      AccountMCode ?? ''
    }`,
    method: 'GET',
    types: [Account_REQUEST, Account_SUCCESS, Account_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getAccountsCount = (
  accessToken,
  fromDate,
  toDate,
  name,
  state,
  district,
) => {
  return createAction({
    endpoint: `${API_URL}/Account/GetAccountsCount?Name=${name}&FromDate=${fromDate}&ToDate=${toDate}&State=${state}&District=${district}`,
    method: 'GET',
    types: [AccountCount_REQUEST, AccountCount_SUCCESS, AccountCount_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getMyAccounts = (accessToken, pageNumber, pageSize, name) => {
  return createAction({
    endpoint: `${API_URL}/Account/MyAccount?Name=${name}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    types: [MYACCOUNT_REQUEST, MYACCOUNT_SUCCESS, MYACCOUNT_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateAccount = (accessToken, payload) => {
  return createAction({
    endpoint: `${API_URL}/Account`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      ACCOUNTUPDATE_REQUEST,
      ACCOUNTUPDATE_SUCCESS,
      ACCOUNTUPDATE_FAILURE,
    ],
  });
};

export const getPanDetail = (accessToken, pan) => {
  return createAction({
    endpoint: `${API_URL}/Account/GetAccountsPortal?Pan=${pan}`,
    method: 'GET',
    types: [Account_PAN_REQUEST, Account_PAN_SUCCESS, Account_PAN_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getGstDetail = (accessToken, gst) => {
  return createAction({
    endpoint: `${API_URL}/Account/GetAccountsPortal?Gst=${gst}`,
    method: 'GET',
    types: [Account_GST_REQUEST, Account_GST_SUCCESS, Account_GST_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getMobileDetail = (accessToken, mobile) => {
  return createAction({
    endpoint: `${API_URL}/Account/GetAccountsPortal?Mobile=${mobile}`,
    method: 'GET',
    types: [
      Account_MOBILE_REQUEST,
      Account_MOBILE_SUCCESS,
      Account_MOBILE_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getUserDetails = (accessToken, id) => {
  return createAction({
    endpoint: `${API_URL}/User/GetById?id=${id}`,
    method: 'GET',
    types: [FETCH_USER_REQUEST, FETCH_USER_SUCCESS, FETCH_USER_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
