import React from 'react';
import styled from 'styled-components';
import {Layout, Row, Col, Button} from 'antd';
import COLORS from '../constants/style/colors';
import {LogoutOutlined} from '@ant-design/icons';
import {useDispatch, useSelector} from 'react-redux';
import {signOut} from '../modules/auth/action';
import {getSelfDetails} from '../modules/userDetails/selector';
import {clearStore} from '../modules/auth/forgotPassword/action';
const Header = () => {
  const dispatch = useDispatch();
  const user = useSelector(getSelfDetails);
  return (
    <StyledHeader
      style={{
        position: 'fixed',
        zIndex: 1,
        width: '100%',
        padding: '0 0 0 20px',
      }}>
      <Row>
        <Col
          span={20}
          style={{color: '#FFFFFF', fontWeight: 'Bold', fontSize: 'medium'}}>
          Welcome
          {user
            ? user.roles.length > 0
              ? user.roles.includes('Admin')
                ? ' ' + user.firstName + ': Admin'
                : ' ' + user.firstName
              : ' '
            : ' '}
        </Col>
        <Col span={4}>
          <Button
            className="login-form-button"
            htmlType="submit"
            onClick={() => {
              dispatch(signOut());
              dispatch(clearStore());
            }}
            style={{
              color: '#FFFFFF',
              background: 'transparent',
              border: '0px solid #fd560eb5',
              fontWeight: '550',
            }}
            type="text">
            <LogoutOutlined /> LogOut
          </Button>
        </Col>
      </Row>
    </StyledHeader>
  );
};

const StyledHeader = styled(Layout.Header)`
  background: ${COLORS.brandColor.current};
`;

export default Header;
