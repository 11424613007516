import {createSelector} from 'reselect';

export const getAccountMaster = ({accountmaster}) => accountmaster;

export const getAccounttTypesData = createSelector(
  getAccountMaster,
  (accountmaster) => accountmaster?.accountMaster?.accountTypes,
);

export const getCustomerCayegoriesData = createSelector(
  getAccountMaster,
  (accountmaster) => accountmaster?.accountMaster?.customerCategories,
);

export const getApplicationTypesData = createSelector(
  getAccountMaster,
  (accountmaster) => accountmaster?.accountMaster?.applicationTypes,
);
