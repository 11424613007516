import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  PREFEREDLANGUAGE_FAILURE,
  PREFEREDLANGUAGE_REQUEST,
  PREFEREDLANGUAGE_SUCCESS,
} from './constants';

export const getPreferedLanguages = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/ContactsMaster/GetPreferedLanguages`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      PREFEREDLANGUAGE_REQUEST,
      PREFEREDLANGUAGE_SUCCESS,
      PREFEREDLANGUAGE_FAILURE,
    ],
  });
};
