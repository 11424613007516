import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  ACTIVITY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ACTIVITYCOUNT_FAILURE,
  ACTIVITYCOUNT_SUCCESS,
  ACTIVITYCOUNT_REQUEST,
  ACTIVITYTYPE_FAILURE,
  ACTIVITYTYPE_REQUEST,
  ACTIVITYTYPE_SUCCESS,
  ACTIVITY_UPDATE_FAILURE,
  ACTIVITY_UPDATE_SUCCESS,
  ACTIVITY_UPDATE_REQUEST,
} from './constants';

export const getOrgActivities = (
  accessToken,
  pageNumber,
  pageSize,
  activityTypeCode,
  status,
  zoneCode,
  branchCode,
  dealerCode,
  employeeCode,
  fromDate,
  toDate,
  account,
  accountMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Activity/MyOrgActivities?ActivityTypeCode=${activityTypeCode}&Status=${status}&ZoneCode=${zoneCode}&BranchCode=${branchCode}&DealerCode=${dealerCode}&EmployeeCode=${employeeCode}&PageNumber=${pageNumber}&PageSize=${pageSize}&PlannedFromDate=${fromDate}&PlannedToDate=${toDate}&Account=${account}&AccountMCode=${accountMCode}`,
    method: 'GET',
    types: [ACTIVITY_REQUEST, ACTIVITY_SUCCESS, ACTIVITY_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOrgActivitiesCount = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Activity/MyOrgActivitiesCount`,
    method: 'GET',
    types: [
      ACTIVITYCOUNT_REQUEST,
      ACTIVITYCOUNT_SUCCESS,
      ACTIVITYCOUNT_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getActivityType = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/ActivityMaster/GetActivityMasterPortal`,
    method: 'GET',
    types: [ACTIVITYTYPE_REQUEST, ACTIVITYTYPE_SUCCESS, ACTIVITYTYPE_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateActivity = (accessToken, payload) => {
  return createAction({
    endpoint: `${API_URL}/Activity/Update`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      ACTIVITY_UPDATE_REQUEST,
      ACTIVITY_UPDATE_SUCCESS,
      ACTIVITY_UPDATE_FAILURE,
    ],
  });
};
