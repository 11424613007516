import React, {useEffect, useState} from 'react';
import {
  Modal,
  Form,
  Collapse,
  Input,
  Radio,
  Button,
  Select,
  DatePicker,
  Space,
  Layout,
  message,
  TimePicker,
} from 'antd';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import COLORS from '../../constants/style/colors';
import styled from 'styled-components';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getActivityDropdownValues} from '../../modules/dropdowns/action';
import {getActivityDropdownsData} from '../../modules/dropdowns/selector';
import {updateActivity} from '../../modules/activity/action';
import {updateActivityData} from '../../modules/activity/selectors';

const {Panel} = Collapse;
const {Option} = Select;

const ActivityModalEditForm = ({
  visible,
  onCancel,
  onCreate,
  onSubmit,
  data,
}) => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const activityRequestType = useSelector(updateActivityData);

  const disableFields = data?.activityStatusName === 'Closed' ? true : false;
  const [form, setForm] = useState({
    activityTypecode: data?.activityTypecode ? data?.activityTypecode : '',
    activityStatusName: data.activityStatusName ? data?.activityStatusName : '',
    plannedDateTime: data ? data.plannedDateTime : '',
    plannedTime: moment(data?.plannedDateTime).format('hh:mm A'),
    closingDateTime:
      data && data?.closingDateTime
        ? moment(data.closingDateTime)._d
        : moment(new Date())._d,
    closingTime: data?.closingDateTime
      ? data?.closingDateTime?.split('T')[1]
      : data?.plannedDateTime?.split('T')[1],
    remarks: data?.remarks ? data?.remarks : '',
  });

  useEffect(() => {}, [form]);

  useEffect(() => {
    if (visible) {
      setForm({
        activityTypecode: data?.activityTypecode ? data?.activityTypecode : '',
        activityStatusName: data.activityStatusName
          ? data?.activityStatusName
          : '',
        plannedDateTime: data ? data.plannedDateTime : '',
        plannedTime: moment(data?.plannedDateTime).format('hh:mm A'),
        closingDateTime:
          data && data?.closingDateTime
            ? moment(data.closingDateTime)._d
            : moment(new Date())._d,
        closingTime: data?.closingDateTime
          ? data?.closingDateTime?.split('T')[1]
          : data?.plannedDateTime.split('T')[1],
        remarks: data?.remarks ? data?.remarks : '',
      });
    }
  }, [visible, data]);

  const handleFormFieldChange = (value, name) => {
    switch (name) {
      default:
        if (
          value?.target?.type
            ? value?.target?.type === 'text' || 'textarea'
            : false
        ) {
          setForm({
            ...form,
            [name]: value?.target?.value,
          });
          break;
        } else
          setForm({
            ...form,
            [name]: value,
          });
        break;
    }
  };

  const handleFormSubmit = () => {
    let payload = null;
    let closeTimeStr = form?.closingTime?._d + '';
    let closeDateTime =
      moment.utc(form.closingDateTime).format().split('T')[0] +
      'T' +
      closeTimeStr.split(' ')[4];
    payload = {
      activityId: data?.activityId,
      accountMcode: data?.accountMcode,
      accountName: data?.accountName,
      contactMcode: data?.contactMcode,
      opportunityMCode: data?.opportunityMCode,
      statusCode: form.activityStatusName,
      activityStatusName: form.activityStatusName,
      plannedDateTime: data?.plannedDateTime,
      remarks: form.remarks,
      activityTypecode: form.activityTypecode,
      closingDateTime: closeDateTime,
    };

    if (form.activityStatusName === 'Closed') {
      if (moment(data.plannedDateTime).isAfter(closeDateTime)) {
        message.error(
          {
            content: 'Select Closing Date after Planned Date !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      } else {
        if (form.activityTypecode && form.closingDateTime && form.closingTime) {
          dispatch(updateActivity(authUser.accessToken, payload));
        } else {
          message.error(
            {
              content: 'Please fill all mandatory fields !',
              className: 'custom-class',
              style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
            },
            5,
          );
        }
      }
    } else {
      payload = {...payload, closingDateTime: data?.plannedDateTime};
      if (form.activityTypecode) {
        dispatch(updateActivity(authUser.accessToken, payload));
      } else {
        message.error(
          {
            content: 'Please fill all mandatory fields !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    }
  };

  useEffect(() => {
    if (visible) {
      if (activityRequestType?.type === 'ACTIVITY_UPDATE_SUCCESS') {
        message.success(
          {
            content: 'Activity Updated Sucessfully !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
        onSubmit();
        onCancel();
      } else if (activityRequestType?.type === 'ACTIVITY_UPDATE_FAILURE') {
        message.error(
          {
            content: 'Activity Update Failed !',
            className: 'custom-class',
            style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
          },
          5,
        );
      }
    }
  }, [activityRequestType]);

  useEffect(() => {
    dispatch(getActivityDropdownValues(authUser.accessToken));
  }, [dispatch, authUser.accessToken]);
  const dropdownDataObj = useSelector(getActivityDropdownsData);
  const dropdownData = dropdownDataObj?.data;

  const disabledDate = (current) => {
    return current.valueOf() > Date.now();
  };

  return (
    <Modal
      destroyOnClose={true}
      footer={null}
      onCancel={onCancel}
      title="Opportunity Details"
      visible={visible}
      width={1000}>
      <StyledHeader
        style={{
          top: 0,
          height: '50pt',
        }}>
        <Button
          onClick={handleFormSubmit}
          shape="round"
          size={10}
          style={{
            backgroundColor: COLORS.brandColor.current,
            color: 'white',
          }}>
          <b>SUBMIT</b>
        </Button>
      </StyledHeader>
      <Form>
        <Collapse defaultActiveKey={['1']}>
          <Panel header="Account Details" key="1">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader"> Account</td>

                  <td className="tdHeader"> Contact</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data?.accountName} />
                    {/* <AutoComplete
                      onChange={(data) =>
                        handleFormFieldChange(data, 'accountName')
                      }
                      onSelect={(value) =>
                        handleFormFieldChange(value, 'accountName')
                      }
                      options={accountNameOptions}
                      style={{width: '100%', marginRight: '10px'}}
                      value={form.accountName}
                    /> */}
                  </td>
                  <td>
                    <Input disabled value={data?.contactNo} />
                    {/* <Select
                      allowClear={true}
                      defaultValue={form.contactMcode}
                      onChange={(value) =>
                        handleFormFieldChange(value, 'contactMcode')
                      }
                      style={{width: '100%'}}>
                      {contactOptions?.map((opt) => {
                        return <Option value={opt?.value}>{opt?.label}</Option>;
                      })}
                    </Select> */}
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Opportunity</td>
                </tr>
                <tr>
                  <td>
                    <Input disabled value={data?.opportunityMCode} />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
          <Panel header="Activity Details" key="2">
            <table className="tableServiceDetails" width="100%">
              <thead></thead>
              <tbody>
                <tr>
                  <td className="tdHeader">
                    {' '}
                    Activity Type <span style={{color: 'red'}}> *</span>
                  </td>
                  <td className="tdHeader">Activity Status</td>
                </tr>
                <tr>
                  <td>
                    <Select
                      allowClear={true}
                      defaultValue={form.activityTypecode}
                      disabled={disableFields}
                      onChange={(data) =>
                        handleFormFieldChange(data, 'activityTypecode')
                      }
                      placeholder="Select Activity Type"
                      style={{width: '100%'}}>
                      {dropdownData?.activityTypes.map((opt) => {
                        return <Option value={opt?.code}>{opt?.name}</Option>;
                      })}
                    </Select>
                  </td>
                  <td className="tdHeader">
                    <Radio.Group
                      defaultValue={form.activityStatusName}
                      disabled={disableFields}
                      onChange={(data) =>
                        handleFormFieldChange(data, 'activityStatusName')
                      }>
                      {dropdownData?.activityStatuses.map((opt) => {
                        return <Radio value={opt?.code}>{opt?.name}</Radio>;
                      })}
                    </Radio.Group>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader">Planned Date</td>
                  <td className="tdHeader">Planned Time</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled
                      value={moment(form.plannedDateTime).format('DD/MM/YYYY')}
                    />
                  </td>
                  <td>
                    <Input disabled value={form.plannedTime} />
                  </td>
                </tr>
                <tr hidden={form.activityStatusName === 'Open' ? true : false}>
                  <td className="tdHeader">
                    Closing Date <span style={{color: 'red'}}> *</span>
                  </td>
                  <td className="tdHeader">
                    Closing Time <span style={{color: 'red'}}> *</span>
                  </td>
                </tr>
                <tr hidden={form.activityStatusName === 'Open' ? true : false}>
                  <td>
                    <Space direction="vertical">
                      <DatePicker
                        clearIcon={null}
                        defaultValue={moment(
                          form.closingDateTime,
                          'DD/MM/YYYY',
                        )}
                        disabled={disableFields}
                        disabledDate={(current) => disabledDate(current)}
                        format={'DD/MM/YYYY'}
                        onChange={(data) =>
                          handleFormFieldChange(data, 'closingDateTime')
                        }
                        style={{width: '100%', marginRight: '10px'}}
                        value={moment(form.closingDateTime, 'DD/MM/YYYY')}
                      />
                    </Space>
                  </td>
                  <td>
                    <Space direction="vertical">
                      <TimePicker
                        defaultValue={moment(form.closingTime, 'HH:mm:ss')}
                        disabled={disableFields}
                        format="h:mm a"
                        onChange={(data) =>
                          handleFormFieldChange(data, 'closingTime')
                        }
                        use12Hours
                      />
                    </Space>
                  </td>
                </tr>
                <tr>
                  <td className="tdHeader"> Remarks</td>
                </tr>
                <tr>
                  <td>
                    <Input
                      disabled={disableFields}
                      onChange={(data) =>
                        handleFormFieldChange(data, 'remarks')
                      }
                      value={form.remarks}
                    />
                  </td>
                </tr>
              </tbody>
            </table>
          </Panel>
        </Collapse>
      </Form>
    </Modal>
  );
};

const StyledHeader = styled(Layout.Header)`
  background: ${COLORS.textColor.lightGray};
`;

export default ActivityModalEditForm;
