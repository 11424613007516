import {queryString} from 'object-query-string';

/**
 * Prepare query string from simple object
 *
 * @param {object} obj
 * @return {string}
 */
export default function getQueryString(obj) {
  const cleanObj = {};
  for (const key in obj) {
    if (obj.hasOwnProperty(key) && obj[key]) {
      if (Array.isArray(obj[key]) && obj[key].length > 0) {
        cleanObj[key] = obj[key];
      } else if (obj[key]) {
        cleanObj[key] = obj[key];
      }
    }
  }

  return queryString(cleanObj);
}
