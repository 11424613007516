import {createSelector} from 'reselect';
import {getAuth} from '../selectors';

export const getAuthUser = createSelector(getAuth, (auth) => auth.user);

export const getAuthUserHasFetched = createSelector(
  getAuthUser,
  (user) => user.hasFetched,
);

export const getAuthUserData = createSelector(getAuthUser, (user) => user.data);

export const getAuthUserAccessToken = createSelector(getAuthUserData, (user) =>
  user && user.accessToken ? user.accessToken : null,
);
