import React, {useEffect, useState} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Pagination, Button, Table, Modal} from 'antd';
import {
  getOpportunities,
  getDownloadOpportunities,
  getOpportunitiesCount,
  getOpportunityByMCode,
} from '../../modules/opportunities/action';
import {useDispatch, useSelector} from 'react-redux';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {
  getOpportunitiesData,
  isFetchingOpportunitiesData,
  getDownloadOpportunitiesData,
  getOpportunitieCountsData,
  isFetchingDownloadOpportunitiesData,
  getOpportunityByMCodeData,
  isFetchingOpportunityByMCodeData,
} from '../../modules/opportunities/selectors';
import {opportunitiesColumns} from '../../data/opportunitiesColumns';
import OpportunityModalEditForm from '../common/OpportunityModalEdit';
import {opportunityDateType} from '../../data/opportunityDateType';
import DataFilterBox from '../common/DataFilterBox';
import moment from 'moment';
import {Parser} from 'json2csv';
import UploadDownloadbox from '../common/UploadDownloadBox';

export const Opportunities = () => {
  const dispatch = useDispatch();
  const authUser = useSelector(getAuthUserData);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const opportunitiesData = useSelector(getOpportunitiesData);
  const isFetchingData = useSelector(isFetchingOpportunitiesData);
  const downloadData = useSelector(getDownloadOpportunitiesData);
  const isFetchingDownloadRequest = useSelector(
    isFetchingDownloadOpportunitiesData,
  );
  const opportunityData = useSelector(getOpportunityByMCodeData);
  const isFetchingOpportunityByMCode = useSelector(
    isFetchingOpportunityByMCodeData,
  );
  const [isDownloadDataDumpClicked, setIsDownloadDataDumpClicked] = useState(
    false,
  );
  const [opportunityDateTypeFilter, setOpportunityDateTypeFilter] = useState(
    'createdOn',
  );

  const optyDataCount = useSelector(getOpportunitieCountsData);
  const [fromDate, setFromDate] = useState(
    moment(new Date()).add(-7, 'day').toDate(),
  );
  const [toDate, setToDate] = useState(moment(new Date()));

  const onOpportunityDateTypeChanged = (value) => {
    setOpportunityDateTypeFilter(value);
  };
  const fromDateChanged = (value) => {
    setFromDate(value._d);
  };
  const toDateChanged = (value) => {
    setToDate(value._d);
  };
  const onFilterDataClick = () => {
    getFilteredData();
  };

  useEffect(() => {
    dispatch(
      getOpportunitiesCount(
        authUser.accessToken,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        'FromDate',
        'ToDate',
        '',
        '',
        '',
      ),
    );
  }, [authUser.accessToken, dispatch]);

  const onResetDataClick = () => {
    setFromDate(moment(new Date()).add(-7, 'day').toDate());
    setToDate(moment(new Date()));
    setOpportunityDateTypeFilter('createdOn');
    setPageSizeNew(10);
    pageChanged(1, 10);
    setFilteredName('');
    setFilteredAccountName('');
    setFilteredAccountMCode('');
    dispatch(
      getOpportunitiesCount(
        authUser.accessToken,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        'FromDate',
        'ToDate',
      ),
    );
    dispatch(
      getOpportunities(
        authUser.accessToken,
        1,
        pageSizeNew,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        'FromDate',
        'ToDate',
        '',
        '',
        '',
      ),
    );
  };

  useEffect(() => {
    if (
      isDownloadDataDumpClicked &&
      !isFetchingDownloadRequest &&
      downloadData !== null
    ) {
      Modal.success({
        title: downloadData.success
          ? 'Download request submitted successfully!!'
          : 'Download request failed',
        content: (
          <div>
            <p></p>
            <p>
              {downloadData.success
                ? 'You will receive an email shortly with the opportunity list'
                : 'Invalid Email Address or problem in sending the email !!'}
            </p>
          </div>
        ),
        onOk() {},
      });
      setIsDownloadDataDumpClicked(!isDownloadDataDumpClicked);
    }
  }, [isDownloadDataDumpClicked, downloadData, isFetchingDownloadRequest]);
  const onDownloadDataDumpClick = () => {
    dispatch(
      getDownloadOpportunities(
        authUser.accessToken,
        pageNumber,
        10,
        moment(toDate).format('YYYY-MM-DD'),
        moment(fromDate).format('YYYY-MM-DD'),
        opportunityDateTypeFilter.includes('createdOn')
          ? 'FromDate'
          : 'FromClosingDate',
        opportunityDateTypeFilter.includes('createdOn')
          ? 'ToDate'
          : 'ToClosingDate',
        filteredName !== '' ? filteredName : '',
        filteredAccountName !== '' ? filteredAccountName : '',
        filteredAccountMCode !== '' ? filteredAccountMCode : '',
      ),
    );
    setIsDownloadDataDumpClicked(!isDownloadDataDumpClicked);
  };

  const pageChanged = (page, pageSize) => {
    setPageNumber(page);
    setConfig((prevState) => ({
      pagination: {
        ...prevState.pagination,
        defaultPageSize: pageSize,
        pageSize: pageSize,
      },
    }));
    setPageSizeNew(pageSize);
    dispatch(
      getOpportunities(
        authUser.accessToken,
        page === 0 ? 1 : page,
        pageSize,
        '',
        '',
        'FromDate',
        'ToDate',
        filteredName !== '' ? filteredName : '',
        filteredAccountName !== '' ? filteredAccountName : '',
        filteredAccountMCode !== '' ? filteredAccountMCode : '',
      ),
    );
  };

  const getFilteredData = () => {
    if (filteredName || filteredAccountName || filteredAccountMCode) {
      dispatch(
        getOpportunities(
          authUser.accessToken,
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          '',
          '',
          'FromDate',
          'ToDate',
          filteredName !== '' ? filteredName : '',
          filteredAccountName !== '' ? filteredAccountName : '',
          filteredAccountMCode !== '' ? filteredAccountMCode : '',
        ),
      );
      dispatch(
        getOpportunitiesCount(
          authUser.accessToken,
          moment(toDate).format('YYYY-MM-DD'),
          moment(fromDate).format('YYYY-MM-DD'),
          opportunityDateTypeFilter.includes('createdOn')
            ? 'FromDate'
            : 'FromClosingDate',
          opportunityDateTypeFilter.includes('createdOn')
            ? 'ToDate'
            : 'ToClosingDate',
        ),
      );
    } else {
      dispatch(
        getOpportunities(
          authUser.accessToken,
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          moment(toDate).format('YYYY-MM-DD'),
          moment(fromDate).format('YYYY-MM-DD'),
          opportunityDateTypeFilter.includes('createdOn')
            ? 'FromDate'
            : 'FromClosingDate',
          opportunityDateTypeFilter.includes('createdOn')
            ? 'ToDate'
            : 'ToClosingDate',
          '',
          '',
          '',
        ),
      );
      dispatch(
        getOpportunitiesCount(
          authUser.accessToken,
          moment(toDate).format('YYYY-MM-DD'),
          moment(fromDate).format('YYYY-MM-DD'),
          opportunityDateTypeFilter.includes('createdOn')
            ? 'FromDate'
            : 'FromClosingDate',
          opportunityDateTypeFilter.includes('createdOn')
            ? 'ToDate'
            : 'ToClosingDate',
        ),
      );
    }
  };

  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const [filteredName, setFilteredName] = useState('');
  const [filteredAccountName, setFilteredAccountName] = useState('');
  const [filteredAccountMCode, setFilteredAccountMCode] = useState('');

  useEffect(() => {
    dispatch(
      getOpportunities(
        authUser.accessToken,
        1,
        10,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        'FromDate',
        'ToDate',
        '',
        '',
        '',
      ),
    );
  }, [dispatch, authUser.accessToken]);

  const onUserNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredName(target.value.trim());
  };
  const onAccountNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredAccountName(target.value.trim());
  };
  const onAccountMCodeChange = ({target}) => {
    setPageNumber(1);
    setFilteredAccountMCode(target.value.trim());
  };

  const items = [
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: 'Opportunity Date Type',
      fieldType: 'Select',
      options: opportunityDateType,
      onChange: onOpportunityDateTypeChanged,
      value: opportunityDateTypeFilter,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromDateChanged,
      value: fromDate,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toDateChanged,
      value: toDate,
    },
    {
      fieldId: 4,
      fieldName: 'Filter data',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterDataClick,
    },

    {
      fieldId: 5,
      fieldName: 'Reset',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onResetDataClick,
    },
    {
      fieldId: 6,
      fieldName: 'Download Data Dump',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onDownloadDataDumpClick,
    },
    {
      fieldId: 7,
      fieldName: '',
      fieldTitle: '',
      fieldMargin: '18px',
      fieldType: 'LabelForOpp',
    },
    {
      fieldId: 8,
      fieldName: 'Filter By Opportunity MCode',
      fieldTitle: '',
      fieldMargin: '20px',
      fieldType: 'Label',
    },
    {
      fieldId: 9,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Code',
      onChange: onUserNameChange,
      value: filteredName,
    },
    {
      fieldId: 8,
      fieldName: 'Account Name',
      fieldTitle: '',
      fieldMargin: '20px',
      fieldType: 'Label',
    },
    {
      fieldId: 9,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Name',
      onChange: onAccountNameChange,
      value: filteredAccountName,
    },
    {
      fieldId: 8,
      fieldName: 'Account MCode',
      fieldTitle: '',
      fieldMargin: '20px',
      fieldType: 'Label',
    },
    {
      fieldId: 9,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Code',
      onChange: onAccountMCodeChange,
      value: filteredAccountMCode,
    },
  ];
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const getDetails = (e, record) => {
    setVisible(!visible);
    setModalData(record);
  };
  const handleCreate = () => {};
  const updatedOpptyCol = [
    ...opportunitiesColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];
  const handleModalSubmit = () => {
    dispatch(
      getOpportunities(
        authUser.accessToken,
        1,
        10,
        moment().format('YYYY-MM-DD'),
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        'FromDate',
        'ToDate',
        filteredName !== '' ? filteredName : '',
        filteredAccountName !== '' ? filteredAccountName : '',
        filteredAccountMCode !== '' ? filteredAccountMCode : '',
      ),
    );
  };
  return (
    <MainLayout>
      <DataFilterBox items={items} title="Opportunities Filter"></DataFilterBox>
      <div className="contentLayout">
        <span className="contentHeader">Opportunities</span>
        <Pagination
          defaultCurrent={1}
          current={pageNumber}
          onChange={pageChanged}
          style={{float: 'right'}}
          total={optyDataCount !== 0 ? optyDataCount : 1}
        />
        <OpportunityModalEditForm
          data={modalData}
          onCancel={() => setVisible(false)}
          onCreate={() => handleCreate()}
          onSubmit={() => handleModalSubmit()}
          visible={visible}
        />
        <Table
          columns={updatedOpptyCol}
          dataSource={
            opportunitiesData
              ? opportunitiesData.data == null
                ? []
                : opportunitiesData.data.opportunities
              : []
          }
          loading={isFetchingData || isFetchingDownloadRequest}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '20px'}}
        />
      </div>
    </MainLayout>
  );
};
