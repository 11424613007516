export default [
  {key: 'All', value: 'All'},
  {key: 'Andhra Pradesh', value: 'Andhra Pradesh'},
  {key: 'Arunachal Pradesh', value: 'Arunachal Pradesh'},
  {key: 'Assam', value: 'Assam'},
  {key: 'Bihar', value: 'Bihar'},
  {key: 'Chandigarh (UT)', value: 'Chandigarh (UT)'},
  {key: 'Chhattisgarh', value: 'Chhattisgarh'},
  {key: 'Dadra and Nagar Haveli (UT)', value: 'Dadra and Nagar Haveli (UT)'},
  {key: 'Daman and Diu (UT)', value: 'Daman and Diu (UT)'},
  {key: 'Delhi (NCT)', value: 'Delhi (NCT)'},
  {key: 'Goa', value: 'Goa'},
  {key: 'Gujarat', value: 'Gujarat'},
  {key: 'Haryana', value: 'Haryana'},
  {key: 'Himachal Pradesh', value: 'Himachal Pradesh'},
  {key: 'Jammu and Kashmir', value: 'Jammu and Kashmir'},
  {key: 'Jharkhand', value: 'Jharkhand'},
  {key: 'Karnataka', value: 'Karnataka'},
  {key: 'Kerala', value: 'Kerala'},
  {key: 'Lakshadweep (UT)', value: 'Lakshadweep (UT)'},
  {key: 'Madhya Pradesh', value: 'Madhya Pradesh'},
  {key: 'Maharashtra', value: 'Maharashtra'},
  {key: 'Manipur', value: 'Manipur'},
  {key: 'Meghalaya', value: 'Meghalaya'},
  {key: 'Mizoram', value: 'Mizoram'},
  {key: 'Nagaland', value: 'Nagaland'},
  {key: 'Odisha', value: 'Odisha'},
  {key: 'Puducherry (UT)', value: 'Puducherry (UT)'},
  {key: 'Punjab', value: 'Punjab'},
  {key: 'Rajasthan', value: 'Rajasthan'},
  {key: 'Sikkim', value: 'Sikkim'},
  {key: 'Tamil Nadu', value: 'Tamil Nadu'},
  {key: 'Telangana', value: 'Telangana'},
  {key: 'Tripura', value: 'Tripura'},
  {key: 'Uttarakhand', value: 'Uttarakhand'},
  {key: 'Uttar Pradesh', value: 'Uttar Pradesh'},
  {key: 'West Bengal', value: 'West Bengal'},
];
