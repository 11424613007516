import {
  ACCOUNTMASTER_REQUEST,
  ACCOUNTMASTER_SUCCESS,
  ACCOUNTMASTER_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const accountMaster = (state = null, action) => {
  switch (action.type) {
    case ACCOUNTMASTER_REQUEST:
      return state;
    case ACCOUNTMASTER_SUCCESS:
      return action.payload;
    case ACCOUNTMASTER_FAILURE:
      return state;
    default:
      return state;
  }
};
export default combineReducers({
  accountMaster,
});
