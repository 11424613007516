import {date} from 'yup';
import React from 'react';
import moment from 'moment';

export const opportunitiesColumns = [
  {
    title: 'MCode',
    dataIndex: 'mCode',
    key: 'id',
    sorter: {
      compare: (a, b) => a.mCode / b.mCode,
    },
  },
  {
    title: 'Dealer Name',
    dataIndex: 'dealerName',
    key: 'id',
    sorter: (a, b) => {
      a = a.dealerName === null ? '' : a.dealerName;
      b = b.dealerName === null ? '' : b.dealerName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Opportunity Model',
    dataIndex: ['modelName'],
    key: 'id',
    sorter: (a, b) => {
      a = a.modelName === null ? '' : a.modelName;
      b = b.modelName === null ? '' : b.modelName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Opportunity Status',
    dataIndex: ['opportunityStatus'],
    key: 'id',
    sorter: (a, b) => {
      a = a.opportunityStatus === null ? '' : a.opportunityStatus;
      b = b.opportunityStatus === null ? '' : b.opportunityStatus;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Opportunity Stage',
    dataIndex: ['opportunityStages', 'opportunityStage'],
    key: 'id',
    sorter: (a, b) => {
      a =
        a.opportunityStages === null
          ? ''
          : a.opportunityStages.opportunityStage === null
          ? ''
          : a.opportunityStages.opportunityStage;
      b =
        b.opportunityStages === null
          ? ''
          : b.opportunityStages.opportunityStage === null
          ? ''
          : b.opportunityStages.opportunityStage;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Account Name',
    dataIndex: ['accountName'],
    key: 'id',
    sorter: (a, b) => {
      a = a.accountName === null ? '' : a.accountName;
      b = b.accountName === null ? '' : b.accountName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Account Mcode',
    dataIndex: ['accountMCode'],
    key: 'id',
    sorter: (a, b) => {
      a = a.accountMCode === null ? '' : a.accountMCode;
      b = b.accountMCode === null ? '' : b.accountMCode;
      return a.localeCompare(b);
    },
  },
  {
    title: 'District',
    dataIndex: ['contactDistrictName'],
    key: 'id',
  },
  {
    title: 'Contact Number',
    dataIndex: ['contactNumber'],
    key: 'id',
    sorter: (a, b) => {
      a = a.accountName === null ? '' : a.accountName;
      b = b.accountName === null ? '' : b.accountName;
      return a.localeCompare(b);
    },
  },
  {
    title: 'Variant Name',
    dataIndex: ['variant'],
    key: 'id',
  },
  {
    title: 'Estimated Closing Date',
    dataIndex: 'estimatedClosingDate',
    key: 'id',
    render: (estimatedClosingDate) => {
      return estimatedClosingDate ? (
        <p>{moment(estimatedClosingDate).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) =>
        moment(a.estimatedClosingDate) / moment(b.estimatedClosingDate),
    },
  },
  {
    title: 'Created Date',
    dataIndex: 'createdOn',
    key: 'id',
    render: (createdOn) => {
      return createdOn ? (
        <p>{moment(createdOn).format('DD/MM/YYYY hh:mm A')}</p>
      ) : (
        <p>-</p>
      );
    },
    sorter: {
      compare: (a, b) => moment(a.createdOn) / moment(b.createdOn),
    },
  },
];
