import {
  Contacts_FAILURE,
  Contacts_REQUEST,
  Contacts_SUCCESS,
  ContactsCount_FAILURE,
  ContactsCount_SUCCESS,
  ContactsCount_REQUEST,
  CONTACTUPDATE_REQUEST,
  CONTACTUPDATE_SUCCESS,
  CONTACTUPDATE_FAILURE,
  ContactsByMCode_REQUEST,
  ContactsByMCode_FAILURE,
  ContactsByMCode_SUCCESS,
  Financier_Contacts_REQUEST,
  Financier_Contacts_FAILURE,
  Financier_Contacts_SUCCESS,
} from './constants';
import {combineReducers} from 'redux';

const isFetchingContacts = (state = false, action) => {
  switch (action.type) {
    case Contacts_REQUEST:
      return true;
    case Contacts_SUCCESS:
    case Contacts_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataContacts = (state = null, action) => {
  switch (action.type) {
    case Contacts_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureContacts = (state = null, action) => {
  switch (action.type) {
    case Contacts_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasContactsFetched = (state = false, action) => {
  switch (action.type) {
    case Contacts_REQUEST:
      return false;
    case Contacts_SUCCESS:
    case Contacts_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingContactsCount = (state = false, action) => {
  switch (action.type) {
    case ContactsCount_REQUEST:
      return true;
    case ContactsCount_SUCCESS:
    case ContactsCount_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataContactsCount = (state = null, action) => {
  switch (action.type) {
    case ContactsCount_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureContactsCount = (state = null, action) => {
  switch (action.type) {
    case ContactsCount_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasContactsCountFetched = (state = false, action) => {
  switch (action.type) {
    case ContactsCount_REQUEST:
      return false;
    case ContactsCount_SUCCESS:
    case ContactsCount_FAILURE:
      return true;
    default:
      return state;
  }
};

const updateContactData = (state = null, action) => {
  switch (action.type) {
    case CONTACTUPDATE_REQUEST:
      return state;
    case CONTACTUPDATE_SUCCESS:
      return action;
    case CONTACTUPDATE_FAILURE:
      return state;
    default:
      return state;
  }
};

const isFetchingContactsByMCode = (state = false, action) => {
  switch (action.type) {
    case ContactsByMCode_REQUEST:
      return true;
    case ContactsByMCode_SUCCESS:
    case ContactsByMCode_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataContactsByMCode = (state = null, action) => {
  switch (action.type) {
    case ContactsByMCode_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureContactsByMCode = (state = null, action) => {
  switch (action.type) {
    case ContactsByMCode_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasContactsByMCodeFetched = (state = false, action) => {
  switch (action.type) {
    case ContactsByMCode_REQUEST:
      return false;
    case ContactsByMCode_SUCCESS:
    case ContactsByMCode_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingFinancierContactsByMCode = (state = false, action) => {
  switch (action.type) {
    case Financier_Contacts_REQUEST:
      return true;
    case Financier_Contacts_SUCCESS:
    case Financier_Contacts_FAILURE:
      return false;
    default:
      return state;
  }
};

const dataFinancierContactsByMCode = (state = null, action) => {
  switch (action.type) {
    case Financier_Contacts_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureFinancierContactsByMCode = (state = null, action) => {
  switch (action.type) {
    case Financier_Contacts_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFinancierContactsByMCodeFetched = (state = false, action) => {
  switch (action.type) {
    case Financier_Contacts_REQUEST:
      return false;
    case Financier_Contacts_SUCCESS:
    case Financier_Contacts_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingContacts,
  dataContacts,
  failureContacts,
  hasContactsFetched,
  isFetchingContactsCount,
  dataContactsCount,
  failureContactsCount,
  hasContactsCountFetched,
  updateContactData,
  isFetchingContactsByMCode,
  dataContactsByMCode,
  failureContactsByMCode,
  hasContactsByMCodeFetched,
  isFetchingFinancierContactsByMCode,
  dataFinancierContactsByMCode,
  failureFinancierContactsByMCode,
  hasFinancierContactsByMCodeFetched,
});
