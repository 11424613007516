import {combineReducers} from 'redux';
import {
  FORGOT_PASSWORD_VERIFICATION_CODE_SUCCESS,
  FORGOT_PASSWORD_VERIFICATION_CODE_FAILURE,
} from './constants';

const isForgotPasswordSendCodeSuccess = (state = false, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_VERIFICATION_CODE_SUCCESS:
      return true;
    case FORGOT_PASSWORD_VERIFICATION_CODE_FAILURE:
      return false;
    default:
      return state;
  }
};

const userInfo = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_VERIFICATION_CODE_SUCCESS:
      return action.user;
    default:
      return state;
  }
};

const isForgotPasswordSendCodeError = (state = null, action) => {
  switch (action.type) {
    case FORGOT_PASSWORD_VERIFICATION_CODE_FAILURE:
      return action;
    default:
      return state;
  }
};

export default combineReducers({
  isForgotPasswordSendCodeSuccess,
  userInfo,
  isForgotPasswordSendCodeError,
});
