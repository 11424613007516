import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  Contacts_FAILURE,
  Contacts_REQUEST,
  Contacts_SUCCESS,
  ContactsCount_FAILURE,
  ContactsCount_SUCCESS,
  ContactsCount_REQUEST,
  CONTACTUPDATE_REQUEST,
  CONTACTUPDATE_SUCCESS,
  CONTACTUPDATE_FAILURE,
  ContactsByMCode_REQUEST,
  ContactsByMCode_FAILURE,
  ContactsByMCode_SUCCESS,
  Financier_Contacts_REQUEST,
  Financier_Contacts_FAILURE,
  Financier_Contacts_SUCCESS,
} from './constants';

export const getContacts = (
  pageNumber,
  pageSize,
  fromDate,
  toDate,
  state,
  district,
  accessToken,
  firstName,
  lastName,
  ContactMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Contacts?FirstName=${firstName}&LastName=${lastName}&FromDate=${fromDate}&ToDate=${toDate}&State=${state}&District=${district}&PageNumber=${pageNumber}&PageSize=${pageSize}&ContactMCode=${
      ContactMCode ?? ''
    }`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [Contacts_REQUEST, Contacts_SUCCESS, Contacts_FAILURE],
  });
};
export const getContactsCount = (
  accessToken,
  fromDate,
  toDate,
  firstName,
  lastName,
  state,
  district,
) => {
  return createAction({
    endpoint: `${API_URL}/Contacts/GetContactsCount?FirstName=${firstName}&LastName=${lastName}&FromDate=${fromDate}&ToDate=${toDate}&State=${state}&District=${district}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      ContactsCount_REQUEST,
      ContactsCount_SUCCESS,
      ContactsCount_FAILURE,
    ],
  });
};

export const updateContact = (accessToken, payload) => {
  return createAction({
    endpoint: `${API_URL}/Contacts`,
    method: 'PUT',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      CONTACTUPDATE_REQUEST,
      CONTACTUPDATE_SUCCESS,
      CONTACTUPDATE_FAILURE,
    ],
  });
};

export const getContactsByMCode = (accountMCode, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/Contacts?AccountMCode=${accountMCode}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      ContactsByMCode_REQUEST,
      ContactsByMCode_SUCCESS,
      ContactsByMCode_FAILURE,
    ],
  });
};

export const getFinancierContactsByMCode = (accountMCode, accessToken) => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster/financierContacts?AccountMCode=${accountMCode}`,
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      Financier_Contacts_REQUEST,
      Financier_Contacts_FAILURE,
      Financier_Contacts_SUCCESS,
    ],
  });
};
