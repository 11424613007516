import {
  Account_FAILURE,
  Account_REQUEST,
  Account_SUCCESS,
  AccountCount_FAILURE,
  AccountCount_REQUEST,
  AccountCount_SUCCESS,
  MYACCOUNT_REQUEST,
  MYACCOUNT_SUCCESS,
  MYACCOUNT_FAILURE,
  ACCOUNTUPDATE_REQUEST,
  ACCOUNTUPDATE_SUCCESS,
  ACCOUNTUPDATE_FAILURE,
  Account_PAN_REQUEST,
  Account_PAN_FAILURE,
  Account_PAN_SUCCESS,
  Account_GST_FAILURE,
  Account_GST_REQUEST,
  Account_GST_SUCCESS,
  Account_MOBILE_FAILURE,
  Account_MOBILE_REQUEST,
  Account_MOBILE_SUCCESS,
  FETCH_USER_FAILURE,
  FETCH_USER_REQUEST,
  FETCH_USER_SUCCESS,
} from './constants';

import {combineReducers} from 'redux';
const isFetching = (state = false, action) => {
  switch (action.type) {
    case Account_REQUEST:
      return true;
    case Account_SUCCESS:
    case Account_FAILURE:
      return false;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case Account_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failure = (state = null, action) => {
  switch (action.type) {
    case Account_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFetched = (state = false, action) => {
  switch (action.type) {
    case Account_REQUEST:
      return false;
    case Account_SUCCESS:
    case Account_FAILURE:
      return true;
    default:
      return state;
  }
};
const isFetchingAccountsCount = (state = false, action) => {
  switch (action.type) {
    case AccountCount_REQUEST:
      return true;
    case AccountCount_SUCCESS:
    case AccountCount_FAILURE:
      return false;
    default:
      return state;
  }
};

const accountsDataCount = (state = null, action) => {
  switch (action.type) {
    case AccountCount_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const acountCountfailure = (state = null, action) => {
  switch (action.type) {
    case AccountCount_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasAccountsCountFetched = (state = false, action) => {
  switch (action.type) {
    case AccountCount_REQUEST:
      return false;
    case AccountCount_SUCCESS:
    case AccountCount_FAILURE:
      return true;
    default:
      return state;
  }
};

const myAccountData = (state = null, action) => {
  switch (action.type) {
    case MYACCOUNT_REQUEST:
      return state;
    case MYACCOUNT_SUCCESS:
      return action.payload;
    case MYACCOUNT_FAILURE:
      return state;
    default:
      return state;
  }
};

const updateAccountData = (state = null, action) => {
  switch (action.type) {
    case ACCOUNTUPDATE_REQUEST:
      return state;
    case ACCOUNTUPDATE_SUCCESS:
      return action;
    case ACCOUNTUPDATE_FAILURE:
      return state;
    default:
      return state;
  }
};

const isFetchingPan = (state = false, action) => {
  switch (action.type) {
    case Account_PAN_REQUEST:
      return true;
    case Account_PAN_SUCCESS:
    case Account_PAN_FAILURE:
      return false;
    default:
      return state;
  }
};

const panData = (state = null, action) => {
  switch (action.type) {
    case Account_PAN_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const panFailure = (state = null, action) => {
  switch (action.type) {
    case Account_PAN_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFetchedPan = (state = false, action) => {
  switch (action.type) {
    case Account_PAN_REQUEST:
      return false;
    case Account_PAN_SUCCESS:
    case Account_PAN_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingGst = (state = false, action) => {
  switch (action.type) {
    case Account_GST_REQUEST:
      return true;
    case Account_GST_SUCCESS:
    case Account_GST_FAILURE:
      return false;
    default:
      return state;
  }
};

const gstData = (state = null, action) => {
  switch (action.type) {
    case Account_GST_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const gstFailure = (state = null, action) => {
  switch (action.type) {
    case Account_GST_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFetchedGst = (state = false, action) => {
  switch (action.type) {
    case Account_GST_REQUEST:
      return false;
    case Account_GST_SUCCESS:
    case Account_GST_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingMobile = (state = false, action) => {
  switch (action.type) {
    case Account_MOBILE_REQUEST:
      return true;
    case Account_MOBILE_SUCCESS:
    case Account_MOBILE_FAILURE:
      return false;
    default:
      return state;
  }
};

const mobileData = (state = null, action) => {
  switch (action.type) {
    case Account_MOBILE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const mobileFailure = (state = null, action) => {
  switch (action.type) {
    case Account_MOBILE_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFetchedMobile = (state = false, action) => {
  switch (action.type) {
    case Account_MOBILE_REQUEST:
      return false;
    case Account_MOBILE_SUCCESS:
    case Account_MOBILE_FAILURE:
      return true;
    default:
      return state;
  }
};

const isFetchingUser = (state = false, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return true;
    case FETCH_USER_SUCCESS:
    case FETCH_USER_FAILURE:
      return false;
    default:
      return state;
  }
};

const userData = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const userFailure = (state = null, action) => {
  switch (action.type) {
    case FETCH_USER_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasFetchedUser = (state = false, action) => {
  switch (action.type) {
    case FETCH_USER_REQUEST:
      return false;
    case FETCH_USER_SUCCESS:
    case FETCH_USER_FAILURE:
      return true;
    default:
      return state;
  }
};

export default combineReducers({
  isFetching,
  data,
  failure,
  hasFetched,
  hasAccountsCountFetched,
  acountCountfailure,
  accountsDataCount,
  isFetchingAccountsCount,
  myAccountData,
  updateAccountData,
  isFetchingPan,
  panData,
  panFailure,
  hasFetchedPan,
  isFetchingGst,
  gstData,
  gstFailure,
  hasFetchedGst,
  isFetchingMobile,
  mobileData,
  mobileFailure,
  hasFetchedMobile,
  isFetchingUser,
  userData,
  hasFetchedUser,
  userFailure,
});
