import {
  ACTIVITY_FAILURE,
  ACTIVITY_REQUEST,
  ACTIVITY_SUCCESS,
  ACTIVITYCOUNT_FAILURE,
  ACTIVITYCOUNT_SUCCESS,
  ACTIVITYCOUNT_REQUEST,
  ACTIVITYTYPE_FAILURE,
  ACTIVITYTYPE_REQUEST,
  ACTIVITYTYPE_SUCCESS,
  ACTIVITY_UPDATE_FAILURE,
  ACTIVITY_UPDATE_SUCCESS,
  ACTIVITY_UPDATE_REQUEST,
} from './constants';
import {combineReducers} from 'redux';

const isFetchingActivities = (state = false, action) => {
  switch (action.type) {
    case ACTIVITY_REQUEST:
      return true;
    case ACTIVITY_SUCCESS:
    case ACTIVITY_FAILURE:
      return false;
    default:
      return state;
  }
};

const activitiesData = (state = null, action) => {
  switch (action.type) {
    case ACTIVITY_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const activitiesFailure = (state = null, action) => {
  switch (action.type) {
    case ACTIVITY_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const isFetchingActivitiesCount = (state = false, action) => {
  switch (action.type) {
    case ACTIVITYCOUNT_REQUEST:
      return true;
    case ACTIVITYCOUNT_SUCCESS:
    case ACTIVITYCOUNT_FAILURE:
      return false;
    default:
      return state;
  }
};

const activitiesDataCount = (state = null, action) => {
  switch (action.type) {
    case ACTIVITYCOUNT_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const activitiesFailureCount = (state = null, action) => {
  switch (action.type) {
    case ACTIVITYCOUNT_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const isFetchingActivitiesType = (state = false, action) => {
  switch (action.type) {
    case ACTIVITYTYPE_REQUEST:
      return true;
    case ACTIVITYTYPE_SUCCESS:
    case ACTIVITYTYPE_FAILURE:
      return false;
    default:
      return state;
  }
};

const activitiesTypeData = (state = null, action) => {
  switch (action.type) {
    case ACTIVITYTYPE_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const activitiesTypeFailure = (state = null, action) => {
  switch (action.type) {
    case ACTIVITYTYPE_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const updateActivityData = (state = null, action) => {
  switch (action.type) {
    case ACTIVITY_UPDATE_REQUEST:
      return state;
    case ACTIVITY_UPDATE_SUCCESS:
      return action;
    case ACTIVITY_UPDATE_FAILURE:
      return state;
    default:
      return state;
  }
};

export default combineReducers({
  activitiesFailure,
  activitiesTypeData,
  activitiesTypeFailure,
  isFetchingActivities,
  isFetchingActivitiesType,
  isFetchingActivitiesCount,
  activitiesDataCount,
  activitiesData,
  activitiesFailureCount,
  updateActivityData,
});
