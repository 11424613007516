import {all, call, spawn, delay} from 'redux-saga/effects';
import dashboardSaga from './dashboard/sagas';

const sagaErrorPrefix = '[SagaError]';

export default function* rootSaga() {
  const sagas = [dashboardSaga];

  try {
    yield all(
      sagas.map((saga) =>
        spawn(function* () {
          while (true) {
            try {
              yield call(saga);
              break;
            } catch (e) {
              // Don't remove following log
              console.error(`${sagaErrorPrefix} the saga will be restarted`, e);
            }
            yield delay(3000);
          }
        }),
      ),
    );
  } catch (error) {
    // Don't remove following log
    console.error(`${sagaErrorPrefix} Unable to yield all root sagas`);
  }
}
