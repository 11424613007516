import thunkMiddleware from 'redux-thunk';
import {apiMiddleware} from 'redux-api-middleware';
import createSagaMiddleware from 'redux-saga';
import {createStore, applyMiddleware, compose} from 'redux';
import {rootReducer} from '../modules/rootReducer';
import rootSaga from '../modules/rootSaga';

const initialState = {};

const sagaMiddleware = createSagaMiddleware();

const middleware = [thunkMiddleware, apiMiddleware, sagaMiddleware];
const middlewareEnhancer = applyMiddleware(...middleware);
const enhancers = [middlewareEnhancer];

if (process.env.NODE_ENV === 'development') {
  const devToolsExtension = window.__REDUX_DEVTOOLS_EXTENSION__;

  if (typeof devToolsExtension === 'function') {
    enhancers.push(devToolsExtension());
  }
}

const composedEnhancers = compose(...enhancers);

const store = createStore(rootReducer, initialState, composedEnhancers);

sagaMiddleware.run(rootSaga);

export default store;
