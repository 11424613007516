import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import Login from '../components/login/Login';

const LoginContainer = (props) => {
  return <Login {...props} />;
};

//#region mapStateToProps
const mapStateToProps = (state: any) => ({});

//#endregion

const mapDispatchToProps = (dispatch: any) => bindActionCreators({}, dispatch);

export default connect(mapStateToProps, mapDispatchToProps)(LoginContainer);
