import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {Pagination, Table, Button} from 'antd';
import {contactsColumns} from '../../data/contactsColumns';
import states from '../../data/states';
import districtsData from '../../data/districts';
import DataFilterBox from '../common/DataFilterBox';
import moment from 'moment';
import {useDispatch, useSelector} from 'react-redux';
import {getContacts, getContactsCount} from '../../modules/contacts/action';
import {getAccountAddress} from '../../modules/address/action';
import {getUserAddressCountriesData} from '../../modules/address/selectors';
import {getAuthUserData} from '../../modules/auth/user/selector';
import {getContactsDataCount} from '../../modules/contacts/selectors';
import {API_URL} from '../../constants/envVariables';
import {downloadCsv} from '../../utils/downloadCsv';
import {request} from '../../utils/HttpClient';
import getQueryString from '../../utils/getQueryString';
import ContactModalForm from '../common/ContactModal';
import ContactModalNepalForm from '../common/ContactModalNepal';

const Contacts = ({contacts}) => {
  const dispatch = useDispatch();
  const [modalData, setModalData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [stateFilter, setStateFilter] = useState(() => 'All');
  const [districtFilter, setDistrictFilter] = useState(() => 'All');
  const [fromDate, setFromDateFilter] = useState(
    moment(new Date()).add(-7, 'day').toDate(),
  );
  const dataCount = useSelector(getContactsDataCount);
  const [toDate, setToDateFilter] = useState(moment(new Date()).toDate());
  const [filterDistricts, setDistrict] = useState(() => []);
  const [filteredContactsData, setContactData] = useState(
    contacts.dataContacts,
  );

  const authUser = useSelector(getAuthUserData);
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSizeNew, setPageSizeNew] = useState(10);
  const [filteredFirstName, setFilteredFirstName] = useState('');
  const [filteredLastName, setFilteredLastName] = useState('');
  const [pageChangeClicked, setPageChangeClicked] = useState(false);
  const [filteredName, setFilteredName] = useState('');

  const [config, setConfig] = useState({
    pagination: {
      pageSizeOptions: ['10', '20', '50'],
      showSizeChanger: true,
      defaultPageSize: '10',
    },
  });

  const onStateChanged = (value) => {
    setStateFilter(value);
    setDistrict(
      districtsData.filter((item) => item.state.includes(value)).length > 0
        ? districtsData.filter((item) => item.state.includes(value))[0]
            .districts
        : districtFilter,
    );
  };
  useEffect(() => {
    dispatch(
      getContactsCount(
        authUser.accessToken,
        moment(fromDate).format('YYYY-MM-DD'),
        moment(toDate).format('YYYY-MM-DD'),
        '',
        '',
        '',
        '',
      ),
    );
  }, [dispatch, authUser.accessToken]);

  useEffect(() => {
    if (modalData) {
      dispatch(
        getAccountAddress(authUser.accessToken, modalData.account?.userId),
      );
    }
  }, [dispatch, modalData]);

  const CountriesData = useSelector(getUserAddressCountriesData);

  const Contactform =
    CountriesData?.length > 0 && CountriesData[0].name === 'India'
      ? ContactModalForm
      : ContactModalNepalForm;

  const onDistrictChanged = (value) => {
    setDistrictFilter(value);
  };
  const fromDateChanged = (value) => {
    setFromDateFilter(value._d);
  };
  const toDateChanged = (value) => {
    setToDateFilter(value._d);
  };
  const onResetDataClick = () => {
    setStateFilter(() => 'All');
    setDistrictFilter(() => 'All');
    setFromDateFilter(moment(new Date()).add(-7, 'day').toDate());
    setToDateFilter(moment(new Date()));
    setFilteredFirstName('');
    setFilteredLastName('');
    setFilteredName('');
    dispatch(
      getContactsCount(
        authUser.accessToken,
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        '',
        '',
        '',
        '',
      ),
    );
    dispatch(
      getContacts(
        1,
        pageSizeNew,
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        '',
        '',
        authUser.accessToken,
        '',
        '',
      ),
    );
  };
  const onFilterDataClick = () => {
    getFilteredData();
  };

  const getFilteredData = () => {
    if (filteredName) {
      dispatch(
        getContactsCount(
          authUser.accessToken,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          filteredFirstName,
          filteredLastName,
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
        ),
      );
      dispatch(
        getContacts(
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          '',
          '',
          '',
          '',
          authUser.accessToken,
          '',
          '',
          filteredName,
        ),
      );
    } else {
      dispatch(
        getContactsCount(
          authUser.accessToken,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          filteredFirstName,
          filteredLastName,
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
        ),
      );
      dispatch(
        getContacts(
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
          authUser.accessToken,
          filteredFirstName,
          filteredLastName,
        ),
      );
    }
  };

  const pageChanged = (page, pageSize) => {
    setConfig((prevState) => ({
      pagination: {
        ...prevState.pagination,
        defaultPageSize: pageSize,
        pageSize: pageSize,
      },
    }));
    setPageSizeNew(pageSize);
    setPageNumber(page);
    setPageChangeClicked(!pageChangeClicked);
  };

  useEffect(() => {
    if (pageChangeClicked) {
      dispatch(
        getContacts(
          pageNumber === 0 ? 1 : pageNumber,
          pageSizeNew,
          moment(fromDate).format('YYYY-MM-DD'),
          moment(toDate).format('YYYY-MM-DD'),
          stateFilter === 'All' ? '' : stateFilter,
          districtFilter === 'All' ? '' : districtFilter,
          authUser.accessToken,
          filteredFirstName,
          filteredLastName,
        ),
      );
      setPageChangeClicked(!pageChangeClicked);
    }
  }, [pageNumber, pageSizeNew]);

  const onDownloadDataClick = () => {
    callApiAndDownloadCsv({
      FirstName: filteredFirstName,
      LastName: filteredLastName,
      FromDate: moment(fromDate).format('YYYY-MM-DD'),
      ToDate: moment(toDate).format('YYYY-MM-DD'),
      State: stateFilter === 'All' ? '' : stateFilter,
      District: districtFilter === 'All' ? '' : districtFilter,
    });
  };

  const handleModalSubmit = () => {
    dispatch(
      getContacts(
        1,
        pageSizeNew,
        moment().subtract(7, 'd').format('YYYY-MM-DD'),
        moment().format('YYYY-MM-DD'),
        '',
        '',
        authUser.accessToken,
        '',
        '',
      ),
    );
  };

  const onFirstNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredFirstName(target.value.trim());
  };
  const onLastNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredLastName(target.value.trim());
  };

  const onUserNameChange = ({target}) => {
    setPageNumber(1);
    setFilteredName(target.value.trim());
  };

  const items = [
    {
      fieldId: 1,
      fieldName: '',
      fieldTitle: 'State',
      fieldType: 'Select',
      options: states,
      onChange: onStateChanged,
      value: stateFilter,
    },
    {
      fieldId: 2,
      fieldName: '',
      fieldTitle: 'District',
      fieldType: 'Select',
      options: filterDistricts,
      onChange: onDistrictChanged,
      value: districtFilter,
    },
    {
      fieldId: 3,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromDateChanged,
      value: fromDate,
    },
    {
      fieldId: 4,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toDateChanged,
      value: toDate,
    },
    {
      fieldId: 5,
      fieldName: 'Filter data',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onFilterDataClick,
    },
    {
      fieldId: 6,
      fieldName: 'Reset',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onResetDataClick,
    },
    {
      fieldId: 7,
      fieldName: 'Download Data Dump',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onDownloadDataClick,
    },
    {
      fieldId: 9,
      fieldName: 'Filter By First Name',
      fieldTitle: '',
      fieldMargin: '17px',
      fieldType: 'Label',
    },
    {
      fieldId: 8,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter First Name',
      onChange: onFirstNameChange,
      value: filteredFirstName,
    },
    {
      fieldId: 12,
      fieldType: 'Col',
    },
    {
      fieldId: 10,
      fieldName: 'Filter By Last Name',
      fieldTitle: '',
      fieldMargin: '17px',
      fieldType: 'Label',
    },
    {
      fieldId: 11,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Last Name',
      onChange: onLastNameChange,
      value: filteredLastName,
    },
    {
      fieldId: 13,
      fieldType: 'Col',
    },
    {
      fieldId: 14,
      fieldName: 'Filter By Contact MCode',
      fieldTitle: '',
      fieldMargin: '17px',
      fieldType: 'Label',
    },
    {
      fieldId: 15,
      fieldName: '',
      fieldTitle: '',
      fieldType: 'Input',
      fieldMargin: '15px',
      placeholderTitle: 'Enter Code',
      onChange: onUserNameChange,
      value: filteredName,
    },
  ];

  const callApiAndDownloadCsv = async (filters) => {
    const filterQueryString = getQueryString(filters);
    const url = `${API_URL}/Contacts/GetContactsDownload?${filterQueryString}`;
    const data = await request(url, authUser.accessToken);
    if (data) {
      downloadCsv(data.payload, 'Contacts');
    }
  };

  useEffect(() => {
    setContactData(
      contacts.dataContacts
        ? contacts.dataContacts.map((item) => ({
            ...item,
            name: `${item.firstName} ${item.lastName}`,
          }))
        : contacts.dataContacts,
    );
  }, [contacts.dataContacts]);

  const updatedContactCol = [
    ...contactsColumns,
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'followUpCallId',
      width: '12%',
      render: (text, record) => (
        <Button
          onClick={(e) => {
            getDetails(e, record);
          }}
          style={{
            background: '#fd560eb5',
            border: '#fd560eb5',
            fontWeight: '550',
          }}
          type="primary">
          Details
        </Button>
      ),
    },
  ];
  const getDetails = (e, record) => {
    console.log('record === ', record);
    setModalData(record);
    setTimeout(() => {
      setVisible(!visible);
    }, 800);
  };

  const handleCreate = () => {};
  return (
    <MainLayout>
      <DataFilterBox items={items} title="Contacts Filter" />
      <div className="contentLayout">
        <span className="contentHeader">Contacts Details</span>
        <Pagination
          defaultCurrent={1}
          onChange={pageChanged}
          style={{float: 'right'}}
          total={dataCount === 0 ? 1 : dataCount}
        />
        <Table
          columns={updatedContactCol}
          dataSource={filteredContactsData}
          loading={contacts.isFetchingContacts}
          {...config}
          pagination={false}
          scroll={{x: 440}}
          style={{marginTop: '30px'}}
        />
      </div>

      <Contactform
        data={modalData}
        onCancel={() => {
          setVisible(false);
          setStateFilter(() => 'All');
          setDistrictFilter(() => 'All');
          setFromDateFilter(moment(new Date()).add(-7, 'day').toDate());
          setToDateFilter(moment(new Date()));
          setFilteredFirstName('');
          setFilteredLastName('');
        }}
        onCreate={() => handleCreate()}
        onSubmit={() => handleModalSubmit()}
        visible={visible}
      />
    </MainLayout>
  );
};

export default Contacts;
