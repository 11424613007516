import {createSelector} from 'reselect';

export const getDropdowns = ({dropdowns}) => dropdowns;

export const getDropdownsData = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns.dropdownsData,
);

export const isFetchingDropdowns = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns.isFetchingDropdowns,
);

export const getOpportunityModelsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityModels,
);

export const getOpportunityStagesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityStages,
);

export const getOpportunityStageTypesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityStageTypes,
);

export const getOpportunityStageTypesWithDuesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityStageTypesWithDues,
);

export const getOpportunityStatusesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityStatuses,
);

export const getOpportunityTypesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityTypes,
);

export const getOriginsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.origins,
);

export const getFinanciersDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.financiers,
);

export const getCustomerTypesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.customerTypes,
);

export const getCompetitorsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.competitors,
);

export const getUsagesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.usages,
);

export const getVariantsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.variants,
);

export const getApplicationTypesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.applicationTypes,
);

export const getReasonsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.reasons,
);

export const getOpportunityAttachmentsDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.opportunityAttachments,
);

export const getZonesDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.zones,
);

export const getKeyAccountDropdown = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns?.dropdownsData?.keyAccount,
);

export const getActivityDropdownsData = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns.activityDropdownsData,
);

export const isFetchingActivityDropdowns = createSelector(
  getDropdowns,
  (dropdowns) => dropdowns.isFetchingActivityDropdowns,
);
