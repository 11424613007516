import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  Opportunities_REQUEST,
  Opportunities_SUCCESS,
  Opportunities_FAILURE,
  DownloadOpportunities_REQUEST,
  DownloadOpportunities_SUCCESS,
  DownloadOpportunities_FAILURE,
  OpportunitiesCount_REQUEST,
  OpportunitiesCount_SUCCESS,
  OpportunitiesCount_FAILURE,
  OpportunitiesUpdate_REQUEST,
  OpportunitiesUpdate_FAILURE,
  OpportunitiesUpdate_SUCCESS,
  CALCULATEOPPORTUNITYSTAGE_REQUEST,
  CALCULATEOPPORTUNITYSTAGE_SUCCESS,
  CALCULATEOPPORTUNITYSTAGE_FAILURE,
  OpportunityMCode_REQUEST,
  OpportunityMCode_FAILURE,
  OpportunityMCode_SUCCESS,
} from './constants';

export const getOpportunities = (
  accessToken,
  pageNumber,
  pageSize,
  toDate,
  fromDate,
  paramFromDate,
  paramToDate,
  opportunityMCode,
  account,
  accountMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/MyOrgOpportunities?${paramFromDate}=${fromDate}&${paramToDate}=${toDate}&PageNumber=${pageNumber}&PageSize=${pageSize}&OpportunityMCode=${opportunityMCode}&Account=${account}&AccountMCode=${accountMCode}`,
    method: 'GET',
    types: [
      Opportunities_REQUEST,
      Opportunities_SUCCESS,
      Opportunities_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
export const getDownloadOpportunities = (
  accessToken,
  pageNumber,
  pageSize,
  toDate,
  fromDate,
  paramFromDate,
  paramToDate,
  opportunityMCode,
  account,
  accountMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/DownloadMyOrgOpportunities?${paramFromDate}=${fromDate}&${paramToDate}=${toDate}&PageNumber=${pageNumber}&PageSize=${pageSize}&OpportunityMCode=${opportunityMCode}&Account=${account}&AccountMCode=${accountMCode}`,
    method: 'GET',
    types: [
      DownloadOpportunities_REQUEST,
      DownloadOpportunities_SUCCESS,
      DownloadOpportunities_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getOpportunitiesCount = (
  accessToken,
  toDate,
  fromDate,
  paramFromDate,
  paramToDate,
) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/MyOrgOpportunitiesCount?${paramFromDate}=${fromDate}&${paramToDate}=${toDate}`,
    method: 'GET',
    types: [
      OpportunitiesCount_REQUEST,
      OpportunitiesCount_SUCCESS,
      OpportunitiesCount_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const updateOpportunity = (accessToken, payload) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/AppPost`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      OpportunitiesUpdate_REQUEST,
      OpportunitiesUpdate_SUCCESS,
      OpportunitiesUpdate_FAILURE,
    ],
  });
};

export const calculateOpportunityStage = (accessToken, payload) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/StageType`,
    method: 'POST',
    body: JSON.stringify(payload),
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
    types: [
      CALCULATEOPPORTUNITYSTAGE_REQUEST,
      CALCULATEOPPORTUNITYSTAGE_SUCCESS,
      CALCULATEOPPORTUNITYSTAGE_FAILURE,
    ],
  });
};

export const getOpportunityByMCode = (
  accessToken,
  pageNumber,
  pageSize,
  opportunityMCode,
) => {
  return createAction({
    endpoint: `${API_URL}/Opportunities/MyOrgOpportunities?OpportunityMCode=${opportunityMCode}&PageNumber=${pageNumber}&PageSize=${pageSize}`,
    method: 'GET',
    types: [
      OpportunityMCode_REQUEST,
      OpportunityMCode_SUCCESS,
      OpportunityMCode_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
