import React, {useState, useEffect} from 'react';
import MainLayout from '../../layouts/MainLayout';
import {useSelector, useDispatch} from 'react-redux';
import UploadDownloadbox from '../common/UploadDownloadBox';
import DataFilterBox from '../common/DataFilterBox';
import {Button, Table, message} from 'antd';
import {bulkUploadColumns} from '../../data/bulkUploadColumns';
import {getAuthUserData} from '../../modules/auth/user/selector';
import moment from 'moment';
import axios from 'axios';
import {API_URL} from '../../constants/envVariables';

import {downloadCsv} from '../../utils/downloadCsv';
import Papa from 'papaparse';
import {func} from 'prop-types';

export const Report = () => {
  const authUser = useSelector(getAuthUserData);

  const [fromTripReportDate, setTripReportFromDate] = useState(
    moment(new Date()).add(-7, 'day').toDate(),
  );
  const [toTripReportDate, setTripReportToDate] = useState(moment(new Date()));

  const fromTripReportDateChanged = (fromDate) => {
    setTripReportFromDate(fromDate);
  };
  const toTripReportDateChanged = (toDate) => {
    setTripReportToDate(toDate);
  };

  const onTripFilterDataClick = () => {
    getTripFilterData();
  };

  const getTripFilterData = async () => {
    const fromDt = moment(fromTripReportDate).format('YYYY-MM-DD');
    const toDt = moment(toTripReportDate).format('YYYY-MM-DD');
    const endpoint = `${API_URL}/User/GetPortalReports?reportType=tripDetailsReport&startDate=${fromDt}&endDate=${toDt}`;
    return await axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${authUser?.accessToken}`,
        },
      })
      .then((obj) => {
        console.log('getTripFilterData', obj);
        if (
          obj?.data?.tripDetailsReports != null &&
          obj?.data?.tripDetailsReports?.length > 0
        ) {
          downloadCsv(obj?.data?.tripDetailsReports, 'Trip_Detail_Report');
          showSuccess('Downloaded successfully!');
        } else {
          showError('No data found');
        }
      })
      .catch((err) => {
        showError('Something went wrong, please try again later!');
      });
  };
  function showSuccess(text) {
    message.success(
      {
        content: text,
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      },
      5,
    );
  }
  function showError(text) {
    message.error(
      {
        content: text,
        className: 'custom-class',
        style: {fontFamily: 'arial', fontWeight: 400, color: '#797878'},
      },
      5,
    );
  }

  const [fromPunchingReportDate, setPunchingReportFromDate] = useState(
    moment(new Date()).add(-7, 'day').toDate(),
  );
  const [toPunchingReportDate, setPunchingReportToDate] = useState(
    moment(new Date()),
  );
  const fromPunchingReportDateChanged = (fromDate) => {
    setPunchingReportFromDate(fromDate);
  };
  const toPunchingReportDateChanged = (toDate) => {
    setPunchingReportToDate(toDate);
  };
  const onPunchingFilterDataClick = () => {
    getPunchingReportData();
  };
  const getPunchingReportData = async () => {
    const fromDt = moment(fromPunchingReportDate).format('YYYY-MM-DD');
    const toDt = moment(toPunchingReportDate).format('YYYY-MM-DD');
    const endpoint = `${API_URL}/User/GetPortalReports?reportType=punchingDetailsReport&startDate=${fromDt}&endDate=${toDt}`;
    return await axios
      .get(endpoint, {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: `Bearer ${authUser?.accessToken}`,
        },
      })
      .then((obj) => {
        console.log('getTripFilterData', obj);
        downloadCsv(obj?.data?.punchingDetailsReports, 'PunchIn_Detail_Report');
        if (
          obj?.data?.punchingDetailsReports != null &&
          obj?.data?.punchingDetailsReports.length > 0
        ) {
          showSuccess('Downloaded successfully!');
        } else {
          showError('No data found');
        }
      })
      .catch((err) => {
        showError('Something went wrong, please try again later!');
      });
  };

  const tripDetailsReportItems = [
    {
      fieldId: 7,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromTripReportDateChanged,
      value: fromTripReportDate,
    },
    {
      fieldId: 8,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toTripReportDateChanged,
      value: toTripReportDate,
    },
    {
      fieldId: 9,
      fieldName: 'Download Report',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onTripFilterDataClick,
    },
  ];
  const punchingeReportItems = [
    {
      fieldId: 13,
      fieldName: '',
      fieldTitle: 'From Date',
      fieldType: 'DatePicker',
      onChange: fromPunchingReportDateChanged,
      value: fromPunchingReportDate,
    },
    {
      fieldId: 14,
      fieldName: '',
      fieldTitle: 'To Date',
      fieldType: 'DatePicker',
      onChange: toPunchingReportDateChanged,
      value: toPunchingReportDate,
    },
    {
      fieldId: 15,
      fieldName: 'Download Report',
      fieldTitle: '',
      fieldType: 'Button',
      onClick: onPunchingFilterDataClick,
    },
  ];
  return (
    <MainLayout>
      <UploadDownloadbox
        items={tripDetailsReportItems}
        title="Trip Details Report"
      />
      <UploadDownloadbox
        items={punchingeReportItems}
        title="Punching Details Report"
      />
    </MainLayout>
  );
};
