import {createSelector} from 'reselect';

export const getOpportunities = ({opportunities}) => opportunities;

export const getOpportunitiesData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.opportunitiesData,
);
export const isFetchedOpportunities = createSelector(
  getOpportunities,
  (opportunities) => opportunities.isFetchingUser,
);

export const isFetchingOpportunitiesData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.isFetchingOpportunities,
);

export const getOpportunitieCountsData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.opportunitiesDataCount,
);
export const isFetchingOpportunitiesDataCount = createSelector(
  getOpportunities,
  (opportunities) => opportunities.isFetchingOpportunitiesCount,
);
export const getDownloadOpportunitiesData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.opportunitiesDownloadData,
);
export const isFetchingDownloadOpportunitiesData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.isFetchingDownloadOpportunities,
);

export const updateOpportunityDataSelector = createSelector(
  getOpportunities,
  (opportunities) => opportunities.updateOpportunityData,
);

export const calculateOpportunityStageSelector = createSelector(
  getOpportunities,
  (opportunities) => opportunities.calculateOpportunityStage,
);

export const getOpportunityByMCodeData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.opportunityByMCode,
);

export const isFetchingOpportunityByMCodeData = createSelector(
  getOpportunities,
  (opportunities) => opportunities.isFetchingOpportunityByMCode,
);
