import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  BULKUPLOADSTATUSLIST_REQUEST,
  BULKUPLOADSTATUSLIST_SUCCESS,
  BULKUPLOADSTATUSLIST_FAILURE,
  BULKUPLOADRESPONSE_REQUEST,
  BULKUPLOADRESPONSE_SUCCESS,
  BULKUPLOADRESPONSE_FAILURE,
  BULKUPLOADREQUEST_REQUEST,
  BULKUPLOADREQUEST_SUCCESS,
  BULKUPLOADREQUEST_FAILURE,
  BULKUPLOADFILEFILTER_REQUEST,
  BULKUPLOADFILEFILTER_SUCCESS,
  BULKUPLOADFILEFILTER_FAILURE,
} from './constants';

export const getBulkUploadStatusList = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/BulkUpload/StatusList`,
    method: 'GET',
    types: [
      BULKUPLOADSTATUSLIST_REQUEST,
      BULKUPLOADSTATUSLIST_SUCCESS,
      BULKUPLOADSTATUSLIST_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getBulkUploadResponse = (accessToken, url, jobid) => {
  return createAction({
    endpoint: `${API_URL}/${url}?jobId=${jobid}`,
    method: 'GET',
    types: [
      BULKUPLOADRESPONSE_REQUEST,
      BULKUPLOADRESPONSE_SUCCESS,
      BULKUPLOADRESPONSE_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const getBulkUploadFilterFileResponse = (accessToken, fileName) => {
  return createAction({
    endpoint: `${API_URL}/BulkUpload/GetFileStatus?fileName=${fileName}`,
    method: 'GET',
    types: [
      BULKUPLOADFILEFILTER_REQUEST,
      BULKUPLOADFILEFILTER_SUCCESS,
      BULKUPLOADFILEFILTER_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};

export const postBulkUploadRequest = (accessToken, url, payload) => {
  return createAction({
    endpoint: `${API_URL}/${url}`,
    method: 'POST',
    body: JSON.stringify(payload),
    types: [
      BULKUPLOADREQUEST_REQUEST,
      BULKUPLOADREQUEST_SUCCESS,
      BULKUPLOADREQUEST_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
