import {call, put, select} from 'redux-saga/effects';
import {request} from '../../../utils/HttpClient';
import getErrorForReducer from '../../../utils/getErrorForReducer';
import {getAuthUserAccessToken} from '../../auth/user/selector';
import {
  DASHBOARD_ENDPOINT,
  FETCH_DASHBOARD_SUCCESS,
  FETCH_DASHBOARD_FAILURE,
} from '../constants';
import getQueryString from '../../../utils/getQueryString';

export default function* fetchDashboard({payload}) {
  const query = getQueryString(payload);
  try {
    const access_token = yield select(getAuthUserAccessToken);

    const url = query
      ? `${DASHBOARD_ENDPOINT}?${query}`
      : `${DASHBOARD_ENDPOINT}`;
    const response = yield call(request, url, access_token);
    yield put({type: FETCH_DASHBOARD_SUCCESS, ...response});
  } catch (error) {
    const payload = getErrorForReducer(error);
    yield put({type: FETCH_DASHBOARD_FAILURE, payload});
  }
}
