import {createAction} from 'redux-api-middleware';
import {API_URL} from '../../constants/envVariables';
import {
  Dropdowns_REQUEST,
  Dropdowns_SUCCESS,
  Dropdowns_FAILURE,
  Activity_Dropdowns_REQUEST,
  Activity_Dropdowns_SUCCESS,
  Activity_Dropdowns_FAILURE,
} from './constants';

export const getDropdownValues = () => {
  return createAction({
    endpoint: `${API_URL}/OpportunitiesMaster`,
    method: 'GET',
    types: [Dropdowns_REQUEST, Dropdowns_SUCCESS, Dropdowns_FAILURE],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    },
  });
};

export const getActivityDropdownValues = (accessToken) => {
  return createAction({
    endpoint: `${API_URL}/ActivityMaster`,
    method: 'GET',
    types: [
      Activity_Dropdowns_REQUEST,
      Activity_Dropdowns_SUCCESS,
      Activity_Dropdowns_FAILURE,
    ],
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      Authorization: `Bearer ${accessToken}`,
    },
  });
};
