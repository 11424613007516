import {ApiError} from './errors';

/**
 * Fetch data from rest service
 *
 * @param {string} url
 * @param {string} accessToken
 * @param {Object} options
 * @param {string} [options.method]
 * @param {string} [options.payload] - Request body.
 * @param {Object} [options.headers]
 *
 * @returns {Promise}
 */
export function request(url, accessToken, options = {}) {
  const config = {
    method: 'GET',
    ...options,
  };

  //#region check setup errors
  const setupErrors = [];

  if (!url) {
    setupErrors.push('url');
  }

  if (
    !config.payload &&
    config.method !== 'GET' &&
    config.method !== 'DELETE'
  ) {
    setupErrors.push('payload');
  }

  if (setupErrors.length > 0) {
    throw new Error(`Error! You must pass \`${setupErrors.join('`, `')}\``);
  }
  //#endregion

  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${accessToken}`,
    ...config.headers,
  };

  const params = {
    headers,
    method: config.method,
  };

  if (params.method !== 'GET') {
    params.body = JSON.stringify(config.payload);
  }

  return fetchRaw(url, params);
}

/**
 *
 * @param {string} url
 * @param {object} params
 */

export function fetchRaw(url, params) {
  return fetch(url, params).then(async (response) => {
    if (response.ok) {
      const successfulResult = {
        payload: await getJSON(response),
        status: response.status,
      };

      return successfulResult;
    } else {
      const error = {
        error: true,
        payload: await getJSON(response).then((data) => {
          const {status, statusText} = response;
          return new ApiError(status, statusText, data);
        }),
      };

      throw error;
    }
  });
}

/**
 * Extract JSON body from a server response
 *
 * @function getJSON
 * @access public
 * @param {object} response - A raw response object
 * @returns {promise|undefined}
 */
async function getJSON(response) {
  const contentType = response.headers.get('Content-Type');
  const emptyCodes = [204, 205];

  if (
    emptyCodes.indexOf(response.status) === -1 &&
    contentType &&
    contentType.indexOf('json') !== -1
  ) {
    return await response.json();
  } else {
    return await Promise.resolve();
  }
}
