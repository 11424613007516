import {
  Dropdowns_REQUEST,
  Dropdowns_SUCCESS,
  Dropdowns_FAILURE,
  Activity_Dropdowns_REQUEST,
  Activity_Dropdowns_SUCCESS,
  Activity_Dropdowns_FAILURE,
} from './constants';
import {combineReducers} from 'redux';

const isFetchingDropdowns = (state = false, action) => {
  switch (action.type) {
    case Dropdowns_REQUEST:
      return true;
    case Dropdowns_SUCCESS:
    case Dropdowns_FAILURE:
      return false;
    default:
      return state;
  }
};

const dropdownsData = (state = null, action) => {
  switch (action.type) {
    case Dropdowns_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureDropdowns = (state = null, action) => {
  switch (action.type) {
    case Dropdowns_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const isFetchingActivityDropdowns = (state = false, action) => {
  switch (action.type) {
    case Activity_Dropdowns_REQUEST:
      return true;
    case Activity_Dropdowns_SUCCESS:
    case Activity_Dropdowns_FAILURE:
      return false;
    default:
      return state;
  }
};

const activityDropdownsData = (state = null, action) => {
  switch (action.type) {
    case Activity_Dropdowns_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureActivityDropdowns = (state = null, action) => {
  switch (action.type) {
    case Activity_Dropdowns_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

export default combineReducers({
  isFetchingDropdowns,
  dropdownsData,
  failureDropdowns,
  isFetchingActivityDropdowns,
  activityDropdownsData,
  failureActivityDropdowns,
});
