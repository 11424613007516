export const Contacts_REQUEST = 'Contacts_REQUEST';
export const Contacts_SUCCESS = 'Contacts_SUCCESS';
export const Contacts_FAILURE = 'Contacts_FAILURE';
export const ContactsCount_REQUEST = 'ContactsCount_REQUEST';
export const ContactsCount_SUCCESS = 'ContactsCount_SUCCESS';
export const ContactsCount_FAILURE = 'ContactsCount_FAILURE';
export const CONTACTUPDATE_REQUEST = 'CONTACTUPDATE_REQUEST';
export const CONTACTUPDATE_SUCCESS = 'CONTACTUPDATE_SUCCESS';
export const CONTACTUPDATE_FAILURE = 'CONTACTUPDATE_FAILURE';
export const ContactsByMCode_REQUEST = 'Contacts_REQUEST';
export const ContactsByMCode_SUCCESS = 'Contacts_SUCCESS';
export const ContactsByMCode_FAILURE = 'Contacts_FAILURE';
export const Financier_Contacts_REQUEST = 'Financier_Contacts_REQUEST';
export const Financier_Contacts_SUCCESS = 'Financier_Contacts_SUCCESS';
export const Financier_Contacts_FAILURE = 'Financier_Contacts_FAILURE';
