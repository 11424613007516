import {combineReducers} from 'redux';
import {
  TERRITORYFILTER_FAILURE,
  TERRITORYFILTER_REQUEST,
  TERRITORYFILTER_SUCCESS,
} from './constants';

const isFetchingTerritoryFilter = (state = false, action) => {
  switch (action.type) {
    case TERRITORYFILTER_REQUEST:
      return true;
    case TERRITORYFILTER_SUCCESS:
    case TERRITORYFILTER_FAILURE:
      return false;
    default:
      return state;
  }
};

const data = (state = null, action) => {
  switch (action.type) {
    case TERRITORYFILTER_SUCCESS:
      return action.payload;
    default:
      return state;
  }
};

const failureTerritoryFilter = (state = null, action) => {
  switch (action.type) {
    case TERRITORYFILTER_FAILURE:
      return action.payload;
    default:
      return state;
  }
};

const hasTerritoryFilterRFetched = (state = false, action) => {
  switch (action.type) {
    case TERRITORYFILTER_REQUEST:
      return false;
    case TERRITORYFILTER_SUCCESS:
    case TERRITORYFILTER_FAILURE:
      return true;
    default:
      return state;
  }
};
export default combineReducers({
  data,
  isFetchingTerritoryFilter,
  hasTerritoryFilterRFetched,
  failureTerritoryFilter,
});
